
 import {audioTechnicaLogo,
  subBrand1,
  subBrand2,
  subBrand3,
  subBrand4,
  subBrand5,
  subBrand6,
  subBrand7,
  subBrand8} from "../../assets/imageImport"; 
import { SubCategory } from "./subCategory";
 
export const CategoryDetails = ({content}) => {
  return (
    <section className="subcategory-section">
      <div className="container-fluid container-lg">
        <div className="row">
        {content && content!="" && <div dangerouslySetInnerHTML={{ __html: content }} />}
          {/* <div className="col-lg-12">
            <div className="brand-banner">
              <img
                src={audioTechnicaLogo}
                alt="brand name"
              />
            </div>
            <div className="brand-content my-3">
              <h1>
                Audio-Technica Corporation is a Japanese company that designs
                and manufactures professional microphones, headphones,
                turntables, phonographic magnetic cartridges, and other audio
                equipment.
              </h1>
              <p>
                Inspired by the depth of emotion he witnessed at vinyl listening
                sessions organised during his tenure at the Bridgestone Museum
                of Arts in Tokyo, Hideo Matsushita was determined to enable more
                people to experience the warmth of analogue listening. In a
                small Shinjuku apartment, he established Audio-Technica and
                began developing cartridges. His first two products, the AT-1
                and AT-3, achieved success and enabled Hideo to expand his
                business. He opened a larger headquarters in Machida, where the
                company still operates today.
              </p>
              <p>
                Building on our passion for listening we have grown to design
                critically acclaimed headphones, turntables and microphones,
                remaining a family business and retaining the belief that
                high-quality audio should be accessible to all. We still produce
                some of the world’s most popular cartridges and we will always
                retain our passion for analogue. We believe that sound should be
                natural and we strive to reproduce true, accurate,
                representations of audio. We start every product with the pure
                vibration created when we capture sound, using this analogue
                transducer technology to create products that transcend everyday
                sensory experiences; heightening our sensibilities. Our quest to
                create pure, natural, audio is perpetual - it never stops, never
                ends.
              </p>
            </div>
          </div> */}
        </div>
         {/* <SubCategory />  */}
      </div>
    </section>
  );
};
