import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Fragment, useContext, useEffect, useState } from "react";
import { logo } from "../../assets/imageImport";
import { BulkButOption } from "../Common/BulkBuyPopup";
import { CartToastContainer } from "../Common/cartToastContainer";
import { ShopAllProducts } from "./shopAllproducts";
import { SearchSuggestion } from "./SearchSuggestion";
import { Link, useLocation } from "react-router-dom";
import { SideBar } from "./sidebar";
import { GlobalContext } from "../../Context/GlobalContext";
import { WishListToaster } from "./WishListToaster";
import { MyFavouriteToaster } from "../Common/myFavouriteToaster";
import { MyFavouriteToasterSimple } from "../Common/myFavouriteToasterSimple";
import { OrderClarificationModal } from "../Common/orderClarificationModal"; 
import { CartSidePopup } from "./cartSidePopup/cartSidePopup";
import { useAuth } from "../../Context/AuthContext";
import { axiosInstance } from "../../axios/axiosInstance";

// ==============================|| MAIN LAYOUT ||============================== //

export const MainLayout = () => {
  const globalcontext = useContext(GlobalContext);
  const [CartItem, setCartItem] = useState(null);

  const { userId, userName, role, isAuthenticated } = useAuth();
  useEffect(() => {
    if (globalcontext.CartItem) setCartItem(globalcontext.CartItem);
  }, [globalcontext.CartItem]);

  useEffect(() => {
    try {
      var _model = $(".allproducts-close");
      if (_model.length > 0) {
        _model[0].click();
        _model.each(function () {
          $(this).click();
        });
      } else _model.click();
    } catch {}
  }, [window.location.pathname]);

  useEffect(() => {
    let e = ["Search for something…", "Search products", "Clearance sales"];
    let i = $("#searchbox");
    let o = function (t, e) {
      this.input = t;
      this.defaultPlaceholder = this.input.attr("placeholder");
      this.texts = e;
      this.curTextNum = 0;
      this.curPlaceholder = "";
      this.blinkCounter = 0;
      this.animationFrameId = 0;
      this.animationActive = !1;
      this.input.attr("placeholder", this.curPlaceholder);
      this.switch = (t) => {
        this.input.addClass("imitatefocus");
        setTimeout(() => {
          this.input.removeClass("imitatefocus");
          this.curTextNum === 0
            ? this.input.attr("placeholder", this.defaultPlaceholder)
            : this.input.attr("placeholder", this.curPlaceholder);
          setTimeout(() => {
            this.input.attr("placeholder", this.curPlaceholder);
            this.animationActive &&
              (this.animationFrameId = window.requestAnimationFrame(
                this.animate
              ));
          }, t);
        }, t);
      };
      this.animate = () => {
        if (!this.animationActive) return;
        let t = this.texts[this.curTextNum],
          e = 500;
        if (this.curPlaceholder == t + "|" && this.blinkCounter == 3) {
          this.blinkCounter = 0;
          this.curTextNum =
            this.curTextNum >= this.texts.length - 1 ? 0 : this.curTextNum + 1;
          this.curPlaceholder = "|";
          this.switch(1e3);
          return;
        }
        this.curPlaceholder == t + "|" && this.blinkCounter < 3
          ? ((this.curPlaceholder = t), this.blinkCounter++)
          : this.curPlaceholder == t && this.blinkCounter < 3
          ? (this.curPlaceholder = this.curPlaceholder + "|")
          : ((this.curPlaceholder =
              t
                .split("")
                .slice(0, this.curPlaceholder.length + 1)
                .join("") + "|"),
            (e = 150));
        this.input.attr("placeholder", this.curPlaceholder);
        setTimeout(() => {
          this.animationActive &&
            (this.animationFrameId = window.requestAnimationFrame(
              this.animate
            ));
        }, e);
      };
      this.stop = () => {
        this.animationActive = !1;
        window.cancelAnimationFrame(this.animationFrameId);
      };
      this.start = () => (
        (this.animationActive = !0),
        (this.animationFrameId = window.requestAnimationFrame(this.animate)),
        this
      );
    };
    $(document).ready(() => {
      let t = new o(i, e).start();
      i.on("focus", (e) => t.stop());
      i.on("blur", (a) => {
        t = new o(i, e);
        "" == a.target.value && setTimeout(t.start, 2e3);
      });
    });

    return () => {
      // You may need to add code to destroy the jQuery components if necessary
    };
  }, []);

  return (
    <Fragment>
      <div>
        <div className="main-wrapper">
          {/* Menu Sidebar */}
          <div className="sidebar-wrap">
            <div className="sidebar-inner">
              <div className="sidebar-close">
                <div className="sidebar-close-btn">
                  <i className="fa-solid fa-arrow-left" />
                  Back to Home
                </div>
              </div>
              <SideBar />
            </div>
          </div>
          {/* Menu Sidebar */}
          {/* Header section */}
          <header className="header-area">
            <div className="container-fluid container-lg p-0">
              <div className="header-navbar">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div className="container-fluid container-lg">
                    <Link className="navbar-brand" to="/home">
                      <img src={logo} alt="Logo" />
                    </Link>
                    <div className="navbar-collapse nav-links">
                      <div
                        className="category-nav mb-2 mb-lg-0"
                        data-bs-toggle="modal"
                        data-bs-target="#shop-allProducts"
                      >
                        <div className="shop-categories">
                          <i className="fa-solid fa-bars" />
                          <p>Shop All Products</p>
                        </div>
                        <span className="wes-down-arrow d-none d-lg-block" />
                      </div>
                      <SearchSuggestion />
                      <div className="d-flex header-right">
                        <div className="header-user d-none d-lg-inline-flex">
                          <span className="wes-my-account" />
                          <div className="header-user-info text-end text-white">
                            <small>{userName}</small>
                            <a href="#.">My Account</a>
                          </div>
                        </div>
                        <div className="header-user d-none d-lg-inline-flex">
                          <span className="wes-wishlist" />
                          <div className="header-user-info text-end text-white">
                            <small>Reorder</small>
                            <a href="#.">My List</a>
                          </div>
                        </div>
                        {/* Mobile Profile with menu */}
                        <div className="mobile-user-info d-block d-lg-none">
                          <span className="wes-my-account" />
                        </div>
                        {/* Mobile Profile with menu */}
                        {/* Mobile Search */}
                        <div className="mob-search position-relative d-block d-lg-none">
                          <span className="wes-search header-search-form" />
                        </div>
                        {/* Mobile Search */}
                        <div
                          className="header-user header-cart d-flex"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#cartPopup"
                          aria-controls="offcanvasTop"
                        >
                          <a href="#." className="cart-icon">
                            <span className="wes-cart" />
                          </a>
                          {CartItem && CartItem.totalItem ? (
                            <div className="header-cart shopping-cart">
                              <a href="#.">{CartItem.totalItem}</a>
                            </div>
                          ) : (
                            <div className="header-cart shopping-cart">
                              <a href="#.">0</a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <CartToastContainer />
                <MyFavouriteToaster />
                <MyFavouriteToasterSimple />
              </div>
            </div>
          </header>
        </div>
        <div className="go-top"></div>
        <ShopAllProducts />
        <WishListToaster />
        <BulkButOption />
        <CartSidePopup />
      </div>
    </Fragment>
  );
};
