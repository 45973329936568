import { Link } from "react-router-dom";
import useAxiosInstance from "../../axios/axiosInterceptor";
import { useEffect, useState } from "react";
import { ProductList } from "./productList";

export const ProductPosterRight = () => {
  const { loading, error, get, post } = useAxiosInstance();
  const [PosterData, setPosterData] = useState(null);
  useEffect(() => {
    get("https://cdn.wes.com.au/wesreact/newui/banner/productPosterRight.txt", (response) => {
      var responsedata = response.data;
      if (responsedata && responsedata.ProductPosterRight) {
        setPosterData(responsedata.ProductPosterRight);
      }
    });
  }, []);
  return (
    <section className="popular-section">
    <div className="container-fluid container-lg">
      <div className="row"> 
        <ProductList type={"POPPRODUCTS"} /> 
        {/* product title */}
        <div className="col-md-5 col-lg-6 order-1 order-lg-2">
        {PosterData ? (
              <div className="new-product-title new-products">
                <div className="products-title">
                  <h1>{PosterData.title}</h1>
                  <p>{PosterData.content}</p>
                  <div className="button-effect">
                    <Link to={PosterData.link} className="shop-link">
                      {PosterData.buttonCaption}
                    </Link>
                  </div>
                </div>
                <img src={PosterData.image} alt="new-product" />
              </div>
            ) : (
              <div className="new-product-title new-products"></div>
            )} 
        </div>
        {/* product title */}
      </div>
    </div>
  </section>
  );
};
