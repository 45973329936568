import { Fragment, useContext, useEffect, useState } from "react";
import { isValidArray } from "../../../Layout/Common/common";
import { ImageWithDefault } from "../../../Layout/Common/imageWithDefault";
import { Link } from "react-router-dom";
import { QuantityStripper } from "../../../Layout/Common/quantityStripper";
import { axiosInstance } from "../../../axios/axiosInstance";
import { GlobalContext } from "../../../Context/GlobalContext";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../../../assets/css/cartList.css";

export const CartItemBody = ({ cartItemList }) => {
  const [cartItem, setCartItem] = useState(null);
  const globalcontext = useContext(GlobalContext);
  useEffect(() => {
    console.log(cartItemList);
    if (isValidArray(cartItemList)) setCartItem(cartItemList);
  }, [cartItemList]);
  const removeCard = (item) => {
    setCartItem((prevCards) =>
      prevCards.filter((card) => card.prod_id !== item.prod_id)
    );
    var data = {
      productId: String(item.prod_id),
      userId: String(globalcontext.UserId),
      orderItemId: item.order_item_id,
      orderId: String(globalcontext.orderid),
    };
    axiosInstance
      .post("MyCart/RemoveItem", data)
      .then((response) => {
        console.log(response);
        if (response.data) {
          var res = response.data;
          if (res.statusCode == 0 && res.data) {
            globalcontext.setCartItem(res.data);
          } else {
            alert("Something Wrong !");
          }
        }
      })
      .catch((error) => console.log(error.message));
  };

  return (
    <Fragment>
      {isValidArray(cartItem) && (
        <div className="added-cartPopup">
          <TransitionGroup>
            {cartItem.map((item, index) => (
              <CSSTransition key={item.prod_id} timeout={300} classNames="card">
                <div className="added-cartPro" key={index}>
                  <div className="added-cartPro-img">
                    <ImageWithDefault src={item.image_th} alt="Product-image" />
                  </div>
                  <div className="added-cartPro-content">
                    <div className="added-cartPro-info">
                      <div className="added-cartPro-item">
                        <h6>
                          <Link to={item.product_url}>{item.description}</Link>
                        </h6>
                        <p>
                          <span>Code: {item.prod_code}</span>$
                          {item.price_ext_applied} each
                        </p>
                      </div>
                      <div className="border-link">
                        <button
                          type="submit"
                          className="remove-pro-popup"
                          onClick={() => {
                            removeCard(item);
                          }}
                        >
                          <i className="fa-regular fa-trash-can" />
                        </button>
                      </div>
                    </div>
                    <div className="added-cartPro-price">
                      <p>${item.total_ext}</p>
                      <QuantityStripper prd={item} />
                    </div>
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      )}
    </Fragment>
  );
};
