import { CategoryGridView } from "./categoryGridView";
import { CategoryListView } from "./CategoryListView";
import { CategoryProductView } from "./CategoryProductView";

export const CategoryFilter = () => {
  return (
    <section className="filter-product-section">
      <div
        className="container-fluid sticky-element filter-sticky bg-white w-100 d-block d-lg-none"
        id="stickyElement"
      >
        <div className="row">
          <div className="col-lg-12">
            {/* Mobile Filters and Sorting */}
            <div className="mob-sort-filter active">
              <ul>
                <li
                  data-bs-toggle="offcanvas"
                  data-bs-target="#mobSorting"
                  aria-controls="offcanvasRight"
                >
                  <p>
                    <span className="wes-sortby" /> Sortby
                  </p>
                </li>
                <li
                  data-bs-toggle="offcanvas"
                  data-bs-target="#mobFilters"
                  aria-controls="offcanvasRight"
                >
                  <p>
                    <span className="wes-filter" /> Filter{" "}
                    <span className="mob-filterCount">1</span>
                  </p>
                </li>
              </ul>
            </div>
            {/* Mobile Filters and Sorting */}
            {/* Mobile Fit my device */}
            <div className="mob-fitmy-device">
              <ul>
                <li
                  data-bs-toggle="offcanvas"
                  data-bs-target="#mobFitmodal"
                  aria-controls="offcanvasRight"
                >
                  <p>
                    <span className="wes-fitmydevice" /> Modal
                  </p>
                </li>
                <li
                  data-bs-toggle="offcanvas"
                  data-bs-target="#mobFitdevice"
                  aria-controls="offcanvasRight"
                >
                  <p>
                    <span className="wes-fitmydevice" /> Device
                  </p>
                </li>
              </ul>
            </div>
            {/* Mobile Fit my device */}
          </div>
        </div>
      </div>
      <CategoryProductView />
    </section>
  );
};
