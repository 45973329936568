import { Fragment, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { Toast } from "bootstrap";
import { axiosInstance } from "../../axios/axiosInstance";
import { WishlistContext } from "../../Context/WishlistContext";
import { showWishListAddToaster } from "./common";
import { Link } from "react-router-dom";

export const MyFavouriteToasterSimple = () => {
  const globalcontext = useContext(GlobalContext);
  const { wishlistState, dispatch } = useContext(WishlistContext);
  const [wishList, setWishList] = useState(null);
  const [NewCollectionName, setNewCollectionName] = useState("");

  useEffect(() => {
    var data = { userid: parseInt(globalcontext.UserId) };
    axiosInstance
      .post("MyFavoriteList/GetFavouriteList", data)
      .then((response) => {
        console.log("yy", response.data);
        setWishList(response.data);
        dispatch({ type: "UPDATE_FAVOURITE_LIST", list: response.data });
      })
      .catch((error) => {
        return error.message;
      });
  }, [globalcontext.UserId, globalcontext.WishList]);

  const AddtoList = (d) => {
    if (d.listId === 0) return false;
    var data = {
      userId: parseInt(globalcontext.UserId),
      listId: parseInt(d.listId),
      productId: parseInt(wishlistState.productid),
    };
    axiosInstance
      .post("MyFavoriteList/AddItemList", data)
      .then((response) => {
        setWishList((prevItems) => response.data);
        showWishListAddToaster();
      })
      .catch((error) => {
        return error.message;
      });
  };
  const AddtoNewList = () => {
    var data = {
      userId: parseInt(globalcontext.UserId),
      listName: NewCollectionName,
      productId: parseInt(wishlistState.productid),
    };
    axiosInstance
      .post("MyFavoriteList/AddNewList", data)
      .then((response) => {
        setWishList((prevItems) => response.data);
        showWishListAddToaster();
        setNewCollectionName("");
      })
      .catch((error) => {
        return error.message;
      });
  };
  return (
    <Fragment>
      <div
        className="position-fixed bottom-0 start-0 p-3"
        style={{ zIndex: 99 }}
      >
        <div
          id="wishlistcontainer2"
          className="toast hide wishlistcontainer"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-bs-delay={500000}
        >
          <div className="toast-body wishlistheader pt-2 pb-4">
            <div className="cart-item-update">
              <h6 className=" fw-semibold mt-2">
                <i className="fa-solid fa-check" /> Item Added to your wish list
              </h6>

              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="toast"
                aria-label="Close"
              />
              <Link to="/" className="text-uppercase checkoutcart-link">
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
