import {
  dummyColors,
  dummyManufacturer,
  dummyType,
} from "../../assets/constValue";
import { FilterDropDown } from "../../Layout/Common/FilterDropdown";
import { LengthFilter } from "../../Layout/Common/filterdrpComponent";
import { CategoryFilter } from "./categoryFilter";

export const SearchProductList = () => {
  return (
    <div
      className="tab-pane fade show active"
      id="products-filters"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="ct-filters-section d-none d-lg-block">
        <div className="container-fluid container-lg">
          <div className="row">
            <CategoryFilter />
            <div className="col-md-6 col-lg-3">
              <FilterDropDown data={dummyManufacturer} onChange={null} />
            </div>
            <div className="col-md-6 col-lg-3">
              <LengthFilter />
            </div>
            <div className="col-md-6 col-lg-3">
              <FilterDropDown data={dummyColors} onChange={null} />
            </div>
          </div>
          <div className="row collapse collapse-filter" id="collapse-filter">
            <div className="col-md-6 col-lg-3">
              <FilterDropDown data={dummyType} onChange={null} />
            </div>
          </div>
          <div id="expand-filter">
            <div id="ct-expand-filter">
              <span className="wes-filter" />{" "}
              <a
                href="#collapse-filter"
                className="expand-filter-link"
                data-bs-toggle="collapse"
              >
                {" "}
                SHOW MORE FILTERS
              </a>
            </div>
          </div>
          {/* selected filters */}
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="selected-filters">
                <div className="selected-filter-lft">
                  <h6>Selected Filters:</h6>
                  <div className="cateogry-selected">
                    <span>Category:</span>
                    <ul>
                      <li>
                        Audio Speakers &amp; PA{" "}
                        <i className="fa-solid fa-circle-xmark" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="border-link">
                  <button type="submit" className="btn btn-clear">
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* selected filters */}
        </div>
      </div>
      {/* Filter Products */}
      <div className="ct-filter-product">
        <div className="row row-spacing">
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product-test.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p className="wishlist">
                    <span className="wesWishlist wes-whishlist1" />
                  </p>
                  <small className="product-stage new-product">New</small>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    JBL Stadium GTO620 - 6.5" coaxial Car Audio Speaker
                  </a>
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                  <span className="pro-added">
                    <i className="fa-solid fa-check" /> Added
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-quantity">
                  <div className="qty-container">
                    <button className="qty-btn-minus btn-light" type="button">
                      <i className="fa fa-minus" />
                    </button>
                    <input type="text" name="qty" className="input-qty" />
                    <button className="qty-btn-plus btn-light" type="button">
                      <i className="fa fa-plus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product2.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    Transcend 512GB M.2 2280 Internal (SSD) Solid State Drive
                  </a>{" "}
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                </div>
                <div className="product-code buying-options">Code: CTT524</div>
                <div className="product-button button-effect">
                  <button type="submit" className="btn btn-products btn-buying">
                    <span className="wes-buying-option" />{" "}
                    <span className="btn-options">Buying Options</span>
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product3.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <small className="product-stage new-clearance">
                    CLEARANCE
                  </small>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    HIKVISION 2MP HD 1080P Night Vision Bullet Outdoor Wired
                  </a>
                </h6>
                <div className="product-unavailable">
                  <p>Temporarily Unavailable</p>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-button">
                  <button
                    type="submit"
                    className="btn btn-products btn-unavailable"
                  >
                    Unavailable
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product4.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    JBL Stadium GTO620 - 6.5" coaxial Car Audio Speaker
                  </a>
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-button button-effect">
                  <button
                    type="submit"
                    className="btn btn-products btn-addcart addToCart"
                  >
                    <span className="wes-cart" /> Add to Cart
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product5.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    JBL Stadium GTO620 - 6.5" coaxial Car Audio Speaker
                  </a>
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                  <span className="pro-added">
                    <span className="wes-added-cart" /> Added
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-quantity">
                  <div className="qty-container">
                    <button className="qty-btn-minus btn-light" type="button">
                      <i className="fa fa-minus" />
                    </button>
                    <input type="text" name="qty" className="input-qty" />
                    <button className="qty-btn-plus btn-light" type="button">
                      <i className="fa fa-plus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product7.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <small className="product-stage new-sale">Sale</small>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    HIKVISION 2MP HD 1080P Night Vision Bullet Outdoor Wired
                  </a>
                </h6>
                <div className="product-unavailable">
                  <p>Substituted</p>
                </div>
                <div className="product-code product-substituted">
                  <p>
                    Replacement:<a href="#.">ABC10101010</a>
                  </p>
                </div>
                <div className="product-button">
                  <button
                    type="submit"
                    className="btn btn-products btn-unavailable"
                  >
                    Unavailable
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product6.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    Transcend 512GB M.2 2280 Internal (SSD) Solid State Drive
                  </a>{" "}
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                </div>
                <div className="product-code buying-options">Code: CTT524</div>
                <div className="product-button button-effect">
                  <button type="submit" className="btn btn-products btn-buying">
                    <span className="wes-buying-option" />{" "}
                    <span className="btn-options">Buying Options</span>
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product8.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    JBL Stadium GTO620 - 6.5" coaxial Car Audio Speaker
                  </a>
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-button button-effect">
                  <button
                    type="submit"
                    className="btn btn-products btn-addcart addToCart"
                  >
                    <span className="wes-cart" />
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product9.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    JBL Stadium GTO620 - 6.5" coaxial Car Audio Speaker
                  </a>
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                  <span className="pro-added">
                    <span className="wes-added-cart" /> Added
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-quantity">
                  <div className="qty-container">
                    <button className="qty-btn-minus btn-light" type="button">
                      <i className="fa fa-minus" />
                    </button>
                    <input type="text" name="qty" className="input-qty" />
                    <button className="qty-btn-plus btn-light" type="button">
                      <i className="fa fa-plus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product10.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    Transcend 512GB M.2 2280 Internal (SSD) Solid State Drive
                  </a>{" "}
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                </div>
                <div className="product-code buying-options">Code: CTT524</div>
                <div className="product-button button-effect">
                  <button type="submit" className="btn btn-products btn-buying">
                    <span className="wes-buying-option" />{" "}
                    <span className="btn-options">Buying Options</span>
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product12.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    JBL Stadium GTO620 - 6.5" coaxial Car Audio Speaker
                  </a>
                </h6>
                <div className="product-price">
                  <p>$2100.00</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-button button-effect">
                  <button
                    type="submit"
                    className="btn btn-products btn-addcart addToCart"
                  >
                    <span className="wes-cart" />
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4">
            {/* Product */}
            <div className="product-list">
              <div className="product-items">
                <a href="#">
                  <img
                    src={require("../../assets/img/home-page/product-image/product11.png")}
                    alt="product-name"
                  />
                </a>
                <div className="wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <small className="product-stage new-sale">Sale</small>
                </div>
              </div>
              <div className="product-details">
                <h6 className="product-title">
                  <a href="#">
                    HIKVISION 2MP HD 1080P Night Vision Bullet Outdoor Wired
                  </a>
                </h6>
                <div className="product-discontinued">
                  <p>Substituted</p>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="product-button">
                  <button
                    type="submit"
                    className="btn btn-products btn-unavailable"
                  >
                    Discontinued
                  </button>
                </div>
              </div>
            </div>
            {/* Product */}
          </div>
        </div>
        <div className="row row-spacing">
          {/* Product Loading */}
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4 d-block d-sm-block d-md-block d-lg-block">
            {/* Product */}
            <div className="product-list">
              <div className="product-image-load" />
              <div className="product-title-load" />
              <div className="product-title-load" />
              <div className="product-btn-load" />
            </div>
            {/* Product */}
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mt-4 d-block d-sm-block d-md-block d-lg-block">
            {/* Product */}
            <div className="product-list">
              <div className="product-image-load" />
              <div className="product-title-load" />
              <div className="product-title-load" />
              <div className="product-btn-load" />
            </div>
            {/* Product */}
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-4 d-none d-sm-none d-md-block d-lg-block">
            {/* Product */}
            <div className="product-list">
              <div className="product-image-load" />
              <div className="product-title-load" />
              <div className="product-title-load" />
              <div className="product-btn-load" />
            </div>
            {/* Product */}
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mt-4 d-none d-sm-none d-md-none d-lg-block">
            {/* Product */}
            <div className="product-list">
              <div className="product-image-load" />
              <div className="product-title-load" />
              <div className="product-title-load" />
              <div className="product-btn-load" />
            </div>
            {/* Product */}
          </div>
          {/* Product Loading */}
        </div>
      </div>
      {/* Filter Products */}
    </div>
  );
};
