export const BulkButOption = () => {
  return (
    <div
      className="modal fade"
      id="orderStatus"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered orderStatus-dialog">
        <div className="modal-content">
          <p className="order-status-close" data-bs-dismiss="modal">
            <i className="fa-regular fa-circle-xmark" />
          </p>
          <div className="modal-body p-0">
            {/* order status */}
            <div className="order-status">
              <div className="order-status-list">
                <div className="order-title">
                  <h6>Order Code</h6>
                </div>
                <div className="order-dec">
                  <p>BPA-38SN</p>
                </div>
              </div>
              <div className="order-status-list">
                <div className="order-title">
                  <h6>Stock Status</h6>
                </div>
                <div className="order-dec pre-order-status">
                  <span>Pre-Order:</span>
                  <p>BPA-38SN</p>
                </div>
              </div>
              <div className="order-status-list">
                <div className="order-title cost-qty">
                  <h6>Cost EX</h6>
                  <p>QTY</p>
                </div>
                <div className="order-dec order-price-dec">
                  <div className="order-price">
                    <span>$16.50</span>
                    <p>
                      <a href="#.">+1</a>
                    </p>
                  </div>
                  <div className="order-price">
                    <span>$14.85</span>
                    <p>
                      <a href="#.">+10</a>
                    </p>
                  </div>
                  <div className="order-price">
                    <span>$13.12</span>
                    <p>
                      <a href="#.">+12</a>
                    </p>
                  </div>
                  <div className="order-price">
                    <span>$11.85</span>
                    <p>
                      <a href="#.">+15</a>
                    </p>
                  </div>
                  <div className="order-price">
                    <span>$14.85</span>
                    <p>RPR</p>
                  </div>
                </div>
              </div>
            </div>
            {/* order status */}
          </div>
        </div>
      </div>
    </div>
  );
};
