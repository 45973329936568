import { Fragment, useEffect, useState } from "react"; 
import useAxiosInstance from "../../axios/axiosInterceptor";

export const HowWorksSection = () => {
  const { loading, error, get, post } = useAxiosInstance();
  const [PromotionData, setPromotionData] = useState();
  useEffect(() => {
    get("https://cdn.wes.com.au/wesreact/newui/banner/Promotion.txt",(response) => {
        var responsedata = response.data;
        if (responsedata && responsedata.Promotion.length > 0) {
          setPromotionData(responsedata.Promotion);
        }
      });
  },[]);
  return (
    <Fragment>
      {PromotionData && (
        <section className="how-works-section">
          <div className="container-fluid container-lg">
            <div className="row">
              {PromotionData.map((row, index) => (
                <div className="col-4 col-md-4 col-lg-4 col-sm-p">
                  <div
                    className="how-works fast-delivery"
                    style={{ backgroundColor: row.background }}
                  >
                    <img src={row.image} alt="fast-delivery" />
                    <div className="how-works-content">
                      <h4>{row.title}</h4>
                      <p className="d-none d-md-block">{row.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};
