import { Fragment } from "react";
import { Link } from "react-router-dom";

export const CategoryBanner = ({ data }) => {
  return (
    <div className="col-lg-6">
      {data && (
        <Fragment>
          <div className="list-category">
            <h4>{data.title}</h4>
            <div className="row ca-list mt-4">
              {data.category.length > 0 &&
                data.category.map((row, index) => (
                  <div className="col-4 ca-list-info">
                    <img src={row.image} alt="category image" />
                    <Link to={row.url}>{row.name}</Link>
                  </div>
                ))}
            </div>
          </div>
          {data.shopall && (
            <div className="ca-category-title">
              <h5>{data.shopall.title}</h5>
              <div className="product-button button-effect">
                <Link to={data.shopall.url} className="shop-link">
                  {data.shopall.caption}
                </Link>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
