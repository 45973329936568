export const DeviceFilterMobile = () => {
  return (
    <div
      className="offcanvas offcanvas-end sorting-offcanvas"
      tabIndex={-1}
      id="mobFitdevice"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header filters-header border-bottom">
        <h5 id="offcanvasRightLabel">Device</h5>
        <div className="clear-filter">
          <a className="cc-clear">Clear Filter</a>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
      </div>
      <div className="offcanvas-body moboffcanvas-body">
        <div className="categ-form-check mob-filters">
          <div className="filter-body">
            <div className="categ-form-check mob-filters">
              <div className="categ-list">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipad"
                    name="ipad"
                  />
                  <label className="form-check-label" htmlFor="ipad">
                    ipad
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipad10"
                    name="ipad10"
                  />
                  <label className="form-check-label" htmlFor="ipad10">
                    ipad 10.2
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipad10"
                    name="ipad2"
                  />
                  <label className="form-check-label" htmlFor="ipad2">
                    ipad2
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipad3"
                    name="ipad3"
                  />
                  <label className="form-check-label" htmlFor="ipad3">
                    ipad3
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipadair"
                    name="ipadair"
                  />
                  <label className="form-check-label" htmlFor="ipadair">
                    ipad Air
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipadmini"
                    name="ipadmini"
                  />
                  <label className="form-check-label" htmlFor="ipadmini">
                    ipad Mini
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipadpro"
                    name="ipadpro"
                  />
                  <label className="form-check-label" htmlFor="ipadpro">
                    ipad Pro
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ipadpro"
                    name="ipadpro"
                  />
                  <label className="form-check-label" htmlFor="ipadpro">
                    ipad Pro 10
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone11"
                    name="iPhone11"
                  />
                  <label className="form-check-label" htmlFor="iPhone11">
                    iPhone 11
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone11pro"
                    name="iPhone11pro"
                  />
                  <label className="form-check-label" htmlFor="iPhone11pro">
                    iPhone 11 pro
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone12"
                    name="iPhone12"
                  />
                  <label className="form-check-label" htmlFor="iPhone12">
                    iPhone 12
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone12pro"
                    name="iPhone12pro"
                  />
                  <label className="form-check-label" htmlFor="iPhone12pro">
                    iPhone 12 Pro
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone12pro"
                    name="iPhone12pro"
                  />
                  <label className="form-check-label" htmlFor="iPhone13">
                    iPhone 13
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone13pro"
                    name="iPhone13pro"
                  />
                  <label className="form-check-label" htmlFor="iPhone13pro">
                    iPhone 13 Pro
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone14"
                    name="iPhone14"
                  />
                  <label className="form-check-label" htmlFor="iPhone14">
                    iPhone 14
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="iPhone14pro"
                    name="iPhone14pro"
                  />
                  <label className="form-check-label" htmlFor="iPhone14pro">
                    iPhone 14 pro
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer border-top">
        <button type="submit" className="btn btn-addcart">
          Apply
        </button>
      </div>
    </div>
  );
};
