import { Modal, Offcanvas } from "bootstrap";
import { GlobalContext } from "../../../Context/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import { shoppingCart } from "../../../assets/imageImport";
import { Link, useNavigate } from "react-router-dom";

export const PreviousOrderPopup = () => {
  const globalcontext = useContext(GlobalContext);
  const [isShown, setIsShown] = useState(false);
  const offcanvasRef = useRef(null);
  const history = useNavigate();
  var previousOrderModal;

  useEffect(() => {
    if (globalcontext.CartItem && !isShown) {
      var modalDiv = document.getElementById("previusOrderdiv");
      var previousOrderModal = new Modal(modalDiv);
      previousOrderModal.show();
      setIsShown(true);
      localStorage.setItem("previousOrderModal", true);
    }
  }, [globalcontext.CartItem]);

  const handleClick = () => {
    const modalElement = offcanvasRef.current;
    const modalInstance = Modal.getInstance(modalElement);

    if (modalInstance) {
      modalInstance.hide(); // Close the modal programmatically
    }
  };

  return (
    <div
      className="modal fade"
      id="previusOrderdiv"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      ref={offcanvasRef}
    >
      <div className="modal-dialog modal-dialog-centered modal-animate-center product-notfound-dialog">
        <div className="modal-content">
          <p className="order-status-close" data-bs-dismiss="modal">
            <i className="fa-regular fa-circle-xmark" />
          </p>
          <div className="modal-body p-0">
            {/* Product Not Found */}
            <div className="product-found">
              {/* Header */}
              <div className="product-found-title">
                <img
                  src={
                    "https://cdn.wes.com.au/wesreact/newui/img/cart/shopping-cart.svg"
                  }
                  alt="product-notfound"
                  className="img-fluid"
                />
                <h4>PRODUCTS FOUND IN CART</h4>
              </div>
              {/* Header */}
              {/* Body */}
              <div className="product-found-body">
                <p className="text-center">
                  Your cart still contains items from a previous session. Do you
                  want to proceed with completing that order?
                </p>
                <div className="product-foundButton">
                  <div className="button-effect product-found-btn">
                    <Link
                      to="/OrderDetails"
                      onClick={handleClick}
                      className="btn btn-products btn-addcart btn-proNot-found"
                    >
                      <i className="fa-solid fa-check" /> Continue with Previous
                      Order
                    </Link>
                  </div>
                  <div className="border-link">
                    <a
                      href="#"
                      className="link-proNot-found"
                      data-bs-dismiss="modal"
                    >
                      <i className="fa-solid fa-xmark" /> Cancel Previous Order
                    </a>
                  </div>
                </div>
              </div>
              {/* Body */}
            </div>
            {/* Product Not Found */}
          </div>
        </div>
      </div>
    </div>
  );
};
