import { Link } from "react-router-dom";
import { axiosInstance } from "../../axios/axiosInstance";
import { useEffect, useState } from "react";

export const LinkProductPage = ({
  productId,
  productCode,
  classList,
  value,
}) => {
  const [productLink, setroductLink] = useState(null);
  useEffect(() => {
    GetProductLink(productId, productCode);
  }, [productId, productCode]);

  const GetProductLink = async (_Id, _Code) => {
    if (_Id || _Code) {
      const response = await axiosInstance.post("GetStaticData/GetProductUrl", {
        productId: String(_Id),
        sku: String(_Code),
      });
      console.log("GetStaticData/GetProductUrl", response);
      if (response.data) {
        let res = response.data;
        if (res.statusCode == 0 && res.link) {
          setroductLink(res.link);
        } else {
        }
      }
    }
  };
  return (
    <Link to={productLink} className={classList}>
      {value}
    </Link>
  );
};
