import { Fragment, useEffect, useState } from "react";
import { category1, category2, category3 } from "../../assets/imageImport";
import useAxiosInstance from "../../axios/axiosInterceptor";
import { Link } from "react-router-dom";
import { CategoryBanner } from "./CategoryBanner";
import { BrandBanner } from "./BrandBanner";
export const CategoryBrandSection = () => {
  const { loading, error, get, post } = useAxiosInstance();
  const [brandJsonData, setBrandJsonData] = useState(null);
  const [categoryJsonData, setCategoryJsonData] = useState(null);
  useEffect(() => {
    get("https://cdn.wes.com.au/wesreact/newui/banner/brandcategory.txt", (response) => {
      var responsedata = response.data;
      if (
        responsedata &&
        responsedata.BrandCategory &&
        responsedata.BrandCategory.category
      ) {
        setCategoryJsonData(responsedata.BrandCategory.category);
      }
      if (
        responsedata &&
        responsedata.BrandCategory &&
        responsedata.BrandCategory.brand
      ) {
        setBrandJsonData(responsedata.BrandCategory.brand);
      }
    });
  }, []);
  return (
    <section className="category-brand-section">
      <div className="container-fluid container-lg">
        <div className="row">
          <div className="col-lg-12">
            <div className="category-brand">
              <div className="row">
                {/* Categories */}
                <CategoryBanner data={categoryJsonData} /> 
                {/* Categories */}
                {/* Brands */}
              <BrandBanner data={brandJsonData} />
                {/* Brands */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
