import { useEffect, useState } from "react";
import { resolveImageUrl } from "./common";

export const ImageWithDefault = ({ src, onerror, classList, alt, style }) => {
  const [imgSrc, setImgSrc] = useState(src);
  useEffect(() => {
     setImgSrc(resolveImageUrl(src)); 
  }, [src]);
  const handleImageError = () => {
    if (onerror == null || onerror == "") {
      onerror = process.env.REACT_APP_NoImage;
    }
    setImgSrc(onerror);
  };

  return (
    <img
      src={imgSrc}
      onError={handleImageError}
      className={classList}
      alt={alt}
      style={style}
    />
  );
};
