export const CategoryFilter = () => {
  return (
    <div className="col-md-6 col-lg-3">
      <div className="ct-filters-list">
        <div className="ct-filter category-filter">
          <div className="ct-filter-top">
            <p>
              Category <span>1</span>
            </p>
          </div>{" "}
          <i className="fa-solid fa-angle-down" />
          <p />
        </div>
        {/* Filter Selection */}
        <div className="ct-category-filter">
          <div className="categ-search position-relative">
            <input
              className="form-control me-2"
              type="text"
              placeholder="Search for.."
              aria-label="Search"
            />
            <i className="fa-solid fa-magnifying-glass" />
          </div>
          <div className="categ-list">
            {/* Av Tv Sat Installation */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="checkmainlevel1"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel1">
                    Av Tv Sat Installation
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#mainlevel1"
                  aria-expanded="false"
                  aria-controls="mainlevel1"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              <div
                id="mainlevel1"
                className="categ-first-level accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                {/* inner child 1 */}
                <div className="categ-childTwo innerchild">
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkchildlevel1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkchildlevel1"
                      >
                        AV Equipment
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#avtwo"
                      aria-expanded="false"
                      aria-controls="avtwo"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="avtwo"
                    className="categ-first-level accordion-collapse collapse categoryLevels"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="avchildthree"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="avchildthree"
                        >
                          Category3
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#avthree"
                        aria-expanded="false"
                        aria-controls="avthree"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="avthree"
                      className="categ-first-level accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="avchildfour"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="avchildfour"
                          >
                            Category4
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#avfour"
                          aria-expanded="false"
                          aria-controls="avfour"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="avfour"
                        className="categ-first-level accordion-collapse innerchild collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              type="checkbox"
                              id="avchildfour"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="avchildfour"
                            >
                              Category5
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#avfive"
                            aria-expanded="false"
                            aria-controls="avfive"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="avfive"
                          className="categ-second-level accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              type="checkbox"
                              id="avchildfiveone"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="avchildfiveone"
                            >
                              Last Category Items
                            </label>
                          </div>
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              type="checkbox"
                              id="avchildfivetwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="avchildfivetwo"
                            >
                              Last Category Items
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* inner child 1*/}
                {/* inner child 2 */}
                <div className="categ-childTwo innerchild">
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkchildlevel2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkchildlevel2"
                      >
                        AV Extenders
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#avextender"
                      aria-expanded="false"
                      aria-controls="avextender"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="avextender"
                    className="categ-first-level accordion-collapse collapse categoryLevels"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="audiochildone"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="audiochildone"
                        >
                          Audio/Video Over CAT5 Extenders
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#audiotwo"
                        aria-expanded="false"
                        aria-controls="audiotwo"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="audiotwo"
                      className="categ-first-level accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="audiochilthree"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="audiochilthree"
                          >
                            Category3
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#audiothree"
                          aria-expanded="false"
                          aria-controls="avfour"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="audiothree"
                        className="categ-first-level accordion-collapse innerchild collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              type="checkbox"
                              id="audiochilfour"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="audiochilfour"
                            >
                              Category4
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#audiochilfour"
                            aria-expanded="false"
                            aria-controls="audiochilfour"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="audiochilfour"
                          className="categ-first-level accordion-collapse innerchild collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                type="checkbox"
                                id="avchildfour"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="avchildfour"
                              >
                                Category5
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#avfive"
                              aria-expanded="false"
                              aria-controls="avfive"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="avfive"
                            className="categ-second-level accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                type="checkbox"
                                id="avchildfiveone"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="avchildfiveone"
                              >
                                Last Category Items
                              </label>
                            </div>
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                type="checkbox"
                                id="avchildfivetwo"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="avchildfivetwo"
                              >
                                Last Category Items
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* inner child 2*/}
              </div>
            </div>
            {/* Av Tv Sat Installation */}
            {/* Cables & Connectors */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel2"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel2">
                    Cables &amp; Connectors
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#cavbleconnectors"
                  aria-expanded="false"
                  aria-controls="cavbleconnectors"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="cavbleconnectors"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="cabchildtwo"
                    />
                    <label className="form-check-label" htmlFor="cabchildtwo">
                      Adaptors
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#cabtwo"
                    aria-expanded="false"
                    aria-controls="cabtwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="cabtwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cabchildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cabchildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#cabthree"
                      aria-expanded="false"
                      aria-controls="cabthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="cabthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="cabchildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="cabchildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#cabfour"
                        aria-expanded="false"
                        aria-controls="cabfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="cabfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="cabchildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="cabchildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#cabfive"
                          aria-expanded="false"
                          aria-controls="cabfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="cabfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="avchildfiveone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="avchildfiveone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="avchildfivetwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="avchildfivetwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="cavbleconnectors"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="plugchildtwo"
                    />
                    <label className="form-check-label" htmlFor="plugchildtwo">
                      Audio Plug Adaptors
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#plugtwo"
                    aria-expanded="false"
                    aria-controls="plugtwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="plugtwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="plugchildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="plugchildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#plugthree"
                      aria-expanded="false"
                      aria-controls="plugthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="plugthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="plugchildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="plugchildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#plugfour"
                        aria-expanded="false"
                        aria-controls="plugfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="plugfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="cabchildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="plugchildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#plugfive"
                          aria-expanded="false"
                          aria-controls="plugfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="plugfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="plugchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="plugchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="plugchildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="plugchildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
            </div>
            {/* Cables & Connectors */}
            {/* Audio Speakers & PA */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel3"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel3">
                    Audio Speakers &amp; PA
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#speakerspa"
                  aria-expanded="false"
                  aria-controls="speakerspa"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="speakerspa"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="featurechildtwo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="featurechildtwo"
                    >
                      Featured Brands Audio
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#featuretwo"
                    aria-expanded="false"
                    aria-controls="featuretwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="featuretwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="featurechildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="featurechildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#featurethree"
                      aria-expanded="false"
                      aria-controls="featurethree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="featurethree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="featurechildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="featurechildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#featurefour"
                        aria-expanded="false"
                        aria-controls="featurefour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="featurefour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="featurechildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="featurechildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#featurefive"
                          aria-expanded="false"
                          aria-controls="featurefive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="featurefive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="featurechildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="featurechildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="featurechildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="featurechildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="speakerspa"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="daytonchildtwo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="daytonchildtwo"
                    >
                      Dayton Audio USA
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#daytontwo"
                    aria-expanded="false"
                    aria-controls="featuretwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="daytontwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="daytonchildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="daytonchildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#daytonthree"
                      aria-expanded="false"
                      aria-controls="daytonthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="daytonthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="daytonchildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="daytonchildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#daytonfour"
                        aria-expanded="false"
                        aria-controls="daytonfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="daytonfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="daytonchildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="daytonchildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#daytonfive"
                          aria-expanded="false"
                          aria-controls="daytonfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="daytonfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="daytonchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="daytonchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="daytonchildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="daytonchildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
            </div>
            {/* Audio Speakers & PA */}
            {/* Data & Computer */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel4"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel4">
                    Data &amp; Computer
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#datacomputer"
                  aria-expanded="false"
                  aria-controls="datacomputer"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="datacomputer"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="cablechildone"
                    />
                    <label className="form-check-label" htmlFor="cablechildone">
                      Cables &amp; Leads
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#cableone"
                    aria-expanded="false"
                    aria-controls="cableone"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="cableone"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cablechildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cablechildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#cablethree"
                      aria-expanded="false"
                      aria-controls="cablethree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="cablethree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="cablechildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="cablechildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#cablefour"
                        aria-expanded="false"
                        aria-controls="cablefour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="cablefour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="cablechildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="cablechildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#cablefive"
                          aria-expanded="false"
                          aria-controls="cablefive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="cablefive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="cablechildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="cablechildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="cablechildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="cablechildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="datacomputer"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="scsichildtwo"
                    />
                    <label className="form-check-label" htmlFor="scsichildtwo">
                      SCSI Leads
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#scsitwo"
                    aria-expanded="false"
                    aria-controls="scsitwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="scsitwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="scsichildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="scsichildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#scsithree"
                      aria-expanded="false"
                      aria-controls="scsithree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="scsithree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="scsichildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="scsichildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#scsifour"
                        aria-expanded="false"
                        aria-controls="scsifour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="scsifour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="scsichildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="scsichildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#scsifive"
                          aria-expanded="false"
                          aria-controls="scsifive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="scsifive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="scsichildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="scsichildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="scsichildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="scsichildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
            </div>
            {/* Data & Computer */}
            {/* Security */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel5"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel5">
                    Security
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#securityone"
                  aria-expanded="false"
                  aria-controls="securityone"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="securityone"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="securitychildtwo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="securitychildtwo"
                    >
                      Cables &amp; Leads
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#securitytwo"
                    aria-expanded="false"
                    aria-controls="securitytwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="securitytwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="securitychildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="securitychildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#securitythree"
                      aria-expanded="false"
                      aria-controls="securitythree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="securitythree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="securitychildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="securitychildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#securityfour"
                        aria-expanded="false"
                        aria-controls="securityfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="securityfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="securitychildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="securitychildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#securityfive"
                          aria-expanded="false"
                          aria-controls="securityfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="securityfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="securitychildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="securitychildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="securitychildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="securitychildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="securityone"
                className="categ-first-level accordion-collapse collapse categoryLevels"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="alaramchildtwo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="alaramchildtwo"
                    >
                      Alarm
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#alaramtwo"
                    aria-expanded="false"
                    aria-controls="alaramtwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="alaramtwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="alaramchildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="alaramchildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#alaramthree"
                      aria-expanded="false"
                      aria-controls="alaramthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="alaramthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="alaramchildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="alaramchildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#alaramfour"
                        aria-expanded="false"
                        aria-controls="alaramfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="alaramfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="alaramchildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="alaramchildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#securityfive"
                          aria-expanded="false"
                          aria-controls="securityfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="securityfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="alaramchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="alaramchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="alaramchildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="alaramchildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
            </div>
            {/* Security */}
            {/* Cellular Accessories */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel6"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel6">
                    Cellular Accessories
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#celluaraccess"
                  aria-expanded="false"
                  aria-controls="mainlevel7"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="celluaraccess"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="usbchildone"
                    />
                    <label className="form-check-label" htmlFor="usbchildone">
                      USB Type-C
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#usbone"
                    aria-expanded="false"
                    aria-controls="usbone"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="usbone"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="usbchildtwo"
                      />
                      <label className="form-check-label" htmlFor="usbchildtwo">
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#usbtwo"
                      aria-expanded="false"
                      aria-controls="usbtwo"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="usbtwo"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="usbchildthree"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="usbchildthree"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#usbthree"
                        aria-expanded="false"
                        aria-controls="usbthree"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="usbthree"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="usbchildfour"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="usbchildfour"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#usbfour"
                          aria-expanded="false"
                          aria-controls="usbfour"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="usbfour"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="usbchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="usbchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="usbchildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="usbchildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="celluaraccess"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="applechildtwo"
                    />
                    <label className="form-check-label" htmlFor="applechildtwo">
                      Apple™ Lightning®
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#appletwo"
                    aria-expanded="false"
                    aria-controls="appletwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="appletwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="applechildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="applechildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#applethree"
                      aria-expanded="false"
                      aria-controls="applethree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="applethree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="applechildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="applechildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#applechildfour"
                        aria-expanded="false"
                        aria-controls="applechildfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="applechildfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="applechildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="applechildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#applefive"
                          aria-expanded="false"
                          aria-controls="applefive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="applefive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="applechildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="applechildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="applechildlastwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="applechildlastwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
            </div>
            {/* Cellular Accessories */}
            {/* Communication Accessories */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel7"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel7">
                    Communication Accessories
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#communication"
                  aria-expanded="false"
                  aria-controls="communication"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="communication"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="communicationchildtwo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="communicationchildtwo"
                    >
                      Antenna Accessories
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#communicationtwo"
                    aria-expanded="false"
                    aria-controls="communicationtwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="communicationtwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="communicationchildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="communicationchildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#communicationthree"
                      aria-expanded="false"
                      aria-controls="communicationthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="communicationthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="communicationchildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="communicationchildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#communicationfour"
                        aria-expanded="false"
                        aria-controls="communicationfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="communicationfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="communicationchildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="communicationchildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#communicationfive"
                          aria-expanded="false"
                          aria-controls="communicationfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="communicationfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="communicationlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="communicationlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="communicationlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="communicationlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="communication"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="mountingchildtwo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="mountingchildtwo"
                    >
                      Antenna Mounting
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#mountingtwo"
                    aria-expanded="false"
                    aria-controls="mountingtwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="mountingtwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mountingchildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="mountingchildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#mountingthree"
                      aria-expanded="false"
                      aria-controls="mountingthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="mountingthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="mountingchildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="mountingchildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#mountingfour"
                        aria-expanded="false"
                        aria-controls="mountingfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="mountingfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="mountingchildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mountingchildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mountingfive"
                          aria-expanded="false"
                          aria-controls="mountingfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="mountingfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="mountingchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mountingchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="mountingchildlastwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mountingchildlastwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
            </div>
            {/* Communication Accessories */}
            {/* Tools & Test */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel8"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel8">
                    Tools &amp; Test
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#toolstest"
                  aria-expanded="false"
                  aria-controls="mainlevel10"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="toolstest"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="handchildone"
                    />
                    <label className="form-check-label" htmlFor="handchildone">
                      Hand Tools
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#handone"
                    aria-expanded="false"
                    aria-controls="handone"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="handone"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="handchildtwo"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="handchildtwo"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#handtwo"
                      aria-expanded="false"
                      aria-controls="handtwo"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="handtwo"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="handchildthree"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="handchildthree"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#handthree"
                        aria-expanded="false"
                        aria-controls="handthree"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="handthree"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="handchildfour"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="handchildfour"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#handfour"
                          aria-expanded="false"
                          aria-controls="handfour"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="handfour"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="handchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="handchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="handchildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="handchildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="toolstest"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="screwchildone"
                    />
                    <label className="form-check-label" htmlFor="screwchildone">
                      Screwdrivers Cordless
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#screwone"
                    aria-expanded="false"
                    aria-controls="screwone"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="screwone"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="screwchildtwo"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="screwchildtwo"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#screwtwo"
                      aria-expanded="false"
                      aria-controls="screwtwo"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="screwtwo"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="screwchildthree"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="screwchildthree"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#screwthree"
                        aria-expanded="false"
                        aria-controls="screwthree"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="screwthree"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="screwchildfour"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="screwchildfour"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#screwfour"
                          aria-expanded="false"
                          aria-controls="screwfour"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="screwfour"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="screwchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="screwchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="screwchildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="screwchildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
            </div>
            {/* Tools & Test */}
            {/* Power & Lighting */}
            <div className="ct-categ-list">
              <div className="categ-listing">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    type="checkbox"
                    id="checkmainlevel9"
                  />
                  <label className="form-check-label" htmlFor="checkmainlevel9">
                    Power &amp; Lighting
                  </label>
                </div>
                <p
                  data-bs-toggle="collapse"
                  data-bs-target="#powerlighting"
                  aria-expanded="false"
                  aria-controls="powerlighting"
                >
                  <i className="fa-solid fa-plus" />
                </p>
              </div>
              {/* Inner Levels */}
              <div
                id="powerlighting"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="batterieschildone"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="batterieschildone"
                    >
                      Batteries Primary
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#batteriesone"
                    aria-expanded="false"
                    aria-controls="batteriesone"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="batteriesone"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="batterieschildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="batterieschildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#batteriesthree"
                      aria-expanded="false"
                      aria-controls="batteriesthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="batteriesthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="batterieschildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="batterieschildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#batteriesfour"
                        aria-expanded="false"
                        aria-controls="batteriesfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="batteriesfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="batterieschildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="batterieschildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#batteriesfive"
                          aria-expanded="false"
                          aria-controls="batteriesfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="batteriesfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="batterieschildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="batterieschildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="batterieschildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="batterieschildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              {/* Inner Levels */}
              <div
                id="powerlighting"
                className="categ-first-level accordion-collapse collapse categoryLevels"
                data-bs-parent="#accordionExample"
              >
                <div className="categ-childOne-inner">
                  <div className="categ-form-check form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      type="checkbox"
                      id="litiumchildtwo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="litiumchildtwo"
                    >
                      Lithium Batteries
                    </label>
                  </div>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#litiumtwo"
                    aria-expanded="false"
                    aria-controls="litiumtwo"
                  >
                    <i className="fa-solid fa-plus" />
                  </p>
                </div>
                <div
                  id="litiumtwo"
                  className="categ-first-level accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="litiumchildthree"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="litiumchildthree"
                      >
                        Category3
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#litiumthree"
                      aria-expanded="false"
                      aria-controls="litiumthree"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="litiumthree"
                    className="categ-first-level accordion-collapse innerchild collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-childOne-inner">
                      <div className="categ-form-check form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          type="checkbox"
                          id="litiumchildfour"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="litiumchildfour"
                        >
                          Category4
                        </label>
                      </div>
                      <p
                        data-bs-toggle="collapse"
                        data-bs-target="#litiumfour"
                        aria-expanded="false"
                        aria-controls="litiumfour"
                      >
                        <i className="fa-solid fa-plus" />
                      </p>
                    </div>
                    <div
                      id="litiumfour"
                      className="categ-first-level accordion-collapse innerchild collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="categ-childOne-inner">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="litiumchildfive"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="litiumchildfive"
                          >
                            Category5
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#litiumfive"
                          aria-expanded="false"
                          aria-controls="litiumfive"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="litiumfive"
                        className="categ-second-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            type="checkbox"
                            id="litiumchildlastone"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="litiumchildlastone"
                          >
                            Last Category Items
                          </label>
                        </div>
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="litiumchildlasttwo"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="litiumchildlasttwo"
                          >
                            Last Category Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inner Levels */}
              <div
                id="mainlevel11"
                className="categ-first-level accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                {/* inner child 1 */}
                <div className="categ-childTwo innerchild">
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkchildlevel9"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkchildlevel9"
                      >
                        Batteries Primary
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#childlevel16"
                      aria-expanded="false"
                      aria-controls="childlevel16"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="childlevel16"
                    className="categ-second-level accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="innerchildlevel19_1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="innerchildlevel19_1"
                      >
                        Lithium Batteries
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="innerchildlevel19_2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="innerchildlevel19_2"
                      >
                        Button Cells
                      </label>
                    </div>
                  </div>
                </div>
                {/* inner child 1*/}
                {/* inner child 2 */}
                <div className="categ-childTwo innerchild">
                  <div className="categ-childOne-inner">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkchildlevel10"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkchildlevel10"
                      >
                        Batteries Rechargeable
                      </label>
                    </div>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target="#childlevel17"
                      aria-expanded="false"
                      aria-controls="childlevel9"
                    >
                      <i className="fa-solid fa-plus" />
                    </p>
                  </div>
                  <div
                    id="childlevel17"
                    className="categ-second-level accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="innerchildlevel20_1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="innerchildlevel20_1"
                      >
                        LiFePO4 Lithium Iron Phosphate
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="innerchildlevel20_2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="innerchildlevel20_2"
                      >
                        Ni-MH Batteries
                      </label>
                    </div>
                  </div>
                </div>
                {/* inner child 2*/}
              </div>
            </div>
            {/* Power & Lighting */}
          </div>
          <div className="reset-filter">
            <a href="#.">Reset Filter</a>
          </div>
        </div>
        {/* Filter Selection */}
      </div>
    </div>
  );
};
