import { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../Context/GlobalContext";

const useAxiosInstance = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const globalcontext = useContext(GlobalContext);
  const axiosInstance = axios.create({
    baseURL: window.location.origin + "/api/",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const post = useCallback(
    async (url, data, SuccessFunction, config = {}) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post(url, data, config);
        if (response.status === 200) {
          globalcontext.setSessionTimeout(false);
          SuccessFunction(response);
        } else if (response.data.title === "Unauthorized") {
          globalcontext.setSessionTimeout(true);
        }
        return response;
      } catch (error) {
        console.log("inside error", error);
        setError(error.response?.data || error.message);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [axiosInstance]
  );

  const get = useCallback(
    async (url, SuccessFunction, config = {}) => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(url, config);
        if (response.status === 200) {
          globalcontext.setSessionTimeout(false);
          SuccessFunction(response);
        } else if (response.data.title === "Unauthorized") {
          globalcontext.setSessionTimeout(true);
        }
        return response;
      } catch (error) {
        console.log("inside error", error);
        setError(error.response?.data || error.message);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [axiosInstance]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  return { loading, error, get, post };
};

export default useAxiosInstance;
