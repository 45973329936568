import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const SearchSuggestion = () => {
  const history = useNavigate();
  const [SearchTxt, setSearchTxt] = useState("");
  const { srctxt } = useParams();

  useEffect(() => {
    if (srctxt) setSearchTxt(srctxt);
  }, [srctxt]);

const handleSearch=(event)=>{ 
  history("/powersearch/"+SearchTxt);  
  event.preventDefault();
}

  return (
    <Fragment>
      <div
        className="header-search position-relative d-none d-lg-block"
        id="input"
      >
        <input
          className="header-search-form form-control me-2"
          type="text"
          id="searchbox"
          name="searchbox"
          placeholder="Search here"
          aria-label="Search"
          value={SearchTxt}
          onChange={(e) => setSearchTxt(e.target.value)}
        />
        <span className="wes-search" /> 
        <div id="suggest" />
        <div className="search-backdrop">
        <div className="search-backdrop-inner" />
      </div>
      </div>
     
      <div className="search-suggestions">




        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="cc-mb-search">
                <div className="close-search">
                  <i
                    id="close-search"
                    className="fa-regular fa-circle-xmark d-none d-lg-block"
                  />
                  <div
                    id="close-search-mob"
                    className="mobile-close-search d-block d-lg-none"
                  >
                    <span className="wes-down-arrow" />
                    <small>Close</small>
                  </div>
                </div>
                <div className="suggestion-input button-effect d-block d-lg-none">
                  <div className="cc-mob-sugg position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Here..."
                    />
                    <span className="wes-search" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-none d-lg-block">
            <div className="col-lg-8 offset-lg-2">
              <div className="search-input">
                <form>
                  <div className="suggestion-input button-effect">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Here..."
                      value={SearchTxt}
                      onChange={(e) => setSearchTxt(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-products btn-search d-none d-lg-block"
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row tag-products mt-0 mt-lg-1">
            <div className="col-lg-3 search-tags-lft">
              <div className="search-tags bg-white">
                <div className="search-tags-title ">
                  <h5>Suggestions</h5>
                </div>
                <div className="search-tags-details">
                  <ul>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> <b>HDMI</b> Cable
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> <b>HDMI</b> Over Cat5
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> Wireless <b>HDMI</b>{" "}
                        Extender
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> High Speed <b>HDMI</b>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> Ultra Speed <b>HDMI</b>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> USB <b>HDMI</b> Cable{" "}
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> <b>HDMI</b> Cable
                        Converter{" "}
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> <b>HDMI</b> 4K{" "}
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> Prolink{" "}
                      </a>
                    </li>
                    <li>
                      <a href="powersearch">
                        <span className="wes-search" /> Australian Monitor{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-9 search-tags-rht">
              <div className="search-products bg-white">
                <div className="search-tags-title">
                  <h5>Product Suggestions</h5>
                </div>
                <div className="search-suggestion-list">
                  <div className="search-carousel">
                    {/* Product */}
                    <div className="row">
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product12.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                Cable HDMI V2.1 High Speed Male-Male 3M
                              </h6>
                              <div className="product-price">
                                <p>$8.90</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product13.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                30CM HDMI Panel Mount Cable
                              </h6>
                              <div className="product-price">
                                <p>$15.00</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product14.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                HDMI To VGA Converter
                              </h6>
                              <div className="product-price">
                                <p>$27.00</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product15.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                Avlink Hdmi High Grade Cables
                              </h6>
                              <div className="product-price">
                                <p>$4.90</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product16.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                Cable HDMI V2.1 High Speed Male-Male 3M
                              </h6>
                              <div className="product-price">
                                <p>$8.90</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product17.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                Usb-c To Hdmi / 2x Usb-a / 2x Usb-c Adaptor
                              </h6>
                              <div className="product-price">
                                <p>$39.50</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product18.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                Hdmi Splitters 1080p - Pro2
                              </h6>
                              <div className="product-price">
                                <p>$100.00</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result"></a>
                        <div className="product-list search-product-list">
                          <a
                            href="powersearch"
                            className="cc-serach-result"
                          ></a>
                          <div className="product-items search-product-items">
                            <a href="powersearch" className="cc-serach-result">
                              <img
                                src={require("../../assets/img/home-page/product-image/product19.png")}
                                alt="product-name"
                              />
                            </a>
                          </div>
                          <div className="product-details">
                            <h6 className="product-title">
                              Hdspider™ Hdmi Over Cat5e/6 Transmitter
                            </h6>
                            <div className="product-price">
                              <p>$686.25</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product20.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                4x1 Hdmi Switch 4k60 + Audio Extractor
                              </h6>
                              <div className="product-price">
                                <p>$105.00</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product21.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                Hdmi Av Switch Avlink
                              </h6>
                              <div className="product-price">
                                <p>$8.90</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product3.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                Mini-hdmi Male To Hdmi-a Female Adaptor
                              </h6>
                              <div className="product-price">
                                <p>$5.75</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                      {/* Search Results */}
                      <div className="col-4 col-sm-3 col-md-2 col-lg-2 search-results mt-3">
                        <a href="powersearch" className="cc-serach-result">
                          <div className="product-list search-product-list">
                            <div className="product-items search-product-items">
                              <img
                                src={require("../../assets/img/home-page/product-image/product4.png")}
                                alt="product-name"
                              />
                            </div>
                            <div className="product-details">
                              <h6 className="product-title">
                                4k Hdmi Over Cat5/6 Splitter Extender
                              </h6>
                              <div className="product-price">
                                <p>$820.00</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* Search Results */}
                    </div>
                    {/* Product */}
                    <div className="row">
                      <div className="col-lg-12">
                        <a
                          href="powersearch"
                          className="d-block py-2 view-results"
                        >
                          View All Results
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
