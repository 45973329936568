import { Fragment, useEffect, useState } from "react";
import { BreadCrumb } from "../../Layout/Common/breadCrumb";
import { MobileViewTabs } from "./mobileViewTabs";
import { HeaderSeaction } from "./headerSection";
import { SearchProductList } from "./searchProductList";
import { FitMyDevice } from "./fitMyDeviceSection";
import { useParams } from "react-router-dom";
import { SortbyMobile } from "../../Layout/Common/SortbyMob";
import { FilterPanelMobile } from "../../Layout/Common/FilterPanelMob";
import { ModelFilterMobile } from "../../Layout/Common/modelFilterMobile";
import { DeviceFilterMobile } from "../../Layout/Common/deviceFilterMobile";

export const PowerSearch = () => {
  const [SearchBreadcrumb, setSearchBreadcrumb] = useState([]);
  const [SearchResult, setSearchResult] = useState([]);
  const { srctxt } = useParams();
  useEffect(() => {
    setSearchBreadcrumb([{ name: `Search Result for "${srctxt}"`, url: null }]);
    setSearchResult({ searchtext: srctxt, nproducts: "876" });
  }, [srctxt]);

  return (
    <Fragment>
      <BreadCrumb data={SearchBreadcrumb} />
      <section className="filter-product-section">
        <MobileViewTabs />
        <div className="container-fluid container-lg">
          <HeaderSeaction data={SearchResult} />
          <div className="row mt-3">
            <div className="col-lg-12">
              {/* Products Filters Section */}
              <div className="products-filter">
                {/* Tabs */}
                <div className="ct-nav-filters">
                  <ul
                    className="nav nav-pills products-filter-nav"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="products-filters-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#products-filters"
                        type="button"
                        role="tab"
                        aria-controls="products-filters"
                        aria-selected="true"
                      >
                        <span className="wes-filter" />
                        <span className="d-none d-lg-block">Filters </span>
                        <span className="cc-filter-name d-block d-lg-none">
                          Products
                        </span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="products-fitmy-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#products-fitmy"
                        type="button"
                        role="tab"
                        aria-controls="products-fitmy"
                        aria-selected="false"
                      >
                        <span className="wes-fitmydevice" />{" "}
                        <span className="cc-filter-name">Fits My Device</span>
                      </button>
                    </li>
                  </ul>
                </div>
                {/* Tabs */}
                {/* Tabs Content */}
                <div className="tab-content" id="pills-tabContent">
                  {/* Filter tab */}
                  <SearchProductList />
                  {/* Filter tab */}
                  {/* Fit my Device tab */}
                  <FitMyDevice />
                  {/* Fit my Device tab */}
                </div>
                {/* Tabs Content */}
              </div>
              {/* Products Filters Section */}
            </div>
          </div>
        </div>
      </section>
      <SortbyMobile />
      <FilterPanelMobile />
      <ModelFilterMobile />
      <DeviceFilterMobile />
    </Fragment>
  );
};
