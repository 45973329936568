import { useEffect, useState } from "react";

export const LoginComp = () => {
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [Hdf, setHdf] = useState("");
  useEffect(() => {
    localStorage.setItem("previousOrderModal", false);
  }, []);
  return (
    <div className="container mt-5">
      <form
        action={window.location.origin + "/api/" + "Authenticate/CheckLogin"}
        method="POST"
      >
        <div id="loginNew" className="login_section offset-3 col-6">
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={Username}
              autoComplete="off"
              maxLength="50"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              placeholder="Username / Email Address"
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              autoComplete="off"
              id="password"
              name="password"
              value={Password}
              maxLength="15"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
          </div>
          <button type="submit" className="btn btn-danger danger">
            Login
          </button>
        </div>
        <input type="hidden" name="cUrl" id="cUrl" value={""} />
        <input
          type="text"
          value={Hdf}
          name="hdfUrl"
          id="hdfUrl"
          style={{ visibility: "hidden" }}
        />
      </form>
    </div>
  );
};
