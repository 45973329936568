export const HeaderSeaction = ({data}) => {

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="subcategory-title subcategory-pro-list">
          <h4>
            Results for <b>'{data.searchtext}'</b> <span>{data.nproducts} Products</span>
          </h4>
          <div className="sort-filters">
            <div className="card-row">
              <a href="#." className="grid-products active">
                <span className="wes-card-filter" />
              </a>
              <a href="#" className="row-products">
                <span className="wes-row-filter" />
              </a>
            </div>
            <div className="sortby-dropdown d-none d-lg-block">
              <button
                href="#"
                role="button"
                data-value=""
                className="select-dropdown__button"
              >
                <span>Sort by : Relevance</span>{" "}
                <i className="fa-solid fa-chevron-down" />
              </button>
              <ul className="select-dropdown__list">
                <li data-value={1} className="select-dropdown__list-item">
                  Newest
                </li>
                <li data-value={2} className="select-dropdown__list-item">
                  Price : Low to High
                </li>
                <li data-value={3} className="select-dropdown__list-item">
                  Price : high to Low
                </li>
                <li data-value={4} className="select-dropdown__list-item">
                  Popular
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
