export const dummyManufacturer = {
  filterName: "manufacture",
  filterList: [
    { id: "aerpro", name: "Aerpro", count: 47 },
    { id: "cellink", name: "Cellink", count: 22 },
    { id: "avlabls", name: "AVLabs", count: 3 },
    { id: "benelec", name: "Benelec", count: 2 },
    { id: "bosch", name: "Bosch", count: 91 },
    { id: "cypress", name: "Cypress", count: 6 },
    { id: "daichi", name: "Daichi", count: 230 },
    { id: "dayton", name: "Dayton Audio", count: 356 },
    { id: "edifier", name: "Edifier", count: 13 },
    { id: "electro", name: "Electro-Voice", count: 28 },
    { id: "fountek", name: "Fountek", count: 3 },
    { id: "jtc", name: "JTS", count: 20 },
    { id: "koss", name: "Koss", count: 27 },
  ],
};

export const dummyColors = {
  filterName: "Colors",
  filterList: [
    { id: "colors1", name: "Black", count: 412 },
    { id: "colors2", name: "Black/Red", count: 6 },
    { id: "colors3", name: "Blue", count: 85 },
    { id: "colors4", name: "Brown", count: 17 },
    { id: "colors5", name: "Clear", count: 6 },
    { id: "colors6", name: "Cream", count: 1 },
    { id: "colors7", name: "Gold", count: 11 },
    { id: "colors8", name: "Green", count: 49 },
    { id: "colors9", name: "GREY", count: 61 },
    { id: "colors10", name: "Orange", count: 18 },
    { id: "colors11", name: "Pink", count: 1 },
    { id: "colors12", name: "Purple", count: 1 },
    { id: "colors13", name: "Red", count: 141 },
  ],
};

export const dummyType = {
  filterName: "Type",
  filterList: [
    { id: "manufacture1", name: "3 PIN", count: 13 },
    { id: "manufacture2", name: "Black", count: 2 },
    { id: "manufacture3", name: "Bulk", count: 50 },
    { id: "manufacture4", name: "Cut To Length", count: 1 },
    { id: "manufacture5", name: "Double", count: 7 },
    { id: "manufacture6", name: "Female", count: 24 },
    { id: "manufacture7", name: "Flange", count: 2 },
    { id: "manufacture8", name: "HDMI", count: 2 },
    { id: "manufacture9", name: "Male", count: 24 },
    { id: "manufacture10", name: "Male R/A", count: 3 },
    { id: "manufacture11", name: "Original", count: 1 },
    { id: "manufacture12", name: "Reel", count: 1 },
    { id: "manufacture13", name: "RG58 name", count: 1 },
  ],
};

export function debounce_new(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}
