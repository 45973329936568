import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { BreadCrumb } from "../../Layout/Common/breadCrumb";
import { CategoryFilter } from "./categoryfilter";
import { CategoryDetails } from "./categorydetails";
import { PopuplarProducts } from "./popuplarproducts";
import { useParams } from "react-router-dom";
import { base64Decode } from "../../Layout/Common/common";
import { axiosInstance } from "../../axios/axiosInstance";
import { GlobalContext } from "../../Context/GlobalContext";
import { SortbyMobile } from "../../Layout/Common/SortbyMob";
import { FilterPanelMobile } from "../../Layout/Common/FilterPanelMob";
import { ModelFilterMobile } from "../../Layout/Common/modelFilterMobile";
import { DeviceFilterMobile } from "../../Layout/Common/deviceFilterMobile";
export const CategoryPage = () => {
  const mountedRef = useRef();
  const { pcid, cid, eapath, filter } = useParams();
  const globalcontext = useContext(GlobalContext);
  const [CategoryContent, setCategoryContent] = useState(null);
  const [Breadcrumbdata, setBreadcrumbdata] = useState(null);
  var data = {
    categoryId: cid,
    parentId: pcid,
    userId: String(globalcontext.UserId),
    eapath: base64Decode(eapath),
    filter: base64Decode(filter),
  };
  const InitLoad = () => {
    axiosInstance
      .post("CategoryPage/GetCategoryContent", data)
      .then((response) => {
        if (response.data != "") {
          console.log(response.data);
          setCategoryContent(response.data);
        }
      })
      .catch((error) => console.log(error.message));
  };
  const GetBreadCrumb = () => {
    axiosInstance
      .post("CategoryPage/GetBreadcrumb", data)
      .then((response) => {
        if (response.data != "") {
          setBreadcrumbdata(response.data);
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    InitLoad();
    GetBreadCrumb();

    let UnbxdAnalyticsConf = window.UnbxdAnalyticsConf || {};
    UnbxdAnalyticsConf[
      "page"
    ] = `{{categoryPath used for category api call (value of "p" parameter).}`;
    UnbxdAnalyticsConf["page_type"] = "BOOLEAN";
  }, [cid, filter, eapath]);

  return (
    <Fragment>
      <BreadCrumb data={Breadcrumbdata} />
      <CategoryDetails content={CategoryContent} />
      <div id="UNX-pdp-recs-1">-</div>
      <PopuplarProducts />
      <CategoryFilter />
      <SortbyMobile />
      <FilterPanelMobile />
      <ModelFilterMobile />
      <DeviceFilterMobile />
    </Fragment>
  );
};
