import { Fragment, useEffect } from "react";

export const PriceTablePopup = ({ prodData, data, action }) => {
  const addtocart = (q) => {
    action(String(q).replace("+", ""), "INC");
  };
  return (
    <div
      className="modal fade"
      id={prodData.productId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered orderStatus-dialog">
        <div className="modal-content">
          <p className="order-status-close" data-bs-dismiss="modal">
            <i className="fa-regular fa-circle-xmark" />
          </p>
          <div className="modal-body p-0">
            {/* order status */}
            <div className="order-status">
              <div className="order-status-list">
                <div className="order-title">
                  <h6>Order Code</h6>
                </div>
                <div className="order-dec">
                  <p>{prodData.productCode}</p>
                </div>
              </div>
              <div className="order-status-list">
                <div className="order-title">
                  <h6>Stock Status</h6>
                </div>
                <div className="order-dec pre-order-status">
                  {prodData.stockStatus.stockStatus_Raw}
                </div>
                {/* <div className="order-dec pre-order-status">
                  <span>Pre-Order:</span>
                  <p>BPA-38SN</p>
                </div> */}
              </div>
              <div className="order-status-list">
                <div className="order-title cost-qty">
                  <h6>Cost EX</h6>
                  <p>QTY</p>
                </div>
                <div className="order-dec order-price-dec">
                  {data &&
                    data.length > 0 &&
                    data.map((row, index) => (
                      <div className="order-price" key={index}>
                        <span>${row.price2}</span>
                        <p>
                          <a href="#." onClick={() => addtocart(row.qty)}>
                            {row.qty}
                          </a>
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* order status */}
          </div>
        </div>
      </div>
    </div>
  );
};
