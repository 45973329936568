import { 
  product2,product3,product4,product5,product6,
  product18,
  product19,
  product20,
  product21 
 } from "../../assets/imageImport";


export const PopuplarProducts = () => {
  return (
    <section className="filter-popular-section">
      <div className="container-fluid container-lg">
        <div className="row">
          <div className="col-lg-12">
            <div className="subcategory-title">
              <h4>Popluar Products</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="category-products owl-carousel">
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product2}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">
                        Transcend 512GB M.2 2280 Internal (SSD) Solid State
                        Drive
                      </a>{" "}
                    </h6>
                    <div className="product-price">
                      <p>$2100.00</p>
                    </div>
                    <div className="product-code buying-options">
                      Code: CTT524
                    </div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-buying"
                      >
                        <span className="wes-buying-option" />{" "}
                        <span className="btn-options">Buying Options</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product3}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                      <small className="product-stage new-sale">Sale</small>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">
                        Transcend 512GB M.2 2280 Internal (SSD) Solid State
                        Drive
                      </a>{" "}
                    </h6>
                    <div className="product-price">
                      <p>$2100.00</p>
                    </div>
                    <div className="product-code buying-options">
                      Code: CTT524
                    </div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-buying"
                      >
                        <span className="wes-buying-option" />{" "}
                        <span className="btn-options">Buying Options</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product5}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">
                        Transcend 512GB M.2 2280 Internal (SSD) Solid State
                        Drive
                      </a>{" "}
                    </h6>
                    <div className="product-price">
                      <p>$2100.00</p>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#orderStatus"
                      >
                        <span className="wes-dollar" />
                      </span>
                    </div>
                    <div className="product-code">Code: CTT524</div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-addcart"
                      >
                        <span className="wes-cart" /> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product4}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">
                        Transcend 512GB M.2 2280 Internal (SSD) Solid State
                        Drive
                      </a>
                    </h6>
                    <div className="product-price">
                      <p>$2100.00</p>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#orderStatus"
                      >
                        <span className="wes-dollar" />
                      </span>
                    </div>
                    <div className="product-code">Code: CTT524</div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-addcart addToCart"
                        id="addToCart"
                      >
                        <span className="wes-cart" /> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product6}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">
                        Transcend 512GB M.2 2280 Internal (SSD) Solid State
                        Drive
                      </a>
                    </h6>
                    <div className="product-price">
                      <p>$2100.00</p>
                    </div>
                    <div className="product-code">Code: CTT524</div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-buying"
                      >
                        <span className="wes-buying-option" />{" "}
                        <span className="btn-options">Buying Options</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product18}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">
                        Transcend 512GB M.2 2280 Internal (SSD) Solid State
                        Drive
                      </a>
                    </h6>
                    <div className="product-price">
                      <p>$2100.00</p>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#orderStatus"
                      >
                        <span className="wes-dollar" />
                      </span>
                    </div>
                    <div className="product-code">Code: CTT524</div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-addcart addToCart"
                        id="addToCart"
                      >
                        <span className="wes-cart" /> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product19}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">Cable HDMI V2.1 High Speed Male-Male 3M</a>
                    </h6>
                    <div className="product-price">
                      <p>$780.00</p>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#orderStatus"
                      >
                        <span className="wes-dollar" />
                      </span>
                    </div>
                    <div className="product-code">Code: CTT524</div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-addcart addToCart"
                        id="addToCart"
                      >
                        <span className="wes-cart" /> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product20}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">Hdmi Splitters 1080p - Pro2</a>
                    </h6>
                    <div className="product-price">
                      <p>$190.00</p>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#orderStatus"
                      >
                        <span className="wes-dollar" />
                      </span>
                    </div>
                    <div className="product-code">Code: CTT524</div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-addcart addToCart"
                        id="addToCart"
                      >
                        <span className="wes-cart" /> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
              <div className="item">
                {/* Product */}
                <div className="product-list">
                  <div className="product-items">
                    <a href="#">
                      <img
                        src={product21}
                        alt="product-name"
                      />
                    </a>
                    <div className="wishlist-products">
                      <p>
                        <span className="wes-whishlist1" />
                      </p>
                    </div>
                  </div>
                  <div className="product-details">
                    <h6 className="product-title">
                      <a href="#">Hdspider™ Hdmi Over Cat5e/6 Transmitter</a>
                    </h6>
                    <div className="product-price">
                      <p>$650.00</p>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#orderStatus"
                      >
                        <span className="wes-dollar" />
                      </span>
                    </div>
                    <div className="product-code">Code: CTT524</div>
                    <div className="product-button button-effect">
                      <button
                        type="submit"
                        className="btn btn-products btn-addcart addToCart"
                        id="addToCart"
                      >
                        <span className="wes-cart" /> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
                {/* Product */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
