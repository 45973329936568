import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosInstance from "../../axios/axiosInterceptor"; 
import { CategorySliderMobile } from "./mobileView/categorySliderMobile";

export const CategoryPanel = () => {
  const [bannerList, setBannerList] = useState(null);
  const [mobileBannerFirst, setMobileBannerFirst] = useState(null);
  const [mobileBannerSecond, setMobileBannerSecond] = useState(null);
  const { loading, error, get, post } = useAxiosInstance();
 
  useEffect(() => {
    get(
      "https://cdn.wes.com.au/wesreact/newui/banner/banner.txt",
      (res) => {
        console.log("test", res);
        var responsedata = res.data;
        if (responsedata && responsedata.Banner.length > 0) {
          setBannerList(responsedata.Banner);
        }
        if (responsedata && responsedata.MobileBannerFirst.length > 0) {
          console.log(responsedata.MobileBannerFirst);
          setMobileBannerFirst(responsedata.MobileBannerFirst);
        }
        if (responsedata && responsedata.MobileBannerSecond.length > 0) {
          setMobileBannerSecond(responsedata.MobileBannerSecond);
        }
      },
      {}
    );
  }, []);

  return (
    <section className="banner-section">
      <div className="container-fluid container-lg">
        <div className="row row-gap-4">
          {bannerList &&
            bannerList.map((row, index) => (
              <div className={`${row.class} d-none d-sm-block`}>
                <div className="banner-image">
                  <Link to={row.URL}>
                    <img
                      src={row.IMG_PATH}
                      className="img-fluid"
                      alt="banner-image"
                    />
                  </Link>
                </div>
              </div>
            ))}
        </div>
        {/* Mobile Banner */}
        <CategorySliderMobile
          sliderFirst={mobileBannerFirst}
          sliderSecond={mobileBannerSecond}
        />
        {/* Mobile Banner */}
      </div>
    </section>
  );
};
