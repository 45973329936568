export const IS_VARIANTS_ENABLED =
    typeof window.UNBXD_VARIANTS_CONFIG === 'object'
        ? window.UNBXD_VARIANTS_CONFIG.enabled
        : false;

export const SEARCH_END_POINT = window.UNBXD_SEARCH_URL;
//alert(SEARCH_END_POINT);

export const VARIANT_LIMIT = 10;

export const SWATCH_MAP = {
    swatchImageUrl: 'vSwatch',
    color: 'color',
    size: 'size',
};

export const LEFT_QUICK_MENU = [
    {
        id: 'quick-order',
        label: 'Quick Order',
        link:"#"
    }, {
        id: 'shop-brand',
        label: 'Shop by Brands',
        link:"#"
    }, {
        id: 'new-product',
        label: 'New Products',
        link:"#"
    }, {
        id: 'clearance',
        label: 'Clearance Products',
        link:"#"
    },{
        id: 'catalogue',
        label: 'Catalogue eBook',
        link:"#"
    }
     
];

export const RIGHT_QUICK_MENU = [
    {
        id: 'help',
        label: 'Help',
        link:"#"
    }, {
        id: 'shop-brand',
        label: 'Shop by Brands',
        link:"#"
    } 
];
 

export const SIDE_MAINMENU =[
    {id:'quickorder',name:'Quick Order',class:'wes-quick-order',href:'#'},
    {id:'shopbybrands',name:'Shop by Brands',class:'wes-shop-brand',href:'#'},
    {id:'newproducts',name:'New Products',class:'wes-new-product',href:'#'},
    {id:'clearanceproducts',name:'Clearance Products',class:'wes-clearance',href:'#'},
    {id:'catalogueebook',name:'Catalogue eBook',class:'wes-catalogue',href:'#'}
]
export const SIDE_CONTACTMENU =[

    {id:'help',name:'Help',class:'fa-regular fa-message',href:'#'},
    {id:'contactus',name:'Contact Us',class:'wes-call',href:'#'} 
]