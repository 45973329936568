import { Fragment, useContext, useEffect } from "react";
import { axiosInstance } from "../../axios/axiosInstance";
import { GlobalContext } from "../../Context/GlobalContext";
import { CategoryPanel } from "./CategoryPanel";
import { Link } from "react-router-dom";

export const ShopAllProducts = () => {
  const globalcontext = useContext(GlobalContext);
  useEffect(() => {
    axiosInstance
      .get("GetStaticData/GetCategoryList")
      .then((response) => {
        globalcontext.setCategoryMenu(response.data);
        window.categoryHoverFun();
        window.categoryClickFun();
      })
      .catch((error) => {
        return error.message;
      });
  }, []);

  return (
    <Fragment>
      <div
        className="modal category-modal fade"
        data-backdrop="true"
        data-keyboard="false"
        id="shop-allProducts"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog allproducts-dialog modal-dialog-slideout">
          <div className="modal-content allproducts-content">
            <div className="modal-body allproducts-body">
              {/*  Category menus */}
              <div className="allproducts-header">
                <a
                  className="allproducts-close d-none d-lg-block"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa-solid fa-xmark d-none d-lg-block" />
                  <p className="d-block d-lg-none">
                    <i className="fa-solid fa-house" /> Back to Home
                  </p>
                </a>
                <div className="all-products">
                  <div className="shop-department d-block d-lg-none mt-0">
                    <p>Quick Links</p>
                  </div>
                  <ul className="allProducts-slider">
                    <li>
                      <a href="#." className="active">
                        Browse Category
                      </a>
                    </li>
                    <li>
                      <a href="#.">New Products</a>
                    </li>
                    <li>
                      <a href="#.">Clearance Products</a>
                    </li>
                    <li>
                      <a href="brands-list.html">Browse by Brands</a>
                    </li>
                    <li>
                      <a href="#.">Catalogue eBooks</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="shop-department d-block d-lg-none">
                <p>Shop by Category</p>
              </div>
              <div className="all-prodcuts-menus">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="category-tab"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="categ-menus">
                      <ul className="nav nav-tabs" id="myTabs">
                        {globalcontext.CategoryMenu &&
                          globalcontext.CategoryMenu.map((row, index) => (
                            <li className="nav-item" key={row.categoryId}>
                              <a
                                className={
                                  "category-link " + (index === 0 && "active")
                                }
                                data-bs-toggle="tab"
                                href={"#" + row.categoryId}
                              >
                                <p>
                                  <img src={row.categoryImage} alt="av-tv" />
                                  {row.categoryName}
                                </p>
                                <i className="fa-solid fa-chevron-right" />
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    {/* tab content */}
                    <div className="tab-content categ-menus-content">
                      {globalcontext.CategoryMenu &&
                        globalcontext.CategoryMenu.map((row, index) => (
                          <CategoryPanel data={row} index={index} />
                        ))}
                    </div>
                    {/* tab content */}
                  </div>
                </div>
              </div>
              {/*  Category menus */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
