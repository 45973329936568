export const QuickMenu =()=>{
return (
    <section className="quick-links-sections d-none d-lg-block">
    <div className="container-fluid container-lg">
        <div className="row">
            <div className="col-lg-12">
                <div className="quick-link-list d-flex align-items-center justify-content-between">
                    <div className="quick-links">
                        <ul className="m-0 p-0 quick-menu quick-menu-effect">
                            <li><a href="#"><span className="wes-quick-order" /> Quick Order</a></li>
                            <li><a href="#"><span className="wes-shop-brand" /> Shop by Brands</a></li>
                            <li><a href="#"><span className="wes-new-product" /> New Products</a></li>
                            <li><a href="#"><span className="wes-clearance" /> Clearance Products</a></li>
                            <li><a href="#"><span className="wes-catalogue" /> Catalogue eBook</a></li>
                        </ul>
                    </div>
                    <div className="quick-links">
                        <ul className="m-0 p-0 quick-menu quick-menu-effect">
                            <li><a href="#"><i className="fa-regular fa-message" /> Help</a></li>
                            <li><a href="#"><span className="wes-call" /> Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)

}