import { Fragment } from "react";

export const FilterDropDown = ({ data, onChange }) => {
  return (
    <Fragment>
      {data && (
        <div className="ct-filters-list">
          <div className={`ct-filter  ${data.filterName.toLowerCase()}-filter`}>
            <div className="ct-filter-top">
              <p>{data.filterName}</p>
            </div>
            <i className="fa-solid fa-angle-down" />
            <p />
          </div>
          {/* Filter Selection */}
          <div className={`ct-${data.filterName.toLowerCase()}-filter`}>
            <div className="categ-search position-relative">
              <input
                className="form-control me-2"
                type="text"
                placeholder="Search for.."
                aria-label="Search"
              />
              <i className="fa-solid fa-magnifying-glass" />
            </div>
            <div className="categ-list">
              {data.filterList.map((filter, index) => (
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={filter.id}
                  />
                  <label className="form-check-label" htmlFor="aerpro">
                    {filter.name}({filter.count})
                  </label>
                </div>
              ))}
            </div>
            <div className="reset-filter">
              <a href="#.">Reset Filter</a>
            </div>
          </div>
          {/* Filter Selection */}
        </div>
      )}
    </Fragment>
  );
};
