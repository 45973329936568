import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

export const BrandBanner = ({ data }) => {
  return (
    <Fragment>
      {data && (
        <div className="col-lg-6">
          <div className="list-category">
            <h4>{data.title}</h4>
            <div className="row">
              {data.brand &&
                data.brand.length > 0 &&
                data.brand.map((row, index) => (
                  <div className="col-4 col-lg-4 mt-3">
                    <Link to={row.url}>
                      <img src={row.image} alt="brand image" />
                    </Link>
                  </div>
                ))}
            </div>
          </div>
          {data.shopall && (
            <div className="ca-category-title">
              <h5>{data.shopall.title}</h5>
              <div className="product-button button-effect">
                <Link to={data.shopall.url} className="shop-link">
                  {data.shopall.caption}
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
