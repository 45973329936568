import React, { createContext, useState, useContext, useEffect } from "react";
import { axiosInstance } from "../axios/axiosInstance";
import { SuspenseLoader } from "../Layout/Common/loader";
import { GlobalContext } from "./GlobalContext";

// Create Auth Context
const AuthContext = createContext(null);

// Provide Auth Context
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const authenticate = async () => {
      setLoading(true);
      try {
        const data = {
          LoginName: "",
          token: "",
        };

        const response = await axiosInstance.post("Authentication", data);

        if (response.data && response.data.statusCode === 200) {
          const responseData = response.data.data;
          console.log(responseData);
          setUser(responseData);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Authentication error:", error.message);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    authenticate();
  }, []);

  const logout = () => {
    localStorage.removeItem("authToken");
    setUser(null);
  };

  if (loading) {
    return <SuspenseLoader />;
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        userId: user?.userId,
        userName: user?.userName,
        loginName: user?.loginName,
        role: user?.userRole,
        logout,
        isAuthenticated: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
