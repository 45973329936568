import { Fragment, useContext, useEffect, useState } from "react";
import { BreadCrumb } from "../../Layout/Common/breadCrumb";
import { GlobalContext } from "../../Context/GlobalContext";
import { OrderDetailItemBody } from "./OrderDetailItemBody";
import { useOrderClarificationModal } from "../../Context/OrderClarificationPopUpContext";
import { axiosInstance } from "../../axios/axiosInstance";
import { OrderDetailClarification } from "./OrderClarification";

export const OrderDetails = () => {
  const [CartItem, setCartItem] = useState(null);
  const globalcontext = useContext(GlobalContext);

  const { isOrderClarification, showOrderClarification } =
    useOrderClarificationModal();
  useEffect(() => {
    if (globalcontext.CartItem) setCartItem(globalcontext.CartItem);
  }, [globalcontext.CartItem]);

  const handleCheckOutClick = () => {
    if (isOrderClarification && !localStorage.getItem("isShown")) {
      //showOrderClarification();
      localStorage.setItem("isShownOrderClarification", "true");
    }
  };

  return (
    <Fragment>
      {/* Breadcrumb Section */}
      <BreadCrumb data={[{ name: "My Cart", url: "" }]} />
      {/* Breadcrumb Section */}
      {/* Cart Section */}
      <section className="cart-section">
        <div className="container-fluid container-lg">
          {/* Cart Title */}
          <div className="row">
            <div className="col-lg-12">
              <div className="wesproduct-title cart-title">
                <h1>My Cart</h1>
                <a href="../category-list.html" className="d-none d-lg-block">
                  <i className="fa-solid fa-chevron-left" /> Return to Product
                  List
                </a>
              </div>
            </div>
          </div>
          {CartItem && (
            <div className="row cart-products">
              <div className="col-md-8 col-lg-8">
                {/* Products */}
                <OrderDetailClarification />
                <OrderDetailItemBody cartItemList={CartItem.cartItem} />
                {/* Products */}
              </div>
              <div className="col-md-4 col-lg-4 mt-0 mt-lg-0">
                <div className="order-summary-details">
                  <div className="order-prices">
                    <div className="order-prices-list">
                      <p>Sub Total</p>
                      <span>$ {CartItem ? CartItem.totalcost : 0.0}</span>
                    </div>
                    <div className="order-prices-list">
                      <p>Tax Amount</p>
                      <span>$ {CartItem ? CartItem.totaltax : 0.0}</span>
                    </div>
                    <div
                      className="order-prices-list"
                      style={{ display: "none" }}
                    >
                      <p>Freight</p>
                      <span>TBA</span>
                    </div>
                  </div>
                  {/* Order Total */}
                  <div className="order-prices-list order-prices-total">
                    <p>Total</p>
                    <span>$ {CartItem ? CartItem.totalIncTax : 0.0}</span>
                  </div>
                  {/* Order Total */}
                </div>
                {/* Btn */}
                <div className="subtotal-btn cartpage-btn">
                  <a
                    href="../index.html"
                    className="btn btn-products btn-unavailable"
                  >
                    Continue Shopping
                  </a>
                  <div className="button-effect popup-checkout-btn">
                    <a
                      href="../checkout/checkout-delivery.html"
                      className="btn btn-products btn-addcart green-btn"
                    >
                      Checkout Now
                    </a>
                  </div>
                </div>
                {/* Btn */}
              </div>
            </div>
          )}
        </div>
      </section>
      {/* Cart Section */}
      {/* Subtotal Section */}
      <section className="subtotal-section d-none d-lg-block">
        <div className="container">
          {/* Cart Title */}
          <div className="row">
            <div className="col-lg-12">
              <div className="cart-subtotal">
                <p>Sub Total - $ {CartItem ? CartItem.totalcost : 0.0}</p>
                <div className="button-effect popup-checkout-btn">
                  <a
                    onClick={handleCheckOutClick}
                    className="btn btn-products btn-addcart green-btn"
                  >
                    Checkout Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Subtotal Section */}
    </Fragment>
  );
};
