import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: window.location.origin + "/api/",
});
export const axiosWithoutAuth = axios.create({
  baseURL: window.location.origin + "/api/",
});

axiosInstance.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      sessionStorage.setItem("IsSessionTimeout", false);
    } else if (res.status === 305) {
      console.log("Till Redirect 305");
    }

    if (res.data.status === "Failure") {
      sessionStorage.setItem("pUrl", window.location.pathname);

      if (String(window.location.pathname).includes("/tilpaymentpayment")) {
        sessionStorage.setItem("IsSessionTimeout", false);
        return res;
      } else {
        setSessionTimeout();
        return false;
      }
    } else {
      return res;
    }
  },
  (err) => {
    if (err.response.status === 401) {
      setSessionTimeout();
      if (err.response.data.title === "Unauthorized") {
        setSessionTimeout();
      }
    }
    if (err.response.status === 302) {
      setSessionTimeout();
    }
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.request.use(
  (req) => {
    req.headers["Authorization"] = getToken();
    req.headers["Access-Control-Allow-Origin"] = "*";
    req.headers["Accept"] = "*";
    return req;
  },
  (err) => {
    console.error("The API INTERCEPTOR FOR GET API ERROR ", err);
    setSessionTimeout();
    if (err.response.status === 401) {
    }
    return Promise.reject(err);
  }
);

export default function setToken() {
  const jwtToken = sessionStorage.getItem("Token");
  if (jwtToken) {
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function setSessionTimeout() {
  sessionStorage.setItem("IsSessionTimeout", true);
  return false;
}

function getToken() {
  const jwtToken = sessionStorage.getItem("Token");
  if (jwtToken) {
    return `Bearer ${jwtToken}`;
  } else {
    return `Bearer `;
  }
}
//}

//function getToken() {
//  const jwtToken = sessionStorage.getItem("Token");
//  if (jwtToken) {
//    return `Bearer ${jwtToken}`;
//  } else {
//    return `Bearer `;
//  }
//}
