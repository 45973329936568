import $ from "jquery";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PriceTablePopup } from "./PriceTablePopup";
import { axiosInstance } from "../../axios/axiosInstance";
import { Toast } from "bootstrap";
import { GlobalContext } from "../../Context/GlobalContext";
import { debounce_new } from "../../assets/constValue";
import { WishlistItem } from "./addWhistList";
import { isNumber } from "lodash";

export const ProductCard = ({ data, index }) => {
  const globalcontext = useContext(GlobalContext);
  const [showPopup, setShowPopup] = useState("hide");
  const [PriceTabelData, setPriceTabelData] = useState(null);
  const [ProductData, setProductData] = useState(data);
  const [isAdded, setIsadded] = useState(data.isAdded);
  const [qty, setQty] = useState(1);

  useEffect(() => {
    setProductData(data);
  }, [data]);

  useEffect(() => {
    if (
      globalcontext.CartItem &&
      globalcontext.CartItem.cartItem &&
      globalcontext.CartItem.cartItem.length > 0
    ) {
      var products = globalcontext.CartItem.cartItem;
      setIsadded(
        products.some(
          (product) => String(product.prod_id) === String(ProductData.productId)
        )
      );
    }
  }, [globalcontext.CartItem]);

  const AddtoCart = (q, t) => {
    var params = {
      userid: String(globalcontext.UserId),
      productId: ProductData.productId,
      qty: parseInt(q),
      key: t,
    };

    globalcontext.setCardToast({ ...data, qty: q, show: false, type: t });
    globalcontext.setCartItem(null);
    axiosInstance
      .post("AddtoCart/AddtoCart", params)
      .then((response) => {
        if (response.data != -1) {
          globalcontext.setCartItem(response.data);
          globalcontext.setCardToast({ ...data, qty: q, show: true, type: t });
        } else {
          alert("Something Wrong !");
        }
      })
      .catch((error) => console.log(error.message));
  };
  const handleAddtocartClick = (event) => {
    AddtoCart(1, "ADD");
    setIsadded(true);
    const button = $(event.currentTarget);
    window.addCartEffect(button);
  };
  const handleMouseEnter = useEffect(() => {
    var d = {
      type: "PriceTable",
      userid: "70677",
      prodid: data.productId,
      productCode: data.productCode,
    };
    axiosInstance
      .post("GetPriceTable/MouseHover", d)
      .then((response) => {
        if (response.data != "") {
          setPriceTabelData(response.data);
        }
      })
      .catch((error) => console.log(error.message));
  }, []);

  const DebounceOnChange = useCallback(
    debounce_new(handleAddtocartClick, 500),
    []
  );

  const QuantityInc = () => {
    setQty(parseInt(qty + 1));
    AddtoCart(parseInt(qty + 1), "INC");
  };
  const QuantityDec = () => {
    setQty(parseInt(qty - 1));
    AddtoCart(parseInt(qty - 1), "DEC");
  };

  const handleOnChange = (e) => {
    var _val = e.target.value;
    if (isNumber(_val)) {
      setQty(parseInt(_val));
      AddtoCart(parseInt(_val), "INC");
    }
  };

  return (
    <Fragment>
      {PriceTabelData && (
        <PriceTablePopup
          prodData={ProductData}
          data={PriceTabelData}
          action={AddtoCart}
        />
      )}
      <div className="product-list" key={ProductData.productId}>
        <div className="product-items">
          <Link to={ProductData.link}>
            <img src={data.prodImage} alt="product-name" />
          </Link>
          <div className="wishlist-products">
            <WishlistItem productId={ProductData.productId} />

            {ProductData.isClearance ? (
              <small className="product-stage new-clearance">CLEARANCE</small>
            ) : (
              ProductData.isNew && (
                <small className="product-stage new-product">New</small>
              )
            )}
          </div>
        </div>
        <div className="product-details">
          <h6 className="product-title">
            <Link to={ProductData.link}>{ProductData.description}</Link>
          </h6>
          {data.isUnavailable ? (
            <div className="product-unavailable">
              <p>Temporarily Unavailable</p>
            </div>
          ) : isAdded ? (
            <div className="product-price">
              <p>${ProductData.price}</p>
              <span className="pro-added">
                <i className="fa-solid fa-check" /> Added
              </span>
            </div>
          ) : data.showBulkBuy ? (
            <div className="product-price">
              <p>${ProductData.price}</p>
              <span
                className="orderstatus"
                data-bs-toggle="modal"
                data-bs-target={`#${data.productId}`}
                onMouseEnter={handleMouseEnter}
              >
                <span className="wes-dollar" />
              </span>
            </div>
          ) : (
            <div className="product-price">
              <p>${ProductData.price}</p>
            </div>
          )}
          <div className="product-code">Code: {ProductData.productCode}</div>
          {isAdded ? (
            <div className="product-quantity">
              <div className="qty-container">
                <button
                  className="qty-btn-minus btn-light"
                  type="button"
                  onClick={QuantityDec}
                >
                  <i className="fa fa-minus" />
                </button>
                <input
                  type="text"
                  name="qty"
                  value={qty}
                  className="input-qty"
                  onChange={handleOnChange}
                />
                <button
                  className="qty-btn-plus btn-light"
                  type="button"
                  onClick={QuantityInc}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          ) : data.isBuyOption ? (
            <div className="product-button button-effect">
              <button type="submit" className="btn btn-products btn-buying">
                <span className="wes-buying-option" />{" "}
                <span className="btn-options">Buying Options</span>
              </button>
            </div>
          ) : data.isUnavailable ? (
            <div className="product-button">
              <button
                type="submit"
                className="btn btn-products btn-unavailable"
              >
                Unavailable
              </button>
            </div>
          ) : (
            <div className="product-button button-effect">
              <button
                onClick={handleAddtocartClick}
                type="submit"
                className="btn btn-products btn-addcart addToCart"
                id="addToCart"
              >
                <span className="wes-cart" /> Add to Cart
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
