import { Link } from "react-router-dom";
import { SIDE_CONTACTMENU, SIDE_MAINMENU } from "../../constants";

export const SideBar = () => {
  return (
    <div className="sidebar-content">
      <div className="sidebar-menu">
        <h4>Main Menu</h4>
        <div className="quick-link-list d-flex align-items-center justify-content-between">
          <div className="quick-links">
            <ul className="m-0 p-0 quick-menu quick-menu-effect">
              {SIDE_MAINMENU.map((row, index) => (
                <li key={index}>
                  <Link to={row.href}>
                    <span className={row.class} /> {row.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="quick-links">
            <ul className="m-0 p-0 quick-menu quick-menu-effect">
              {SIDE_CONTACTMENU.map((row, index) => (
                <li key={index}>
                  <Link to={row.href}>
                    <span className={row.class} /> {row.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <h4 className="mt-5">Profile</h4>
        <ul className="navbar-nav mr-auto sidebar-nav">
          <li className="dropdown_item inner-dropdown icon header-icon">
            <a href="#">Account Settings</a>
          </li>
          <li className="dropdown_item inner-dropdown icon header-icon">
            <a href="#">Wish List</a>
          </li>
          <li className="dropdown_item inner-dropdown icon header-icon">
            <a href="#">Signout</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
