import { useEffect, useState } from "react";
import useAxiosInstance from "../../axios/axiosInterceptor";
import { Link } from "react-router-dom";
import { OfferBannerMobileView } from "./mobileView/offerBannerMobileView";
import ReactOwlCarousel from "react-owl-carousel";

export const OfferSection = () => {
  const { loading, error, get, post } = useAxiosInstance();
  const [offerData, setOfferData] = useState(null);
  var options = {
    items: 4,
    loop: true,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    margin: 10,
    responsive: {
        0: {
          items: 2,  
        },
        768: {
          items: 4, 
          margin: 20,
          autoplay: false
        },
        992: {
          items: 4,
          margin:  20,
          autoplay: false
        },
    }
  };
  useEffect(() => {
    get(
      "https://cdn.wes.com.au/wesreact/newui/banner/offerBanner.txt",
      (response) => {
        var responsedata = response.data;
        if (responsedata && responsedata.Offer) {
          setOfferData(responsedata.Offer);
          console.log("response", responsedata.Offer);
        }
      }
    );
  }, []);
  return (
    <section className="offers-section">
      <div className="container-fluid container-lg">
        {offerData && (
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="offers-slider">
                  <ReactOwlCarousel className="owl-theme" {...options}>
                    {offerData &&
                      offerData.length > 0 &&
                      offerData.map((row, index) => (
                        <div key={index} className="item banner-image">
                          <Link to={row.url}>
                            <img src={row.image} alt="banner-image" />
                          </Link>
                        </div>
                      ))}
                  </ReactOwlCarousel>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
