import { useEffect, useState } from "react";
import { ProductCard } from "../../Layout/Common/productCard";
import useAxiosInstance from "../../axios/axiosInterceptor";
import Masonry from "react-responsive-masonry";

export const ProductList = ({ type }) => {
  const { loading, error, get, post } = useAxiosInstance();
  const [ProductListJson, setProductListJson] = useState();
  useEffect(() => {
    var data = {
      type: type,
      userid: "0",
      count: 15,
    };
    post("GetIndexProduct", data, (response) => {
      if (response.data != "") {
        let result = response.data;
        if (Array.isArray(result) && result.length > 0) {
          setProductListJson(result);
          if (type == "POPPRODUCTS") {
            sessionStorage.setItem("POPPRODUCTS", JSON.stringify(result));
          }
        }
      }
    });
  }, []);
  return (
    <div className="col-12 col-md-7 col-lg-6 order-2 order-lg-1">
      <div className="row">
        {ProductListJson &&
          ProductListJson.map((row, index) => {
            if (index === 0 || index === 1) {
              return (
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-sm-p  ">
                  <ProductCard data={row} />
                </div>
              );
            } else {
              return (
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-sm-p   mt-sm-4 mt-md-4">
                  <ProductCard data={row} />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};
