import { Link } from "react-router-dom";
import useAxiosInstance from "../../axios/axiosInterceptor";
import { useEffect, useState } from "react";
import { ProductList } from "./productList";
import Masonry from "react-responsive-masonry";

export const ProductPosterLeft = () => {
  const { loading, error, get, post } = useAxiosInstance();
  const [PosterData, setPosterData] = useState(null);
  useEffect(() => {
    get("https://cdn.wes.com.au/wesreact/newui/banner/newProductPoster.txt", (response) => {
      var responsedata = response.data;
      if (responsedata && responsedata.NewProductPoster) {
        setPosterData(responsedata.NewProductPoster);
      }
    });
  }, []);
  return (
    <section className="products-section">
      <div className="container-fluid container-lg">
        <div className="row"> 
          <div className="col-md-5 col-lg-6">
            {PosterData ? (
              <div className="new-product-title new-products">
                <div className="products-title">
                  <h1>{PosterData.title}</h1>
                  <p>{PosterData.content}</p>
                  <div className="button-effect">
                    <Link to={PosterData.link} className="shop-link">
                      {PosterData.buttonCaption}
                    </Link>
                  </div>
                </div>
                <img src={PosterData.image} alt="new-product" />
              </div>
            ) : (
              <div className="new-product-title new-products"></div>
            )}
          </div>
          <ProductList type={"NewProducts"} />  
        </div>
      </div>
    </section>
  );
};
