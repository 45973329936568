import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "./Context/AuthContext";

const ProtectedRoute = ({ Component }) => {
  const { isAuthenticated } = useAuth();
  const history = useNavigate();
  return isAuthenticated ? Component : () => <Navigate to="/Login" replace />;
};

export default ProtectedRoute;
