export const CategoryListView = () => {
  return (
    <div className="ct-filter-product">
      <div className="row">
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product1.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">
                      JBL Stadium GTO620 - 6.5" coaxial Car Audio Speaker
                    </a>
                  </h6>
                  <div className="product-features">
                    <p>
                      The JBL Audio CE30P-4 provides big-speaker features in an
                      extremely compact unit. It features a paper cone, rubber
                      roll surround, and high-energy double neodymium motor
                      structure, mounted to a robust injection-molded plastic
                      frame that’s perfect for press-fit or glued mounting.
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>Extremely compact size in a press-fit frame</li>
                      <li>Paper cone, rubber surround</li>
                      <li>High-output double neodymium magnet motor</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$2100.00</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="wishlist-products row-wishlist-products">
                  <p className="wishlist">
                    <span className="wesWishlist wes-whishlist1" />
                  </p>
                  <div className="product-button button-effect">
                    <button
                      type="submit"
                      className="btn btn-products btn-addcart addToCart"
                    >
                      <span className="wes-cart" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product13.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Ceiling Speakers Hifi Pairs Audio Volume</a>{" "}
                    <small className="product-stage new-sale">Sale</small>
                  </h6>
                  <div className="product-unavailable">
                    <p>Temporarily Unavailable</p>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="wishlist-products row-wishlist-products">
                  <div className="product-button">
                    <button
                      type="submit"
                      className="btn btn-products btn-unavailable"
                    >
                      {" "}
                      Unavailable
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product14.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Headphones With Volume Safety</a>
                  </h6>
                  <div className="product-unavailable">
                    <p>Substituted</p>
                  </div>
                  <div className="product-code product-substituted">
                    <p>
                      Replacement:<a href="#.">ABC10101010</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button">
                    <button
                      type="submit"
                      className="btn btn-products btn-unavailable"
                    >
                      Unavailable
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product15.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Amplifier Board Class-d Diy 2x 10w</a>
                  </h6>
                  <div className="product-features">
                    <p>
                      This board is designed around the PAM8610 integrated
                      circuit, a 10W per channel Class D digital audio power
                      amplifier. Class D amplification offers high power output
                      in a miniature size. 90% efficiency ensures a high quality
                      sound reproduction.&nbsp;
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>Suitable for compact DIY project integration.</li>
                      <li>2x 10W continuous power outputs @ 8 ohms.</li>
                      <li>Cool operation, no heat-sink required.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$2100.00</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: CTT524</div>
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button button-effect">
                    <button
                      type="submit"
                      className="btn btn-products btn-addcart addToCart"
                    >
                      <span className="wes-cart" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product16.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Usb-c To 3.5mm Audio Adaptor Lead</a>
                  </h6>
                  <div className="product-features">
                    <p>
                      This USB Type-C male to 3.5mm male audio cable allows
                      users to connect their devices to a speaker or or car
                      stereo system, etc. Premium cable covered with a weave
                      style nylon material to ensure durability. Lightweight and
                      easy to carry.&nbsp;
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>USB-C to 3.5mm Plug</li>
                      <li>1.0 metre in length</li>
                      <li>Nylon weave braid</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$74.50</p>
                  <span className="pro-added">
                    <span className="wes-added-cart" /> Added
                  </span>
                </div>
                <div className="product-code">Code: AL731</div>
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-quantity">
                    <div className="qty-container row-qty-container">
                      <button className="qty-btn-minus btn-light" type="button">
                        <i className="fa fa-minus" />
                      </button>
                      <input type="text" name="qty" className="input-qty" />
                      <button className="qty-btn-plus btn-light" type="button">
                        <i className="fa fa-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product17.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Lightning Plug Headphones</a>
                    <small className="product-stage new-clearance">
                      Clearance
                    </small>
                  </h6>
                  <div className="product-features">
                    <p>
                      This board is designed around the PAM8610 integrated
                      circuit, a 10W per channel Class D digital audio power
                      amplifier. Class D amplification offers high power output
                      in a miniature size. 90% efficiency ensures a high quality
                      sound reproduction.&nbsp;
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>
                        Wooden MDF enclosed, 4 inch subwoofer with front bass
                        reflex port
                      </li>
                      <li>THD + N (testing level) 10%</li>
                      <li>Input Type PC / AUX</li>
                      <li>Signal to noise ratio ≥85dBA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$16.50</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: KCF356</div>
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button button-effect">
                    <button
                      type="submit"
                      className="btn btn-products btn-addcart addToCart"
                    >
                      <span className="wes-cart" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product5.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Lightning Plug Headphones</a>
                    <small className="product-stage new-product">New</small>
                  </h6>
                  <div className="product-features">
                    <p>
                      This board is designed around the PAM8610 integrated
                      circuit, a 10W per channel Class D digital audio power
                      amplifier. Class D amplification offers high power output
                      in a miniature size. 90% efficiency ensures a high quality
                      sound reproduction.&nbsp;
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>
                        Wooden MDF enclosed, 4 inch subwoofer with front bass
                        reflex port
                      </li>
                      <li>THD + N (testing level) 10%</li>
                      <li>Input Type PC / AUX</li>
                      <li>Signal to noise ratio ≥85dBA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$16.50</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: KCF356</div>
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button button-effect">
                    <button
                      type="submit"
                      className="btn btn-products btn-addcart addToCart"
                    >
                      <span className="wes-cart" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product6.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Headphones With Volume Safety</a>
                  </h6>
                  <div className="product-unavailable">
                    <p>Discontinued</p>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button">
                    <button
                      type="submit"
                      className="btn btn-products btn-unavailable"
                    >
                      Discontinued
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product8.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Lightning Plug Headphones</a>
                    <small className="product-stage new-product">New</small>
                  </h6>
                  <div className="product-features">
                    <p>
                      This board is designed around the PAM8610 integrated
                      circuit, a 10W per channel Class D digital audio power
                      amplifier. Class D amplification offers high power output
                      in a miniature size. 90% efficiency ensures a high quality
                      sound reproduction.&nbsp;
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>
                        Wooden MDF enclosed, 4 inch subwoofer with front bass
                        reflex port
                      </li>
                      <li>THD + N (testing level) 10%</li>
                      <li>Input Type PC / AUX</li>
                      <li>Signal to noise ratio ≥85dBA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$16.50</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: KCF356</div>
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button button-effect">
                    <button
                      type="submit"
                      className="btn btn-products btn-addcart addToCart"
                    >
                      <span className="wes-cart" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product9.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Lightning Plug Headphones</a>
                    <small className="product-stage new-product">New</small>
                  </h6>
                  <div className="product-features">
                    <p>
                      This board is designed around the PAM8610 integrated
                      circuit, a 10W per channel Class D digital audio power
                      amplifier. Class D amplification offers high power output
                      in a miniature size. 90% efficiency ensures a high quality
                      sound reproduction.&nbsp;
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>
                        Wooden MDF enclosed, 4 inch subwoofer with front bass
                        reflex port
                      </li>
                      <li>THD + N (testing level) 10%</li>
                      <li>Input Type PC / AUX</li>
                      <li>Signal to noise ratio ≥85dBA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$16.50</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: KCF356</div>
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button button-effect">
                    <button
                      type="submit"
                      className="btn btn-products btn-addcart addToCart"
                    >
                      <span className="wes-cart" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
        {/* Product */}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-4">
          <div className="search-row position-relative">
            <div className="search-row-products">
              <div className="ct-row-products">
                <div className="row-pro-img">
                  <a href="#">
                    <img
                      src={require("../../assets/img/home-page/product-image/product10.png")}
                      alt="product image"
                    />
                  </a>
                </div>
                <div className="row-products-details">
                  <h6 className="product-title">
                    <a href="#">Lightning Plug Headphones</a>
                    <small className="product-stage new-sale">Sale</small>
                  </h6>
                  <div className="product-features">
                    <p>
                      This board is designed around the PAM8610 integrated
                      circuit, a 10W per channel Class D digital audio power
                      amplifier. Class D amplification offers high power output
                      in a miniature size. 90% efficiency ensures a high quality
                      sound reproduction.&nbsp;
                    </p>
                    <h6>Features</h6>
                    <ul>
                      <li>
                        Wooden MDF enclosed, 4 inch subwoofer with front bass
                        reflex port
                      </li>
                      <li>THD + N (testing level) 10%</li>
                      <li>Input Type PC / AUX</li>
                      <li>Signal to noise ratio ≥85dBA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ct-row-info position-relative">
                <div className="row-price">
                  <p>$16.50</p>
                  <span data-bs-toggle="modal" data-bs-target="#orderStatus">
                    <span className="wes-dollar" />
                  </span>
                </div>
                <div className="product-code">Code: KCF356</div>
                <div className="wishlist-products row-wishlist-products">
                  <p>
                    <span className="wes-whishlist1" />
                  </p>
                  <div className="product-button button-effect">
                    <button
                      type="submit"
                      className="btn btn-products btn-addcart addToCart"
                    >
                      <span className="wes-cart" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product */}
      </div>
      <div className="row">
        {/* Product Loading */}
        <div className="col-lg-12 mt-4">
          {/* Product */}
          <div className="product-list row-product-list">
            <div className="product-load-img">
              <div className="product-image-load" />
            </div>
            <div className="product-load-content">
              <div className="product-title-load" />
              <div className="product-title-load" />
              <div className="product-btn-load" />
            </div>
          </div>
          {/* Product */}
        </div>
        {/* Product Loading */}
      </div>
    </div>
  );
};
