import { Fragment, useContext, useEffect } from "react";
import { QuickMenu } from "./quickMenu";
import { CategoryPanel } from "./categoryPanel";
import { HowWorksSection } from "./HowWorksSection";
import { CategoryBrandSection } from "./categoryBrandSection";
import { ProductListSection } from "./productListSection";
import { OfferSection } from "./offerSection";
import { ProductPosterRight } from "./ProductPosterRight";
import { ProductPosterLeft } from "./productPosterLeft";
import { CatelogueBanner } from "./catelogueBanner";
import { GlobalContext } from "../../Context/GlobalContext";
import { PreviousOrderPopup } from "../../Layout/Common/PopUp/previousOrderPopUp";
import { axiosInstance } from "../../axios/axiosInstance";

export const ContentSection = () => {
  return (
    <Fragment>
      <QuickMenu />
      <CategoryPanel />
      <HowWorksSection />
      <ProductPosterLeft />
      <ProductPosterRight />
      <CategoryBrandSection />
      <div id="UNX-home-recs-1"></div>
      <ProductListSection type={"Feature"} />
      <ProductListSection type={"Clearance"} />
      <OfferSection />
      <CatelogueBanner />
      <PreviousOrderPopup />
    </Fragment>
  );
};
