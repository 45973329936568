import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import lodash from "lodash";
import { GlobalContext } from "../../Context/GlobalContext";
import { axiosInstance } from "../../axios/axiosInstance";
import { CartToastObject, isNumber } from "./common";

export const QuantityStripper = ({ prd }) => {
  const [product, setProduct] = useState(null);
  const [qty, setQty] = useState(0);
  const globalcontext = useContext(GlobalContext);
  useEffect(() => {
    if (prd) setProduct(prd);
    if (prd.qty) setQty(prd.qty);
    console.log(prd);
  }, [prd]);

  const debouncedFunction = useCallback(
    lodash.debounce((p, value, type) => {
      AddtoCart(p, value, type);
    }, 500), // 500ms debounce delay
    []
  );

  const QuantityInc = () => {
    console.log(parseInt(qty + 1));
    setQty(parseInt(qty + 1));
    debouncedFunction(product, parseInt(qty + 1), "INC");
  };
  const QuantityDec = () => {
    setQty(parseInt(qty - 1));
    debouncedFunction(product, parseInt(qty - 1), "DEC");
  };
  const handleOnChange = (e) => {
    var value = e.target.value;
    if (isNumber(e)) {
      setQty(value);
      debouncedFunction(product, parseInt(value), "INC");
    }
  };

  const AddtoCart = (p, q, t) => {
    var params = {
      userid: String(globalcontext.UserId),
      productId: String(p.prod_id),
      qty: parseInt(q),
    };
    const toastObj = new CartToastObject(
      p.prod_code,
      p.image_th,
      p.description,
      p.price_ext_applied,
      q
    );
    globalcontext.setCardToast({ ...toastObj, qty: q, show: false, type: t });
    globalcontext.setCartItem(null);
    axiosInstance
      .post("AddtoCart/AddtoCart", params)
      .then((response) => {
        if (response.data != -1) {
          globalcontext.setCartItem(response.data);
          globalcontext.setCardToast({
            ...toastObj,
            qty: q,
            show: true,
            type: t,
          });
        } else {
          alert("Something Wrong !");
        }
      })
      .catch((error) => console.log(error.message));
  };

  return (
    <Fragment>
      {product && (
        <div className="product-quantity" id={product.prod_id}>
          <div className="qty-container">
            <button
              className="qty-btn-minus btn-light"
              type="button"
              onClick={QuantityDec}
            >
              <i className="fa fa-minus" />
            </button>
            <input
              type="text"
              name="qty"
              value={qty}
              onChange={handleOnChange}
              className="input-qty"
            />
            <button
              className="qty-btn-plus btn-light"
              type="button"
              onClick={QuantityInc}
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};
