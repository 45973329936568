import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../Context/GlobalContext";
import { CartItemBody } from "./carditemBody";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "bootstrap";

export const CartSidePopup = () => {
  const globalcontext = useContext(GlobalContext);
  const [CartItem, setCartItem] = useState(null);
  const offcanvasRef = useRef(null);
  const history = useNavigate();
  useEffect(() => {
    if (globalcontext.CartItem) setCartItem(globalcontext.CartItem);
    console.log(globalcontext.CartItem);
  }, [globalcontext.CartItem]);
  const handleNavigation = () => {
    const modalElement = offcanvasRef.current;
    const modalInstance = Offcanvas.getInstance(modalElement);

    if (modalInstance) {
      modalInstance.hide(); // Close the modal programmatically
    }
  };
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="cartPopup"
      ref={offcanvasRef}
      aria-labelledby="offcanvasTopLabel"
    >
      <div className="offcanvas-header cartPopup-header">
        <h5 id="offcanvasTopLabel">My Cart</h5>
        <p
          className="text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fa-solid fa-xmark" />
        </p>
      </div>
      {CartItem && (
        <Fragment>
          <div className="offcanvas-body cartPopup-body">
            <div className="cartPoup-info">
              <h6>Your Cart</h6>
              <p>({CartItem.totalItem} Items)</p>
            </div>
            <CartItemBody cartItemList={CartItem.cartItem} />
          </div>
          <div className="offcanvas-footer cartPopup-footer">
            {/* Subtotal */}
            <div className="cart-popup-subtotal">
              <div className="cartPopup-subtotal">
                <h6>
                  Sub Total <span>({CartItem.totalItem} Items)</span>
                </h6>
                <p>Price Ex GST</p>
              </div>
              <div className="subtotal-price">${CartItem.totalcost}</div>
            </div>
            {/* Subtotal */}
            <div className="subtotal-btn">
              <Link
                to="/OrderDetails"
                onClick={handleNavigation}
                className="btn btn-products btn-unavailable"
              >
                View Cart
              </Link>
              <div className="button-effect popup-checkout-btn">
                <a
                  href="../checkout/checkout-delivery.html"
                  className="btn btn-products btn-addcart green-btn"
                >
                  Checkout
                </a>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
