import React, { useEffect, useState } from "react";
import { useAuth } from "./AuthContext";

export const GlobalContext = React.createContext();

function GlobalContextProvider(props) {
  const { userId, username, user, role, isAuthenticated } = useAuth();
  const [UserId, setUserId] = useState(0);
  const [CardToast, setCardToast] = useState(null);
  const [SessionTimeout, setSessionTimeout] = useState(false);
  const [CartItem, setCartItem] = useState(null);
  const [OrderId, setOrderId] = useState(null);
  const [UserDetail, setUserDetail] = useState(null);
  const [CategoryMenu, setCategoryMenu] = useState(null);
  const [WishList, setWishList] = useState(null);
  const [IndexProduct, setIndexProduct] = useState(null);

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  return (
    <GlobalContext.Provider
      value={{
        SessionTimeout,
        setSessionTimeout,
        UserDetail,
        setUserDetail,
        UserId,
        setUserId,
        CardToast,
        setCardToast,
        CartItem,
        setCartItem,
        CategoryMenu,
        setCategoryMenu,
        WishList,
        setWishList,
        IndexProduct,
        setIndexProduct,
        OrderId,
        setOrderId,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
export default GlobalContextProvider;
