export const FilterPanelMobile = () => {
  return (
    <div
      className="offcanvas offcanvas-end sorting-offcanvas"
      tabIndex={-1}
      id="mobFilters"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header filters-header border-bottom">
        <h5 id="offcanvasRightLabel">Filters</h5>
        <div className="clear-filter">
          <a className="cc-clear">Clear Filter</a>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
      </div>
      <div className="offcanvas-body moboffcanvas-body">
        <div className="accordion" id="accordionExample">
          {/* Category Filter */}
          <div className="cc-mob-filter">
            <div
              className="category-mob"
              data-bs-toggle="collapse"
              data-bs-target="#mobcategory"
              aria-expanded="false"
              aria-controls="mobcategory"
            >
              <p>
                Categories<small>1</small>
              </p>{" "}
              <i className="fa-solid fa-chevron-right" />
            </div>
            <div
              id="mobcategory"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              {/* Filter content */}
              <div className="filter-body">
                {/* Categories Filter */}
                <div
                  className="categ-form-check mob-filters"
                  id="accordion"
                  aria-multiselectable="true"
                >
                  <div className="categ-list">
                    {/* Av Tv Sat Installation */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel1"
                          >
                            Av Tv Sat Installation
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mainlevelone"
                          aria-expanded="false"
                          aria-controls="mainlevelone"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      <div
                        id="mainlevelone"
                        className="categ-first-level collapse"
                      >
                        {/* inner child 1 */}
                        <div className="categ-childTwo innerchild">
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobcheckchildlevel1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobcheckchildlevel1"
                              >
                                AV Equipment
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobavtwo"
                              aria-expanded="false"
                              aria-controls="mobavtwo"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobavtwo"
                            className="categ-first-level collapse categoryLevels"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobavchildthree"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobavchildthree"
                                >
                                  Category3
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobavthree"
                                aria-expanded="false"
                                aria-controls="mobavthree"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobavthree"
                              className="categ-first-level collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobavchildfour"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobavchildfour"
                                  >
                                    Category4
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobavfour"
                                  aria-expanded="false"
                                  aria-controls="mobavfour"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobavfour"
                                className="categ-first-level innerchild collapse"
                              >
                                <div className="categ-childOne-inner">
                                  <div className="categ-form-check form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="mobavchildfour"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="mobavchildfour"
                                    >
                                      Category5
                                    </label>
                                  </div>
                                  <p
                                    data-bs-toggle="collapse"
                                    data-bs-target="#mobavfive"
                                    aria-expanded="false"
                                    aria-controls="mobavfive"
                                  >
                                    <i className="fa-solid fa-plus" />
                                  </p>
                                </div>
                                <div
                                  id="mobavfive"
                                  className="categ-second-level collapse"
                                >
                                  <div className="categ-form-check form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="mobavchildfiveone"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="mobavchildfiveone"
                                    >
                                      Last Category Items
                                    </label>
                                  </div>
                                  <div className="categ-form-check form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="mobavchildfivetwo"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="mobavchildfivetwo"
                                    >
                                      Last Category Items
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* inner child 1*/}
                        {/* inner child 2 */}
                        <div className="categ-childTwo innerchild">
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobcheckchildlevel2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobcheckchildlevel2"
                              >
                                AV Extenders
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#avextender"
                              aria-expanded="false"
                              aria-controls="avextender"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="avextender"
                            className="categ-first-level collapse categoryLevels"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobaudiochildone"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobaudiochildone"
                                >
                                  Audio/Video Over CAT5 Extenders
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#audiotwo"
                                aria-expanded="false"
                                aria-controls="audiotwo"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="audiotwo"
                              className="categ-first-level collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobaudiochilthree"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobaudiochilthree"
                                  >
                                    Category3
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#audiothree"
                                  aria-expanded="false"
                                  aria-controls="avfour"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="audiothree"
                                className="categ-first-level innerchild collapse"
                              >
                                <div className="categ-childOne-inner">
                                  <div className="categ-form-check form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="audiochilfour"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="audiochilfour"
                                    >
                                      Category4
                                    </label>
                                  </div>
                                  <p
                                    data-bs-toggle="collapse"
                                    data-bs-target="#audiochilfour"
                                    aria-expanded="false"
                                    aria-controls="audiochilfour"
                                  >
                                    <i className="fa-solid fa-plus" />
                                  </p>
                                </div>
                                <div
                                  id="audiochilfour"
                                  className="categ-first-level innerchild collapse"
                                >
                                  <div className="categ-childOne-inner">
                                    <div className="categ-form-check form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="avchildfour"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="avchildfour"
                                      >
                                        Category5
                                      </label>
                                    </div>
                                    <p
                                      data-bs-toggle="collapse"
                                      data-bs-target="#avfive"
                                      aria-expanded="false"
                                      aria-controls="avfive"
                                    >
                                      <i className="fa-solid fa-plus" />
                                    </p>
                                  </div>
                                  <div
                                    id="avfive"
                                    className="categ-second-level collapse"
                                  >
                                    <div className="categ-form-check form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="avchildfiveone"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="avchildfiveone"
                                      >
                                        Last Category Items
                                      </label>
                                    </div>
                                    <div className="categ-form-check form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="avchildfivetwo"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="avchildfivetwo"
                                      >
                                        Last Category Items
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* inner child 2*/}
                      </div>
                    </div>
                    {/* Av Tv Sat Installation */}
                    {/* Cables & Connectors */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="checkmainlevel2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkmainlevel2"
                          >
                            Cables &amp; Connectors
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobcavbleconnectors"
                          aria-expanded="false"
                          aria-controls="mobcavbleconnectors"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobcavbleconnectors"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobcabchildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobcabchildtwo"
                            >
                              Adaptors
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobcabtwo"
                            aria-expanded="false"
                            aria-controls="mobcabtwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobcabtwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobcabchildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobcabchildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobcabthree"
                              aria-expanded="false"
                              aria-controls="mobcabthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobcabthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobcabchildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobcabchildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobcabfour"
                                aria-expanded="false"
                                aria-controls="mobcabfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobcabfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobcabchildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobcabchildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobcabfive"
                                  aria-expanded="false"
                                  aria-controls="mobcabfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobcabfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobavchildfiveone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobavchildfiveone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobavchildfivetwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobavchildfivetwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobcavbleconnectors"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobplugchildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobplugchildtwo"
                            >
                              Audio Plug Adaptors
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobplugtwo"
                            aria-expanded="false"
                            aria-controls="mobplugtwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobplugtwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobplugchildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobplugchildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobplugthree"
                              aria-expanded="false"
                              aria-controls="mobplugthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobplugthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobplugchildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobplugchildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobplugfour"
                                aria-expanded="false"
                                aria-controls="mobplugfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobplugfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobplugchildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobplugchildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobplugfive"
                                  aria-expanded="false"
                                  aria-controls="mobplugfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobplugfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobplugchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobplugchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobplugchildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobplugchildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                    </div>
                    {/* Cables & Connectors */}
                    {/* Audio Speakers & PA */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel3"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel3"
                          >
                            Audio Speakers &amp; PA
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobspeakerspa"
                          aria-expanded="false"
                          aria-controls="mobspeakerspa"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobspeakerspa"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobfeaturechildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobfeaturechildtwo"
                            >
                              Featured Brands Audio
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobfeaturetwo"
                            aria-expanded="false"
                            aria-controls="mobfeaturetwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobfeaturetwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobfeaturechildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobfeaturechildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobfeaturethree"
                              aria-expanded="false"
                              aria-controls="mobfeaturethree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobfeaturethree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobfeaturechildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobfeaturechildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobfeaturefour"
                                aria-expanded="false"
                                aria-controls="mobfeaturefour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobfeaturefour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobfeaturechildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobfeaturechildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobfeaturefive"
                                  aria-expanded="false"
                                  aria-controls="mobfeaturefive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobfeaturefive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobfeaturechildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobfeaturechildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobfeaturechildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobfeaturechildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobspeakerspa"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobdaytonchildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobdaytonchildtwo"
                            >
                              Dayton Audio USA
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobdaytontwo"
                            aria-expanded="false"
                            aria-controls="mobdaytontwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobdaytontwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobdaytonchildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobdaytonchildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobdaytonthree"
                              aria-expanded="false"
                              aria-controls="mobdaytonthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobdaytonthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobdaytonchildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobdaytonchildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobdaytonfour"
                                aria-expanded="false"
                                aria-controls="mobdaytonfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobdaytonfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobdaytonchildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobdaytonchildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobdaytonfive"
                                  aria-expanded="false"
                                  aria-controls="mobdaytonfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobdaytonfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobdaytonchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobdaytonchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobdaytonchildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobdaytonchildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                    </div>
                    {/* Audio Speakers & PA */}
                    {/* Data & Computer */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel4"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel4"
                          >
                            Data &amp; Computer
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobdatacomputer"
                          aria-expanded="false"
                          aria-controls="mobdatacomputer"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobdatacomputer"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobcablechildone"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobcablechildone"
                            >
                              Cables &amp; Leads
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobcableone"
                            aria-expanded="false"
                            aria-controls="mobcableone"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobcableone"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobcablechildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobcablechildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobcablethree"
                              aria-expanded="false"
                              aria-controls="mobcablethree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobcablethree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobcablechildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobcablechildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobcablefour"
                                aria-expanded="false"
                                aria-controls="mobcablefour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobcablefour"
                              className="categ-first-level innerchild collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobcablechildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobcablechildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobcablefive"
                                  aria-expanded="false"
                                  aria-controls="mobcablefive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobcablefive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobcablechildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobcablechildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobcablechildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobcablechildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobdatacomputer"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobscsichildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobscsichildtwo"
                            >
                              SCSI Leads
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobscsitwo"
                            aria-expanded="false"
                            aria-controls="mobscsitwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobscsitwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobscsichildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobscsichildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobscsithree"
                              aria-expanded="false"
                              aria-controls="mobscsithree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobscsithree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobscsichildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobscsichildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobscsifour"
                                aria-expanded="false"
                                aria-controls="mobscsifour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobscsifour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobscsichildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobscsichildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobscsifive"
                                  aria-expanded="false"
                                  aria-controls="mobscsifive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobscsifive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobscsichildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobscsichildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobscsichildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobscsichildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                    </div>
                    {/* Data & Computer */}
                    {/* Security */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel5"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel5"
                          >
                            Security
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobsecurityone"
                          aria-expanded="false"
                          aria-controls="mobsecurityone"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobsecurityone"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobsecuritychildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobsecuritychildtwo"
                            >
                              Cables &amp; Leads
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobsecuritytwo"
                            aria-expanded="false"
                            aria-controls="mobsecuritytwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobsecuritytwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobsecuritychildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobsecuritychildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobsecuritythree"
                              aria-expanded="false"
                              aria-controls="mobsecuritythree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobsecuritythree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobsecuritychildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobsecuritychildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobsecurityfour"
                                aria-expanded="false"
                                aria-controls="mobsecurityfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobsecurityfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobsecuritychildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobsecuritychildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobsecurityfive"
                                  aria-expanded="false"
                                  aria-controls="mobsecurityfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobsecurityfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobsecuritychildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobsecuritychildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobsecuritychildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobsecuritychildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobsecurityone"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobalaramchildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobalaramchildtwo"
                            >
                              Alarm
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobalaramtwo"
                            aria-expanded="false"
                            aria-controls="mobalaramtwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobalaramtwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobalaramchildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobalaramchildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobalaramthree"
                              aria-expanded="false"
                              aria-controls="mobalaramthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobalaramthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobalaramchildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobalaramchildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobalaramfour"
                                aria-expanded="false"
                                aria-controls="mobalaramfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobalaramfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobalaramchildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobalaramchildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobsecurityfive"
                                  aria-expanded="false"
                                  aria-controls="mobsecurityfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobsecurityfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobalaramchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobalaramchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobalaramchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobalaramchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                    </div>
                    {/* Security */}
                    {/* Cellular Accessories */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel6"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel6"
                          >
                            Cellular Accessories
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobcelluaraccess"
                          aria-expanded="false"
                          aria-controls="mobcelluaraccess"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobcelluaraccess"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobusbchildone"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobusbchildone"
                            >
                              USB Type-C
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobusbone"
                            aria-expanded="false"
                            aria-controls="mobusbone"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobusbone"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobusbchildtwo"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobusbchildtwo"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobusbtwo"
                              aria-expanded="false"
                              aria-controls="mobusbtwo"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobusbtwo"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobusbchildthree"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobusbchildthree"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobusbthree"
                                aria-expanded="false"
                                aria-controls="mobusbthree"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobusbthree"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobusbchildfour"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobusbchildfour"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobusbfour"
                                  aria-expanded="false"
                                  aria-controls="mobusbfour"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobusbfour"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobusbchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobusbchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobusbchildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobusbchildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobcelluaraccess"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobapplechildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobapplechildtwo"
                            >
                              Apple™ Lightning®
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobappletwo"
                            aria-expanded="false"
                            aria-controls="mobappletwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobappletwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobapplechildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobapplechildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobapplethree"
                              aria-expanded="false"
                              aria-controls="mobapplethree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobapplethree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobapplechildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobapplechildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobapplechildfour"
                                aria-expanded="false"
                                aria-controls="mobapplechildfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobapplechildfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobapplechildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobapplechildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobapplefive"
                                  aria-expanded="false"
                                  aria-controls="mobapplefive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobapplefive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobapplechildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobapplechildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobapplechildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobapplechildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                    </div>
                    {/* Cellular Accessories */}
                    {/* Communication Accessories */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel7"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel7"
                          >
                            Communication Accessories
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobcommunication"
                          aria-expanded="false"
                          aria-controls="mobcommunication"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobcommunication"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobcommunicationchildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobcommunicationchildtwo"
                            >
                              Antenna Accessories
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobcommunicationtwo"
                            aria-expanded="false"
                            aria-controls="mobcommunicationtwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobcommunicationtwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobcommunicationchildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobcommunicationchildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobcommunicationthree"
                              aria-expanded="false"
                              aria-controls="mobcommunicationthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobcommunicationthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobcommunicationchildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobcommunicationchildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobcommunicationfour"
                                aria-expanded="false"
                                aria-controls="mobcommunicationfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobcommunicationfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobcommunicationchildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobcommunicationchildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobcommunicationfive"
                                  aria-expanded="false"
                                  aria-controls="mobcommunicationfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobcommunicationfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobcommunicationlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobcommunicationlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobcommunicationlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobcommunicationlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobcommunication"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobmountingchildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobmountingchildtwo"
                            >
                              Antenna Mounting
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobmountingtwo"
                            aria-expanded="false"
                            aria-controls="mobmountingtwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobmountingtwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobmountingchildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobmountingchildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobmountingthree"
                              aria-expanded="false"
                              aria-controls="mobmountingthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobmountingthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobmountingchildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobmountingchildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobmountingfour"
                                aria-expanded="false"
                                aria-controls="mobmountingfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobmountingfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobmountingchildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobmountingchildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobmountingfive"
                                  aria-expanded="false"
                                  aria-controls="mobmountingfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobmountingfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobmountingchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobmountingchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobmountingchildlastwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobmountingchildlastwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                    </div>
                    {/* Communication Accessories */}
                    {/* Tools & Test */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel8"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel8"
                          >
                            Tools &amp; Test
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobtoolstest"
                          aria-expanded="false"
                          aria-controls="mobtoolstest"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobtoolstest"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobhandchildone"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobhandchildone"
                            >
                              Hand Tools
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobhandone"
                            aria-expanded="false"
                            aria-controls="mobhandone"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobhandone"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobhandchildtwo"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobhandchildtwo"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobhandtwo"
                              aria-expanded="false"
                              aria-controls="mobhandtwo"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobhandtwo"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobhandchildthree"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobhandchildthree"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobhandthree"
                                aria-expanded="false"
                                aria-controls="mobhandthree"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobhandthree"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobhandchildfour"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobhandchildfour"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobhandfour"
                                  aria-expanded="false"
                                  aria-controls="mobhandfour"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobhandfour"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobhandchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobhandchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobhandchildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobhandchildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobtoolstest"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobscrewchildone"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobscrewchildone"
                            >
                              Screwdrivers Cordless
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobscrewone"
                            aria-expanded="false"
                            aria-controls="mobscrewone"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobscrewone"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobscrewchildtwo"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobscrewchildtwo"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobscrewtwo"
                              aria-expanded="false"
                              aria-controls="mobscrewtwo"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobscrewtwo"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobscrewchildthree"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobscrewchildthree"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobscrewthree"
                                aria-expanded="false"
                                aria-controls="mobscrewthree"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobscrewthree"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobscrewchildfour"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobscrewchildfour"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobscrewfour"
                                  aria-expanded="false"
                                  aria-controls="mobscrewfour"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobscrewfour"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobscrewchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobscrewchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobscrewchildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobscrewchildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                    </div>
                    {/* Tools & Test */}
                    {/* Power & Lighting */}
                    <div className="ct-categ-list">
                      <div className="categ-listing">
                        <div className="categ-form-check form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobcheckmainlevel9"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobcheckmainlevel9"
                          >
                            Power &amp; Lighting
                          </label>
                        </div>
                        <p
                          data-bs-toggle="collapse"
                          data-bs-target="#mobpowerlighting"
                          aria-expanded="false"
                          aria-controls="mobpowerlighting"
                        >
                          <i className="fa-solid fa-plus" />
                        </p>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mobpowerlighting"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mobbatterieschildone"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mobbatterieschildone"
                            >
                              Batteries Primary
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#mobbatteriesone"
                            aria-expanded="false"
                            aria-controls="mobbatteriesone"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="mobbatteriesone"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mobbatterieschildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mobbatterieschildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#mobbatteriesthree"
                              aria-expanded="false"
                              aria-controls="mobbatteriesthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="mobbatteriesthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mobbatterieschildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobbatterieschildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#mobbatteriesfour"
                                aria-expanded="false"
                                aria-controls="mobbatteriesfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="mobbatteriesfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobbatterieschildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobbatterieschildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#mobbatteriesfive"
                                  aria-expanded="false"
                                  aria-controls="mobbatteriesfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="mobbatteriesfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobbatterieschildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobbatterieschildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="mobbatterieschildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mobbatterieschildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      {/* Inner Levels */}
                      <div
                        id="mobpowerlighting"
                        className="categ-first-level collapse categoryLevels"
                      >
                        <div className="categ-childOne-inner">
                          <div className="categ-form-check form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="moblitiumchildtwo"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="moblitiumchildtwo"
                            >
                              Lithium Batteries
                            </label>
                          </div>
                          <p
                            data-bs-toggle="collapse"
                            data-bs-target="#moblitiumtwo"
                            aria-expanded="false"
                            aria-controls="moblitiumtwo"
                          >
                            <i className="fa-solid fa-plus" />
                          </p>
                        </div>
                        <div
                          id="moblitiumtwo"
                          className="categ-first-level collapse"
                        >
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="moblitiumchildthree"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="moblitiumchildthree"
                              >
                                Category3
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#moblitiumthree"
                              aria-expanded="false"
                              aria-controls="moblitiumthree"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="moblitiumthree"
                            className="categ-first-level innerchild collapse"
                          >
                            <div className="categ-childOne-inner">
                              <div className="categ-form-check form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="moblitiumchildfour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="moblitiumchildfour"
                                >
                                  Category4
                                </label>
                              </div>
                              <p
                                data-bs-toggle="collapse"
                                data-bs-target="#moblitiumfour"
                                aria-expanded="false"
                                aria-controls="moblitiumfour"
                              >
                                <i className="fa-solid fa-plus" />
                              </p>
                            </div>
                            <div
                              id="moblitiumfour"
                              className="categ-first-level innerchild collapse"
                            >
                              <div className="categ-childOne-inner">
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="moblitiumchildfive"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="moblitiumchildfive"
                                  >
                                    Category5
                                  </label>
                                </div>
                                <p
                                  data-bs-toggle="collapse"
                                  data-bs-target="#moblitiumfive"
                                  aria-expanded="false"
                                  aria-controls="moblitiumfive"
                                >
                                  <i className="fa-solid fa-plus" />
                                </p>
                              </div>
                              <div
                                id="moblitiumfive"
                                className="categ-second-level collapse"
                              >
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="moblitiumchildlastone"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="moblitiumchildlastone"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                                <div className="categ-form-check form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="moblitiumchildlasttwo"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="moblitiumchildlasttwo"
                                  >
                                    Last Category Items
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Inner Levels */}
                      <div
                        id="mainlevel11"
                        className="categ-first-level accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        {/* inner child 1 */}
                        <div className="categ-childTwo innerchild">
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="checkchildlevel9"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkchildlevel9"
                              >
                                Batteries Primary
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#childlevel16"
                              aria-expanded="false"
                              aria-controls="childlevel16"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="childlevel16"
                            className="categ-second-level accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="innerchildlevel19_1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="innerchildlevel19_1"
                              >
                                Lithium Batteries
                              </label>
                            </div>
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="innerchildlevel19_2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="innerchildlevel19_2"
                              >
                                Button Cells
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* inner child 1*/}
                        {/* inner child 2 */}
                        <div className="categ-childTwo innerchild">
                          <div className="categ-childOne-inner">
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="checkchildlevel10"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkchildlevel10"
                              >
                                Batteries Rechargeable
                              </label>
                            </div>
                            <p
                              data-bs-toggle="collapse"
                              data-bs-target="#childlevel17"
                              aria-expanded="false"
                              aria-controls="childlevel9"
                            >
                              <i className="fa-solid fa-plus" />
                            </p>
                          </div>
                          <div
                            id="childlevel17"
                            className="categ-second-level accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="innerchildlevel20_1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="innerchildlevel20_1"
                              >
                                LiFePO4 Lithium Iron Phosphate
                              </label>
                            </div>
                            <div className="categ-form-check form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="innerchildlevel20_2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="innerchildlevel20_2"
                              >
                                Ni-MH Batteries
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* inner child 2*/}
                      </div>
                    </div>
                    {/* Power & Lighting */}
                  </div>
                </div>
                {/* Categories Filter */}
              </div>
              {/* Filter content */}
            </div>
          </div>
          {/* Category Filter */}
          {/* Manufacture Filter */}
          <div className="cc-mob-filter">
            <div
              className="category-mob"
              data-bs-toggle="collapse"
              data-bs-target="#mobmanufacture"
              aria-expanded="false"
              aria-controls="mobmanufacture"
            >
              <p>Manufacturer</p> <i className="fa-solid fa-chevron-right" />
            </div>
            <div
              id="mobmanufacture"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              {/* Manufacture content */}
              <div className="filter-body">
                <div className="categ-form-check mob-filters">
                  <div className="categ-list">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="aerpro"
                      />
                      <label className="form-check-label" htmlFor="aerpro">
                        Aerpro(47)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cellink"
                      />
                      <label className="form-check-label" htmlFor="cellink">
                        Cellink(22)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="avlabls"
                      />
                      <label className="form-check-label" htmlFor="avlabls">
                        AVLabs(3)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="benelec"
                      />
                      <label className="form-check-label" htmlFor="benelec">
                        Benelec(2)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="bosch"
                      />
                      <label className="form-check-label" htmlFor="bosch">
                        Bosch(91)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cypress"
                      />
                      <label className="form-check-label" htmlFor="cypress">
                        Cypress(6)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="daichi"
                      />
                      <label className="form-check-label" htmlFor="daichi">
                        Daichi(230)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="dayton"
                      />
                      <label className="form-check-label" htmlFor="dayton">
                        Dayton Audio(356)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edifier"
                      />
                      <label className="form-check-label" htmlFor="edifier">
                        Edifier(13)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Electro"
                      />
                      <label className="form-check-label" htmlFor="electro">
                        Electro-Voice(28)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fountek"
                      />
                      <label className="form-check-label" htmlFor="fountek">
                        Fountek(3)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="jtc"
                      />
                      <label className="form-check-label" htmlFor="jtc">
                        JTS(20)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="koss"
                      />
                      <label className="form-check-label" htmlFor="koss">
                        Koss(27)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* Manufacture content */}
            </div>
          </div>
          {/* Manufacture Filter */}
          {/* Length Filter */}
          <div className="cc-mob-filter">
            <div
              className="category-mob"
              data-bs-toggle="collapse"
              data-bs-target="#moblength"
              aria-expanded="false"
              aria-controls="moblength"
            >
              <p>Length</p> <i className="fa-solid fa-chevron-right" />
            </div>
            <div
              id="moblength"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              {/* Manufacture content */}
              <div className="filter-body">
                <div className="categ-form-check mob-filters">
                  <div className="categ-list">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthone"
                      />
                      <label className="form-check-label" htmlFor="lengthone">
                        0.12M(5)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthtwo"
                      />
                      <label className="form-check-label" htmlFor="lengthtwo">
                        0.1M(26)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengththree"
                      />
                      <label className="form-check-label" htmlFor="lengththree">
                        0.2M(14)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthfour"
                      />
                      <label className="form-check-label" htmlFor="lengthfour">
                        0.5M(70)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthfive"
                      />
                      <label className="form-check-label" htmlFor="lengthfive">
                        1.2M(37)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthsix"
                      />
                      <label className="form-check-label" htmlFor="lengthsix">
                        1.5M(98)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthseven"
                      />
                      <label className="form-check-label" htmlFor="lengthseven">
                        1.8M(37)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengtheight"
                      />
                      <label className="form-check-label" htmlFor="lengtheight">
                        100M(103)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthnine"
                      />
                      <label className="form-check-label" htmlFor="lengthnine">
                        10M(76)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthten"
                      />
                      <label className="form-check-label" htmlFor="lengthten">
                        15M(54)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengtheleven"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="lengtheleven"
                      >
                        190mm(1)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengthtwelve"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="lengthtwelve"
                      >
                        1M(78)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lengththirteen"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="lengththirteen"
                      >
                        2.0M(57)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Length Filter */}
          {/* Colour Filter */}
          <div className="cc-mob-filter">
            <div
              className="category-mob"
              data-bs-toggle="collapse"
              data-bs-target="#mobcolour"
              aria-expanded="false"
              aria-controls="mobcolour"
            >
              <p>Colour</p> <i className="fa-solid fa-chevron-right" />
            </div>
            <div
              id="mobcolour"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              {/* Manufacture content */}
              <div className="filter-body">
                <div className="categ-form-check mob-filters">
                  <div className="categ-list">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="black"
                      />
                      <label className="form-check-label" htmlFor="black">
                        Black(412)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="red"
                      />
                      <label className="form-check-label" htmlFor="red">
                        Black/Red(6)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="blue"
                      />
                      <label className="form-check-label" htmlFor="blue">
                        Blue(85)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="brown"
                      />
                      <label className="form-check-label" htmlFor="brown">
                        Brown(17)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="clear"
                      />
                      <label className="form-check-label" htmlFor="clear">
                        Clear(6)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cream"
                      />
                      <label className="form-check-label" htmlFor="cream">
                        Cream(1)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gold"
                      />
                      <label className="form-check-label" htmlFor="gold">
                        Gold(11)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="green"
                      />
                      <label className="form-check-label" htmlFor="green">
                        Green(49)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="grey"
                      />
                      <label className="form-check-label" htmlFor="grey">
                        GREY(61)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="orange"
                      />
                      <label className="form-check-label" htmlFor="orange">
                        Orange(18)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="pink"
                      />
                      <label className="form-check-label" htmlFor="pink">
                        Pink(1)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="purple"
                      />
                      <label className="form-check-label" htmlFor="purple">
                        Purple(1)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="redone"
                      />
                      <label className="form-check-label" htmlFor="redone">
                        Red(141)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Colour Filter */}
          {/* Type Filter */}
          <div className="cc-mob-filter">
            <div
              className="category-mob"
              data-bs-toggle="collapse"
              data-bs-target="#mobtype"
              aria-expanded="false"
              aria-controls="mobtype"
            >
              <p>Type</p> <i className="fa-solid fa-chevron-right" />
            </div>
            <div
              id="mobtype"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              {/* Manufacture content */}
              <div className="filter-body">
                <div className="categ-form-check mob-filters">
                  <div className="categ-list">
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="threepin"
                      />
                      <label className="form-check-label" htmlFor="threepin">
                        3 PIN(13)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="blacktype"
                      />
                      <label className="form-check-label" htmlFor="blacktype">
                        Black(2)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="bulk"
                      />
                      <label className="form-check-label" htmlFor="bulk">
                        Bulk(50)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cutlength"
                      />
                      <label className="form-check-label" htmlFor="cutlength">
                        Cut To Length(1)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="double"
                      />
                      <label className="form-check-label" htmlFor="double">
                        Double(7)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="female"
                      />
                      <label className="form-check-label" htmlFor="female">
                        Female(24)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flange"
                      />
                      <label className="form-check-label" htmlFor="flange">
                        Flange(2)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="hdmi"
                      />
                      <label className="form-check-label" htmlFor="hdmi">
                        HDMI(2)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="male"
                      />
                      <label className="form-check-label" htmlFor="male">
                        Male(24)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="malera"
                      />
                      <label className="form-check-label" htmlFor="malera">
                        Male R/A(3)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="original"
                      />
                      <label className="form-check-label" htmlFor="original">
                        Original(1)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="reel"
                      />
                      <label className="form-check-label" htmlFor="reel">
                        Reel(1)
                      </label>
                    </div>
                    <div className="categ-form-check form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="rgtype"
                      />
                      <label className="form-check-label" htmlFor="rgtype">
                        RG58 type(1)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Type Filter */}
        </div>
      </div>
      <div className="offcanvas-footer border-top">
        <button
          type="submit"
          className="btn btn-addcart"
          data-bs-dismiss="offcanvas"
        >
          Show 600+ Results
        </button>
      </div>
    </div>
  );
};
