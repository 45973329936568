import React, { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Modal } from "bootstrap";
const OrderClarificationContext = createContext();

const useOrderClarificationModal = () => useContext(OrderClarificationContext);

const OrderClarificationProvider = ({ children }) => {
  const [isOrderClarification, setIsOrderClarification] = useState(false);

  const [modalState, setModalState] = useState({
    isVisible: false,
    content: null,
  });

  // Show the modal
  const showOrderClarification = () => {
    var modalDiv = document.getElementById("productfound");
    var popupDiv = Modal.getInstance(modalDiv);

    if (!popupDiv) {
      popupDiv = new Modal(modalDiv);
    }
    if (!modalDiv.classList.contains("show")) {
      popupDiv.show();
    }
  };

  // Hide the modal
  const hideOrderClarification = () => {
    var modalDiv = document.getElementById("productfound");
    var popupDiv = new Modal(modalDiv);
    popupDiv.hide();
  };

  return (
    <OrderClarificationContext.Provider
      value={{
        modalState,
        showOrderClarification,
        hideOrderClarification,
        isOrderClarification,
        setIsOrderClarification,
      }}
    >
      {children}
      <div
        className="modal fade"
        id="productfound"
        role="dialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-animate-center product-notfound-dialog">
          <div className="modal-content">
            <p className="order-status-close" data-bs-dismiss="modal">
              <i className="fa-regular fa-circle-xmark" />
            </p>
            <div className="modal-body p-0">
              <div className="product-found">
                <div className="product-found-title">
                  <i className="fa-solid fa-triangle-exclamation" />
                  <h4>ORDER CLARIFICATIONS</h4>
                </div>

                <div className="product-found-body">
                  <p className="text-center">
                    Please review and clear any order clarifications. Once the
                    clarifications are resolved, you can proceed to checkout.
                  </p>
                  <div className="product-foundButton">
                    <div className="button-effect product-found-btn">
                      <Link
                        data-bs-dismiss="modal"
                        to="/OrderDetails"
                        className="btn btn-products btn-addcart"
                      >
                        <i className="fa-solid fa-check" /> Review Errors
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OrderClarificationContext.Provider>
  );
};
export {
  OrderClarificationContext,
  useOrderClarificationModal,
  OrderClarificationProvider,
};
