export const SortbyMobile = () => {
  return (
    <div
      className="offcanvas offcanvas-end sorting-offcanvas"
      tabIndex={-1}
      id="mobSorting"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header filters-header border-bottom">
        <h5 id="offcanvasRightLabel">Sortby</h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        <div className="categ-form-check mob-filters">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="sortnewest"
            />
            <label className="form-check-label" htmlFor="sortnewest">
              Newest
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="sortlow" />
            <label className="form-check-label" htmlFor="sortlow">
              Price : Low to High
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="sorthigh" />
            <label className="form-check-label" htmlFor="sorthigh">
              Price : High to Low
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="sortpopluar"
            />
            <label className="form-check-label" htmlFor="sortpopluar">
              Popular
            </label>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer border-top">
        <button
          type="submit"
          className="btn btn-addcart"
          data-bs-dismiss="offcanvas"
        >
          Apply
        </button>
      </div>
    </div>
  );
};
