import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
export const CategoryPanel = ({ data, index }) => {
  return (
    <div
      key={index}
      className={`tab-pane category-pane mob-category-pane ${
        index === 0 ? " active show" : ""
      }`}
      id={data.categoryId}
    >
      <div className="mob-categ-back d-block d-lg-none">
        <p className="back-categories">
          <i className="fa-solid fa-arrow-left-long" />
          Back to Main
        </p>
      </div>
      <div className="mob-top-categ">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-12">
              {/* Catg Title*/}
              <div className="catg-list-title">
                <h5>
                  <Link to={data.categoryPath}>{data.categoryName} </Link>
                </h5>
              </div>
              {/* Catg Title*/}
            </div>
          </div>
          <div className="row">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                {Array.isArray(data.CategoryList) &&
                  data.CategoryList.map((crow, i) => (
                    <div key={index + i} className="catg-menu-inner">
                      <Link
                        to={crow.categoryPath}
                        className="d-none d-lg-block"
                      >
                        <h6>
                          {crow.CategoryName}
                          <i className="fa-solid fa-chevron-right" />
                        </h6>
                      </Link>
                      <a
                        className="d-block d-lg-none"
                        data-bs-toggle="collapse"
                        href={"#" + crow.CategoryId.replace(/ /g, "_")}
                        role="button"
                        aria-expanded="false"
                        aria-controls={crow.CategoryId.replace(/ /g, "_")}
                      >
                        <h6>
                          {crow.CategoryName}
                          <i className="fa-solid fa-chevron-right" />
                        </h6>
                      </a>
                      <div
                        className="collapse thrid-collapse"
                        id={crow.CategoryId.replace(/ /g, "_")}
                      >
                        <p
                          className="mob-categ-back back-subcategories"
                          data-bs-toggle="collapse"
                          href={"#" + crow.CategoryId.replace(/ /g, "_")}
                        >
                          <i className="fa-solid fa-arrow-left-long" />
                          Back to Categories
                        </p>
                        <div className="inner-third-level">
                          <ul>
                            {crow.SubCategoryList &&
                              Array.isArray(crow.SubCategoryList) &&
                              crow.SubCategoryList.map((subrow, k) => (
                                <li key={index + i + k}>
                                  <Link to={subrow.subCategoryPath}>
                                    {subrow.subCategoryName}{" "}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </div>
    </div>
  );
};
