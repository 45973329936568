import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosInstance from "../../axios/axiosInterceptor";

export const CatelogueBanner = () => {
  const [eBookPoster, setEbookPoster] = useState(null);
  const { loading, error, get, post } = useAxiosInstance();
  useEffect(() => {
    get(
      "https://cdn.wes.com.au/wesreact/newui/banner/eBookPoster.txt",
      (response) => {
        if (response.data && response.data.EbookPoster) {
          console.log("CatelogueBanner", response.data.EbookPoster);
          setEbookPoster(response.data.EbookPoster);
        }
      }
    );
  }, []);
  return (
    <section className="clearance-section">
      {eBookPoster &&
        eBookPoster.map((row, index) => (
          <div className="container-fluid container-lg">
            <div className="row">
              <div className="col-lg-12">
                <Link to={row.url}>
                  <img
                    src={row.image}
                    className="img-fluid"
                    alt="catelogue banner"
                  />
                </Link>
              </div>
            </div>
          </div>
        ))}
    </section>
  );
};
