import { lazy } from "react";
import { ContentSection } from "./View/Home/contentSection";
import { CategoryPage } from "./View/Category/categorypage";
import { PowerSearch } from "./View/PowerSearch/powerSearch";
import { BrandPage } from "./View/Brand/BrandPage";
import { LoginComp } from "./Layout/Common/Login";
import { OrderDetails } from "./View/OrderDetails/index";

// const contentSection = lazy(() =>
//   import("./View/Home/contentSection").then((module) => ({
//     default: module.ContentSection,
//   }))
// );
// const CategoryPage = lazy(() =>
//   import("./View/Category/categorypage").then((module) => ({
//     default: module.CategoryPage,
//   }))
// );
// const PowerSearch = lazy(() =>
//   import("./View/PowerSearch/powerSearch").then((module) => ({
//     default: module.PowerSearch,
//   }))
// );
// const LoginComp = lazy(() =>
//   import("./Layout/Common/Login").then((module) => ({
//     default: module.LoginComp,
//   }))
// );
// const BrandPage = lazy(() =>
//   import("./View/Brand/BrandPage").then((module) => ({
//     default: module.BrandPage,
//   }))
// );
// const OrderDetails = lazy(() =>
//   import("./View/OrderDetails/index").then((module) => ({
//     default: module.OrderDetails,
//   }))
// );

const PAGES = {
  HOME: "/Home",
  CATEGORY: "/Category",
  CATEGORY_EAPATH: "/Category/:cid/:pcid/:eapath",
  CATEGORY_EAPATH_FILTER: "/Category/:cid/:pcid/:eapath/:filter",
  BRAND: "/brand",
  POWERSEARCH: "/PowerSearch",
  POWERSEARCH_SEARCH: "/PowerSearch/:srctxt",
  LOGIN: "/Login",
  ORDER_DETAIL: "/OrderDetails",
};

const routesConfig = [
  { path: "/", Component: ContentSection },
  { path: PAGES.HOME, Component: ContentSection },
  { path: PAGES.CATEGORY, Component: CategoryPage },
  { path: PAGES.CATEGORY_EAPATH, Component: CategoryPage },
  { path: PAGES.CATEGORY_EAPATH_FILTER, Component: CategoryPage },
  { path: PAGES.BRAND, Component: BrandPage },

  { path: PAGES.POWERSEARCH, Component: PowerSearch },
  {
    path: PAGES.POWERSEARCH_SEARCH,
    Component: PowerSearch,
  },
  { path: PAGES.LOGIN, Component: LoginComp },
  { path: PAGES.ORDER_DETAIL, Component: OrderDetails },
];

const PAGES_WITHOUT_FOOTER = [PAGES.ORDER_DETAIL];

export { routesConfig, PAGES_WITHOUT_FOOTER, PAGES };
