import React, { useState } from 'react';

const lengths = [
  { id: "length1", length: "0.12M", count: 5 },
  { id: "length2", length: "0.1M", count: 26 },
  { id: "length3", length: "0.2M", count: 14 },
  { id: "length4", length: "0.5M", count: 70 },
  { id: "length5", length: "1.2M", count: 37 },
  { id: "length6", length: "1.5M", count: 98 },
  { id: "length7", length: "1.8M", count: 37 },
  { id: "length8", length: "100M", count: 103 },
  { id: "length9", length: "10M", count: 76 },
  { id: "length10", length: "15M", count: 54 },
  { id: "length11", length: "190mm", count: 1 },
  { id: "length12", length: "1M", count: 78 },
  { id: "length13", length: "2.0M", count: 57 }
];

export const LengthFilter = () => {
  const [search, setSearch] = useState('');
  const [selectedLengths, setSelectedLengths] = useState([]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleCheckboxChange = (id) => {
    setSelectedLengths(prevState =>
      prevState.includes(id)
        ? prevState.filter(lengthId => lengthId !== id)
        : [...prevState, id]
    );
  };

  const filteredLengths = lengths.filter(length =>
    length.length.toLowerCase().includes(search.toLowerCase())
  );

  const resetFilter = () => {
    setSelectedLengths([]);
  };

  return (
    <div className="ct-filters-list">
      <div className="ct-filter length-filter">
        <div className="ct-filter-top">
          <p>Length</p>
        </div>
        <i className="fa-solid fa-angle-down" />
        <p />
      </div>
      <div className="ct-length-filter">
        <div className="categ-search position-relative">
          <input
            className="form-control me-2"
            type="text"
            placeholder="Search for.."
            value={search}
            onChange={handleSearch}
            aria-label="Search"
          />
          <i className="fa-solid fa-magnifying-glass" />
        </div>
        <div className="categ-list">
          {filteredLengths.map(length => (
            <div key={length.id} className="categ-form-check form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedLengths.includes(length.id)}
                onChange={() => handleCheckboxChange(length.id)}
                id={length.id}
              />
              <label className="form-check-label" htmlFor={length.id}>
                {length.length}({length.count})
              </label>
            </div>
          ))}
        </div>
        <div className="reset-filter">
          <a href="#." onClick={resetFilter}>Reset Filter</a>
        </div>
      </div>
    </div>
  );
};
 
