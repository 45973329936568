import ReactOwlCarousel from "react-owl-carousel";
import { ProductCard } from "../../../Layout/Common/productCard";

export const ProductListMobileView = ({ ProductListJson }) => {
  var options = {
    items: 2,
    loop: true,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    margin: 10,
    responsive: {
      0: {
        items: 2, // 2 items for mobile view
      },
      768: {
        items: 3, // 1 item for medium view
        margin: 10,
      },
      991: {
        items: 3, // 1 item for large view
        margin: 20,
        autoplay: false,
      },
    },
  };
  return (
    <div
      className="row clearance-products d-block d-sm-none d-xs-block"
      key={Math.random()}
    >
      <div className="col-lg-12">
        <ReactOwlCarousel className="owl-theme" {...options}>
          {/* Product */}
          {ProductListJson &&
            ProductListJson.map((row, index) => (
              <>
                <div className="item" key={index + Math.random()}>
                  <ProductCard data={row} />
                </div>
              </>
            ))}
        </ReactOwlCarousel>
      </div>
    </div>
  );
};
