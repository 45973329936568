import $ from "jquery";
import { Toast } from "bootstrap";

export function showWishListAddToaster() {
  var toastEl = document.getElementById("wishlistadded");
  var toastAdd = new Toast(toastEl);
  var toastE2 = document.getElementById("wishlistremove");
  var toastremove = new Toast(toastE2);
  toastAdd.show();
  toastremove.hide();
}
export function showFavouriteToaster() {
  var toastEl = document.getElementById("wishlistcontainer");
  var toastAdd = new Toast(toastEl);
  var toastE2 = document.getElementById("wishlistremove");
  var toastremove = new Toast(toastE2);
  toastAdd.show();
  toastremove.hide();
  //showWishListAddToaster();
}

export function showWishListRemoveToaster() {
  var toastEl = document.getElementById("wishlistcontainer");
  var toastAdd = new Toast(toastEl);
  var toastE2 = document.getElementById("wishlistremove");
  var toastremove = new Toast(toastE2);
  toastAdd.hide();
  toastremove.show();
}

export function validateNumber(event) {
  var charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
}
export function isNumberKey(evt) {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
}

export function isNumber(e) {
  e = e ? e : window.event;
  var charCode = e.which ? e.which : e.keyCode;
  if (e.charCode == 8 || e.charCode == 9) {
    return false;
  }
  if (e.charCode >= 46 || e.charCode <= 57) {
    return true;
  } else return true;
} //  alert("test");

export function matchtxt(given_string, pattern) {
  given_string = given_string.toLowerCase();
  pattern = pattern.toLowerCase();
  return given_string.includes(pattern);
}

export function PageCompare(URL) {
  var currentPage = window.location.pathname;
  return URL.includes(currentPage);
}

export function isValidPhoneNumber(e) {
  e = e ? e : window.event;
  var charCode = e.which ? e.which : e.keyCode;
  if (e.charCode == 8 || e.charCode == 9) {
    return false;
  }
  if (e.charCode >= 46 || e.charCode <= 57) {
    return true;
  } else return true;
} //  alert("test");

export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export function isPhoneNumber(p) {
  var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
  var digits = p.replace(/\D/g, "");
  return phoneRe.test(digits);
}

export function base64Decode(encodedString) {
  try {
    if (encodedString == "") return encodedString;
    if (encodedString == null || encodedString == "null") return null;
    const decodedString = atob(encodedString);
    return decodedString;
  } catch (error) {
    return null;
  }
}
export function base64Encode(str) {
  const utf8Bytes = new TextEncoder().encode(str);
  return btoa(String.fromCharCode(...utf8Bytes));
}

export function IsNumberKey(v) {
  const numericValue = v.replace(/[^0-9]/g, "");
  return numericValue;
}
export function IsNumberKeyWithoutZero(v) {
  //console.log("v", v);
  if (v.startsWith("0") && String(v).length == 1) {
    return "";
  } else {
    const numericValue = v.replace(/[^0-9]/g, "");
    // console.log("numericValue", numericValue);
    return numericValue;
  }
}
export function SmsNumberStart04(v) {
  if (v.startsWith("0") && String(v).length == 1) {
    const numericValue = v.replace(/[^0-9]/g, "");
    if (String(numericValue)) return numericValue;
  } else if (v.startsWith("04") && String(v).length > 1) {
    const numericValue = v.replace(/[^0-9]/g, "");
    if (String(numericValue)) return numericValue;
  } else if (v.startsWith("0") && String(v).length > 1) {
    return "0";
  } else return "";
}

export function isNumeric(inputtxt) {
  var letters = /^[A-Z a-z]+$/;
  if (inputtxt.match(letters)) {
    return true;
  } else {
    return false;
  }
}
export function isAlphaNumericAnd_(inputtxt) {
  const numericValue = inputtxt.replace(/[^a-zA-Z0-9\-]/g, "");
  return numericValue;
}
export function isAlpha(inputtxt) {
  const numericValue = inputtxt.replace(/[^a-z A-Z]/g, "");
  return numericValue;
}
export function isAlphaNumeric(inputtxt) {
  const numericValue = inputtxt.replace(/[^a-z A-Z0-9.,/-]/g, "");
  return numericValue;
}
export function validateWebsite(value) {
  const urlRegex = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/\S*)?$/;
  return urlRegex.test(value);
}

export function IsNumberKeyWithoutZeroCopyPast(v) {
  console.log("onChange", v);
  v = v.replace("-", "");
  var t = v;
  if (String(v).includes(" ") && !String(v).includes("\n")) {
    var txt = v.replace("-", "").split(" ");
    var numberonly = IsNumberKeyWithoutZero(txt[1].replace("-", ""));
    t = txt[0] + " " + numberonly;
  } else if (String(v.replace("-", "")).includes("\n")) {
    let tx = String(v.replace("-", "")).split("\n");
    var len = tx.length;
    var lastEnterTxt = tx[parseInt(len) - 1];
    if (lastEnterTxt != "") {
      if (lastEnterTxt.includes(" ")) {
        var txt2 = lastEnterTxt.split(" ");
        var numberonly = IsNumberKeyWithoutZero(txt2[1].replace("-", ""));
        lastEnterTxt = txt2[0] + " " + numberonly.replace("-", "");
      }
      tx[parseInt(len) - 1] = lastEnterTxt.replace("-", "");
      t = tx.join("\n");
    }
  }
  return t;
}

export function PaymentGatewayInit() {}

export function getPageName() {
  var cUrl = String(window.location.pathname).toLowerCase().split("/")[1];
  return cUrl;
}

export function collapseNewCard(id) {
  //collapsecreditcard
  var divContent = document.getElementById(id);
  var ariaid = $(divContent).attr("aria-controls");
  var ariaContent = document.getElementById(ariaid);
  if ($(divContent).hasClass("collapsed")) {
    // If the class exists, remove it
    $(divContent).removeClass("collapsed");
    $(divContent).attr("aria-expanded", "true");

    if ($(ariaContent).hasClass("show") == false) {
      $(ariaContent).addClass("show");
    }
  }
}

export function FilterSpecialCharacter(inputString) {
  var regex = /[^a-zA-Z0-9.\s]/g;
  var replacedString = inputString.replace(regex, "");
  return replacedString;
}

export function FormAnimate(e) {
  $(e).parent().addClass("label-animate");
}

export function FormAction() {
  $('.form-control.custom_input[name="textbox"]').click(function () {
    $(this).parent().addClass("label-animate");
  });

  $('.form-control.custom_input[name="textbox"]').focus(function () {
    $(this).parent().addClass("label-animate");
  });

  $('.form-control.custom_input[name="textbox"]').blur(function () {
    if ($(this).val() == "") {
      $(this).parent().removeClass("label-animate");
    } // Remove the "focused" class
  });

  // Add focus event listener (optional)
  //$('.form-control.custom_input[name="textbox"]').focus(function() {
  //  $(this).addClass("label-animate"); // Add the "focused" class
  //});

  $(window).click(function (event) {
    if (!$(event.target).is(".form-control")) {
      $(".form-control").each(function () {
        if ($(this).val() == "") {
          $(this).parent().removeClass("label-animate");
        }
      });
    }
  });
}
export function CheckErrorCode(txt) {
  var rValue = "";
  try {
    var errorObject = JSON.parse(process.env.REACT_APP_LoginError);
    var dCode = base64Decode(txt);
    var errormsg = errorObject[dCode];
    if (errormsg != "") {
      rValue = errormsg;

      if (window.location.pathname == "/") {
        document.getElementById("divLoginmySidenav").style.width = "378px";
        document.getElementById("show_all_nav2").style.display = "block";
      }
    }
  } catch {
    rValue = "";
  }
  return rValue;
}
export function isValidArray(array) {
  return Array.isArray(array) && array.length > 0;
}
export const resolveImageUrl = (src) => {
  if (!src) return ""; // Handle null/undefined cases
  return src.startsWith("http")
    ? src
    : `${process.env.REACT_APP_IMAGE_URL || ""}${src}`;
};

export class CartToastObject {
  constructor(prdcode, prdimg, prddesc, prdprice, prdqty) {
    this.productCode = prdcode;
    this.prodImage = prdimg;
    this.description = prddesc;
    this.price = prdprice;
    this.qty = prdqty;
  }

  // Optional method to get details as JSON
  toJson() {
    return {
      prodImage: this.prodImage,
      description: this.description,
      qty: this.qty,
      price: this.price,
      productCode: this.productCode,
    };
  }
}
// export function ExcelUpload(e) {
//   var reader = new FileReader();
//   reader.readAsArrayBuffer(e.target.files[0]);
//   reader.onload = export function(e) {
//     var data = new Uint8Array(reader.result);
//     var wb = XLSX.read(data, { type: "array" });
//     var sheet_name_list = wb.SheetNames;
//     var dataj = XLSX.utils.sheet_to_json(wb.Sheets[sheet_name_list[0]], {
//       raw: true,
//       defval: null
//     });

//     dataj.map(export function(r) {
//       console.log(r);
//     });
//   };
// }
