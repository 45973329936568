import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactOwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

export const CategorySliderMobile =({sliderFirst,sliderSecond})=>{
    const options = {
        items: 1,
        loop: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 3000,
      };
    return (
        <div className="row d-block d-sm-none">
        <div className="col-12">
          <ReactOwlCarousel className="owl-theme" {...options}>
            {sliderFirst &&
              sliderFirst.map((row, index) => (
                <div className="item" key={index}>
                  <Link to={row.URL}>
                    <img
                      src={row.IMG_PATH}
                      className="img-fluid"
                      alt="banner-image"
                    />
                  </Link>
                </div>
              ))}
          </ReactOwlCarousel>
        </div>
        <div className="col-12">
          <ReactOwlCarousel className="owl-theme" {...options}>
            {sliderSecond &&
              sliderSecond.map((row, index) => (
                <div className="item" key={index}>
                  <Link to={row.URL}>
                    <img
                      src={row.IMG_PATH}
                      className="img-fluid"
                      alt="banner-image"
                    />
                  </Link>
                </div>
              ))}
          </ReactOwlCarousel>
        </div>
      </div>
    )
}