import { Fragment, useEffect, useState } from "react";
import { axiosInstance } from "../../axios/axiosInstance";
import { BreadCrumb } from "../../Layout/Common/breadCrumb";
import { Preloader } from "../../Layout/Common/preloader";

export const BrandPage = () => {
    const [Breadcrumb, setBreadcrumb] = useState([]);
    var data = {};
    useEffect(() => {
        axiosInstance
    .post("CategoryPage/GetCategoryContent", data)
    .then((response) => {
      if (response.data != "") {
        console.log(response.data); 
      }
    })
    .catch((error) => console.log(error.message)); 
    setBreadcrumb([{ name: `Brand`, url: null }]);
  }, []);
  return (
    <Fragment>
       <Preloader />
      <BreadCrumb data={Breadcrumb} />
      <section className="brand-list-section">
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-sm-6 col-lg-6 shop-brand-title">
              <h1>Shop By Brand</h1>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div className="shop-brand-search position-relative float-end">
                <input className="form-control me-2" type="text" name="searchbox" placeholder="Search here" aria-label="Search" />
                <span className="wes-search" />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="brands-list">
                <ul>
                  <li><a href="#." className="active">All</a></li>
                  <li className="border-link"><a href="#.">A</a></li>
                  <li className="border-link"><a href="#.">B</a></li>
                  <li className="border-link"><a href="#.">C</a></li>
                  <li className="border-link"><a href="#.">D</a></li>
                  <li className="border-link"><a href="#.">E</a></li>
                  <li className="border-link"><a href="#.">F</a></li>
                  <li className="border-link"><a href="#.">G</a></li>
                  <li className="border-link"><a href="#.">H</a></li>
                  <li className="border-link"><a href="#.">I</a></li>
                  <li className="border-link"><a href="#.">J</a></li>
                  <li className="border-link"><a href="#.">K</a></li>
                  <li className="border-link"><a href="#.">L</a></li>
                  <li className="border-link"><a href="#.">M</a></li>
                  <li className="border-link"><a href="#.">N</a></li>
                  <li className="border-link"><a href="#.">O</a></li>
                  <li className="border-link"><a href="#.">P</a></li>
                  <li className="border-link"><a href="#.">Q</a></li>
                  <li className="border-link"><a href="#.">R</a></li>
                  <li className="border-link"><a href="#.">S</a></li>
                  <li className="border-link"><a href="#.">T</a></li>
                  <li className="border-link"><a href="#.">U</a></li>
                  <li className="border-link"><a href="#.">V</a></li>
                  <li className="border-link"><a href="#.">W</a></li>
                  <li className="border-link"><a href="#.">X</a></li>
                  <li className="border-link"><a href="#.">Y</a></li>
                  <li className="border-link"><a href="#.">Z</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row row-spacing">
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand1.png" alt="brand list" />
                  <span>Aerpro<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand2.png" alt="brand list" />
                  <span>Cellink<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand3.png" alt="brand list" />
                  <span>Sandisk<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand4.png" alt="brand list" />
                  <span>Daichi<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand5.png" alt="brand list" />
                  <span>Dayton Audio<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand6.png" alt="brand list" />
                  <span>Prolink<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand7.png" alt="brand list" />
                  <span>SB Acoustics<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand8.png" alt="brand list" />
                  <span>Studio Acoustics<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand9.png" alt="brand list" />
                  <span>LevelOne<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand10.png" alt="brand list" />
                  <span>TP-Link<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand11.png" alt="brand list" />
                  <span>Camelion<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand12.png" alt="brand list" />
                  <span>Edimax<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand13.png" alt="brand list" />
                  <span>Ubiquiti<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand14.png" alt="brand list" />
                  <span>TP-Link<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand15.png" alt="brand list" />
                  <span>Audio Technica<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand16.png" alt="brand list" />
                  <span>Australian Monitor<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand17.png" alt="brand list" />
                  <span>Clarion<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand18.png" alt="brand list" />
                  <span>Elsafe<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand19.png" alt="brand list" />
                  <span>Fanvil<span>
                    </span></span></a>
              </div>
            </div>
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 mt-3 mt-lg-4">
              <div className="branding-list">
                <a href="brand-view.html" className="brand-list-effect">
                  <img src="https://cdn.wes.com.au/wesreact/newui/img/brand-list/brand20.png" alt="brand list" />
                  <span>Huawei<span>
                    </span></span></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
