import { Fragment, useContext, useEffect, useState } from "react";
import { useOrderClarificationModal } from "../../Context/OrderClarificationPopUpContext";
import { GlobalContext } from "../../Context/GlobalContext";
import { axiosInstance } from "../../axios/axiosInstance";
import { isValidArray } from "../../Layout/Common/common";
import { LinkProductPage } from "../../Layout/Common/LinkProductPage";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";

export const OrderDetailClarification = () => {
  const globalcontext = useContext(GlobalContext);
  const [orderErrorItem, setOrderErrorItem] = useState(null);
  const [isAlreadyShown, setIsAlreadyShown] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const {
    showOrderClarification,
    hideOrderClarification,
    isOrderClarification,
    setIsOrderClarification,
  } = useOrderClarificationModal();

  useEffect(() => {
    if (!isAlreadyShown) CheckErrorOrder();
    GetErrorItem();
  }, [globalcontext.CartItem]);

  const CheckErrorOrder = async () => {
    axiosInstance
      .post("MyCart/CheckOrderClarification", {
        userId: String(globalcontext.UserId),
        orderId: String(globalcontext.OrderId),
      })
      .then((response) => {
        if (response.data && response.data.statusCode == 0) {
          showOrderClarification();
          setIsOrderClarification(true);
          setIsAlreadyShown(true);
        } else {
          hideOrderClarification();
          setIsOrderClarification(false);
        }
      })
      .catch((error) => {
        hideOrderClarification();
        setIsOrderClarification(false);
      });
  };

  const GetErrorItem = async () => {
    if (globalcontext.UserId && globalcontext.OrderId) {
      const response = await axiosInstance.post(
        "MyCart/GetOrderClarification",
        {
          userId: String(globalcontext.UserId),
          orderId: String(globalcontext.OrderId),
        }
      );
      console.log("MyCart/GetOrderClarification", response);
      if (response.data) {
        let res = response.data;
        if (res.statusCode != -1 && isValidArray(res.data)) {
          res.data.forEach((item, index) => {
            setTimeout(() => {
              setOrderErrorItem((prevCards) =>
                prevCards ? [...prevCards, item] : [item]
              );
            }, index * 100);
          });
          //setOrderErrorItem(res.data);
        } else {
        }
      }
    }
  };
  const handleRemoveItem = (clarificationId) => {
    axiosInstance
      .post("MyCart/RemoveOrderClarification", {
        orderItemId: clarificationId,
        userId: String(globalcontext.UserId),
      })
      .then((response) => {
        setOrderErrorItem((prevCards) =>
          prevCards.filter((card) => card.clarificationId !== clarificationId)
        );
      })
      .catch((error) => {
        return error.message;
      });
  };
  return (
    <Fragment>
      {orderErrorItem && (
        <div class="row">
          <div class="col-lg-12 order-clarification-info">
            <TransitionGroup>
              {orderErrorItem.map((item, index) => (
                <CSSTransition
                  key={item.clarificationId}
                  timeout={300}
                  classNames="card"
                >
                  {item.style == "BLUE" ? (
                    <ErrorItemBlue
                      item={item}
                      index={index}
                      handleAction={handleRemoveItem}
                    />
                  ) : item.style == "RED" ? (
                    <ErrorItemRed
                      item={item}
                      index={index}
                      handleAction={handleRemoveItem}
                    />
                  ) : (
                    <ErrorItemOrange
                      item={item}
                      index={index}
                      handleAction={handleRemoveItem}
                    />
                  )}
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const ErrorItemRed = ({ item, index, handleAction }) => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <Fragment>
      {item && (
        <div
          className="order-clarification not-found"
          key={index}
          style={{
            opacity: isClicked ? 0.5 : 1,
          }}
        >
          <div className="order-clarification-lft">
            <h5>
              <span className="wes-alert" /> {item.clarificationMessage}
            </h5>
            <p>{`Code: ${item.orderCode} Not Found / Incorrect Code`}</p>
          </div>
          <div className="order-clarification-rht">
            <button
              type="submit"
              className="cc-cart-delete"
              onClick={() => {
                setIsClicked(true);
                handleAction(item.clarificationId);
              }}
            >
              <i className="fa-regular fa-trash-can" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const ErrorItemOrange = ({ item, index, handleAction }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Fragment>
      {item && (
        <div
          className="order-clarification temporarily-unavailable"
          key={index}
          style={{
            opacity: isClicked ? 0.5 : 1,
          }}
        >
          <div className="order-clarification-lft">
            <h5>
              <span className="wes-alert" /> {item.clarificationMessage}
            </h5>
            <h6>
              <LinkProductPage
                productId={item.productId}
                productCode={item.orderCode}
                value={item.prod_desc}
              />
            </h6>
            <p>Code: {item.orderCode} is currently unavailable</p>
          </div>
          <div className="order-clarification-rht">
            <button
              type="submit"
              className="cc-cart-delete"
              onClick={() => {
                setIsClicked(true);
                handleAction(item.clarificationId);
              }}
            >
              <i className="fa-regular fa-trash-can" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const ErrorItemBlue = ({ item, index, handleAction }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Fragment>
      {item && (
        <div
          className="order-clarification product-notAvailable"
          key={index}
          style={{
            opacity: isClicked ? 0.5 : 1,
          }}
        >
          <div className="order-clarification-lft">
            <h5>
              <span className="wes-alert" /> {item.clarificationMessage}
            </h5>
            <h6>
              <LinkProductPage
                productId={item.productId}
                productCode={item.orderCode}
                value={item.prod_desc}
              />
            </h6>
            <LinkProductPage
              productId={item.replaceItemId}
              productCode={item.replaceItemCode}
              value={` View Replacement Product : ${item.replaceItemCode}`}
            />
          </div>
          <div className="order-clarification-rht">
            <button
              type="submit"
              className="cc-cart-delete"
              onClick={() => {
                setIsClicked(true);
                handleAction(item.clarificationId);
              }}
            >
              <i className="fa-regular fa-trash-can" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};
