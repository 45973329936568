import "./index";
import "./App.css";
import "owl.carousel";
import $ from "jquery";
import {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  Suspense,
} from "react";
import { GlobalContext } from "./Context/GlobalContext";
import {
  Redirect,
  Route,
  Routes,
  Router,
  useNavigate,
  useParams,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import IdleTimer, { IdleTimerComponent, useIdleTimer } from "react-idle-timer";
import useAxiosInstance from "./axios/axiosInterceptor";
import { MainLayout } from "./Layout/MainLayout/MainLayout";
import { BottomLayout } from "./Layout/BottomLayout/BottomLayout";
import { LoginComp } from "./Layout/Common/Login";
import { axiosInstance } from "./axios/axiosInstance";
import { routesConfig } from "./routeConfig";
import { useOrderClarificationModal } from "./Context/OrderClarificationPopUpContext";
import ProtectedRoute from "./protectedRoute";
import { useAuth } from "./Context/AuthContext";
import { SuspenseLoader } from "./Layout/Common/loader";

function App() {
  const globalcontext = useContext(GlobalContext);
  const { userId, userName, role, isAuthenticated } = useAuth();
  const { loading, error, get, post } = useAxiosInstance();
  const { showOrderClarification } = useOrderClarificationModal();
  const [isLoginPage, setIsLoginPage] = useState(false);
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    try {
      if (isAuthenticated) {
        // if (location.pathname.toLowerCase() === "/login")
        //   history("/home", { replace: true });
        let data = { userid: userId };
        post("MyFavoriteList/GetWishList", data, (response) => {
          console.log("GetWhishList", response);
          if (response.data && response.data.length > 0) {
            globalcontext.setWishList(response.data);
          }
        });
      } else {
        history("/login", { replace: true });
      }
    } catch (error) {}
  }, [isAuthenticated]);

  const LoadMyCartItem = () => {
    var data = {
      UserID: String(userId),
      OrderID: "0",
    };
    axiosInstance
      .post("MyCart/GetMyCartItem", data)
      .then((response) => {
        globalcontext.setCartItem(response.data);
        globalcontext.setOrderId(response.data.orderid);
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    setIsLoginPage(location.pathname.toLowerCase() === "/login");
    if (isAuthenticated && userId) {
      LoadMyCartItem();
    }
  }, [history, window.location.search, userId]);
  return (
    <Fragment>
      {isLoginPage ? (
        <LoginComp />
      ) : !isAuthenticated ? (
        <Navigate to="/Login" replace />
      ) : (
        <Fragment>
          <MainLayout />
          {/* <Suspense fallback={<SuspenseLoader />}> */}
          <Routes>
            {routesConfig.map(({ path, Component }) => (
              <Route
                key={path}
                path={path}
                Component={ProtectedRoute({ Component })}
              />
            ))}
          </Routes>
          {/* </Suspense> */}
          <BottomLayout />
        </Fragment>
      )}
    </Fragment>
  );
}

export default App;
