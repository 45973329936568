import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
 
export const WishListToaster = ({ action, productId }) => {
  return (
    <Fragment>
      <div className="position-fixed bottom-0 start-0 p-3" style={{ zIndex: 99 }}>
        <div
          id="wishlistadded"
          className="toast hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-bs-delay={100000}
        >
          <div className="toast-body bg-black">
            <p className="text-uppercase text-white rounded-1 mb-0">
              <i className="fa-solid fa-check" /> Added to your wishlist
            </p>
         
              <Link to="/" className="text-uppercase viewLink">View</Link>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="toast"
                aria-label="Close"
              />
          </div>
        </div>
      </div>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 99 }}>
        <div
          id="wishlistremove"
          className="toast hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-bs-delay={1000}
        >
          <div className="toast-body bg-black">
            <p className="text-uppercase text-white rounded-1">
              <i className="fa-solid fa-xmark" /> Removed to your wishlist
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
