import { Fragment, useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../axios/axiosInterceptor";
import { ProductCard } from "../../Layout/Common/productCard";
import $ from "jquery";
import { ProductListMobileView } from "./mobileView/productListMobile";
import { GlobalContext } from "../../Context/GlobalContext";
export const ProductListSection = ({ type }) => {
  const { loading, error, get, post } = useAxiosInstance();
  const [ProductListJson, setProductListJson] = useState();
  const globalcontext = useContext(GlobalContext);
  useEffect(() => {
    var data = {
      type: "CLEARENCE",
      userid: "0",
      count: 15,
    };
    if (globalcontext.IndexProduct === null) {
      post("GetIndexProduct", data, (response) => {
        if (response.data != "") {
          let result = response.data;
          if (Array.isArray(result) && result.length > 0) {
            globalcontext.setIndexProduct(result);
          }
        }
      });
    }
  }, [window.location.href]);
  const handleAddtocartClick = (event) => {
    const button = $(event.currentTarget);
    window.addCartEffect(button);
  };
  return (
    <section className="feature-section">
      <div className="container-fluid container-lg">
        <div className="row">
          <div className="col-lg-12">
            {/* title */}
            <div className="product-title text-center">
              <h2>{type} Products</h2>
              <p>Check {type} Products</p>
            </div>
            {/* title */}
          </div>
        </div>
        <div className="row clearance-products d-block d-sm-none d-xs-none">
          <div className="col-lg-12">
            <div className="owl-carousel features-slider">
              {globalcontext.IndexProduct &&
                globalcontext.IndexProduct.map((row, index) => (
                  <Fragment>
                    <ProductCard data={row} />
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
        <ProductListMobileView ProductListJson={globalcontext.IndexProduct} />
        <div className="row mt-4">
          <div className="col-lg-12 button-effect text-center">
            <a href="#" className="shop-link">
              Shop All
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
