import React, { createContext, useReducer } from "react";

const WishlistContext = createContext();

const wishlistReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_WISHLIST":
      return {
        ...state,
        [action.productId]: true,
        productid: action.productId,
      };
    case "REMOVE_FROM_WISHLIST":
      return {
        ...state,
        [action.productId]: false,
        removeid: action.productId,
      };
    case "UPDATE_FAVOURITE_LIST":
      return {
        ...state,
        favouriteList: action.list,
      };
    default:
      return state;
  }
};

const WishlistProvider = ({ children }) => {
  const [wishlistState, dispatch] = useReducer(wishlistReducer, {});

  return (
    <WishlistContext.Provider value={{ wishlistState, dispatch }}>
      {children}
    </WishlistContext.Provider>
  );
};

export { WishlistContext, WishlistProvider };
