import { Fragment, useEffect, useState } from "react";
import { acceptablePayments, footerLogo } from "../../assets/imageImport";
import { useLocation } from "react-router-dom";
import { PAGES_WITHOUT_FOOTER } from "../../routeConfig";
export const BottomLayout = () => {
  const location = useLocation();
  const [isNotRequired, setIsNotRequired] = useState(false);
  useEffect(() => {
    const exists = PAGES_WITHOUT_FOOTER.some(
      (item) => item.toLowerCase() === location.pathname.toLowerCase()
    );
    setIsNotRequired(exists);
  }, [location]);

  if (!isNotRequired) {
    return (
      <footer className="footer-section">
        <div className="container-fluid container-lg">
          <div className="row mt-4 footer-link-top">
            <div className="col-lg-4">
              <div className="company-info">
                <img src={footerLogo} alt="footer-logo" />
                <p className="text-white mt-3">
                  WES is your trusted supplier for all your Audio/Visual, Data,
                  Security, and Electronic Components Parts, and Accessories
                  needs We provide our customers with a comprehensive range of
                  products and fast reliable services to meet your project
                  requirements. From professional installers and system
                  integrators to electronics and technicians, we have the right
                  solutions for you!
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row footer-links mt-md-3 mt-lg-0">
                <div className="col-md-3 col-lg-3 mt-3 mt-lg-0">
                  <h5>Quick Links</h5>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> About Us{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> My Account{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Quick Order{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-lg-3 footer-links mt-3 mt-lg-0">
                  <h5>Others</h5>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Catalogue Download
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Terms and Condition{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Job Enquiries{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Privacy Policy{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-lg-3 footer-links mt-3 mt-lg-0">
                  <h5>Categories</h5>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> AV TV Sat
                        Installation
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Cable &amp;
                        Connectors
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Audio Speakers{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Security{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-lg-3 footer-links mt-3 mt-lg-0">
                  <h5>Users</h5>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Payment Policy
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> User Guide{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> Job Enquiries{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-circle" /> FAQ{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row payments-links">
            <div className="col-md-6 col-lg-6">
              <img src={acceptablePayments} alt="acceptable-payments" />
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="social-links">
                <ul>
                  <li>
                    <a href="#" title="Facebook">
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Twitter">
                      <i className="fa-brands fa-x-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Google Plus">
                      <i className="fa-brands fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Youtube">
                      <i className="fa-brands fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyrights">
            <div className="col-lg-6">
              <p>Copyright © 2024 wes.com.au. All rights are reserved.</p>
            </div>
            <div className="col-lg-6">
              <div className="help-links">
                <ul>
                  <li>
                    <a href="#" title="Facebook">
                      Help Center
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Facebook">
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};
