import $ from "jquery";
import { Toast } from "bootstrap";
import React, { useContext, useEffect, useState } from "react";
import {
  WishlistContext,
  WishlistProvider,
} from "../../Context/WishlistContext";
import { axiosInstance } from "../../axios/axiosInstance";
import { GlobalContext } from "../../Context/GlobalContext";
import { showFavouriteToaster, showWishListRemoveToaster } from "./common";
import "../../../src/assets/plugins/wesicons/wes-icon.css";
import "../../../src/assets/plugins/wesicons/fonts/wescom.svg";
import { useLocation } from "react-router-dom";

export const WishlistItem = ({ productId }) => {
  const globalcontext = useContext(GlobalContext);
  const { wishlistState, dispatch } = useContext(WishlistContext);
  var isAdded = wishlistState[productId] || false;
  const [isAnimate, setIsAnimate] = useState(false);
  const [pageName, setPagename] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setPagename(location.pathname.toUpperCase());
  }, [location.pathname]);

  useEffect(() => {
    if (globalcontext.WishList && globalcontext.WishList.length > 0) {
      var products = globalcontext.WishList;
      var myWishList = products.some(
        (product) => String(product.PRODUCT_ID) === String(productId)
      );
      if (myWishList) {
        dispatch({ type: "ADD_TO_WISHLIST", productId });
      }
    }
  }, [globalcontext.WishList]);

  const handleWishlistClick = (event) => {
    setIsAnimate(true);
    setTimeout(() => {
      setIsAnimate(false);
    }, 1000);
    var p = $(event.currentTarget);
    if (isAdded) {
      wishList("REMOVE");
      showWishListRemoveToaster();
      dispatch({ type: "REMOVE_FROM_WISHLIST", productId });
    } else {
      wishList("ADD");
      showFavouriteToaster();
      dispatch({ type: "ADD_TO_WISHLIST", productId });
    }
  };
  const wishList = (action) => {
    var params = {
      userid: globalcontext.UserId,
      productid: parseInt(productId),
      action: action,
    };

    axiosInstance
      .post("MyFavoriteList/AddToWhishList", params)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          globalcontext.setWishList(response.data);
        }
      })
      .catch((error) => console.log(error.message));
  };
  return (
    <>
      {pageName && pageName.includes("ORDERDETAILS") ? (
        <button
          type="submit"
          onClick={handleWishlistClick}
          className={`cc-cart-wishlist wishlist ${
            isAdded && "wishlistaddedbtn"
          }`}
        >
          <span
            className={`wesWishlist ${
              isAdded ? "wes-whishlist-active" : "wes-whishlist1"
            }`}
          />
        </button>
      ) : (
        <p
          onClick={handleWishlistClick}
          className={`wishlist ${
            isAdded ? "wishlistaddedbtn" : "wishlistremovebtn"
          } ${isAnimate ? " animate" : ""}`}
        >
          <span
            className={`wesWishlist ${
              isAdded ? "wes-whishlist-active" : "wes-whishlist1"
            }`}
          />
        </p>
      )}
    </>
  );
};
