import { Fragment, useContext, useEffect, useState } from "react";
import { isValidArray } from "../../Layout/Common/common";
import { ImageWithDefault } from "../../Layout/Common/imageWithDefault";
import { Link } from "react-router-dom";
import { QuantityStripper } from "../../Layout/Common/quantityStripper";
import { axiosInstance } from "../../axios/axiosInstance";
import { GlobalContext } from "../../Context/GlobalContext";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../../assets/css/cartList.css";
import { WishlistItem } from "../../Layout/Common/addWhistList";

export const OrderDetailItemBody = () => {
  const [cartItem, setCartItem] = useState(null);
  const globalcontext = useContext(GlobalContext);

  useEffect(() => {
    if (
      globalcontext.CartItem &&
      isValidArray(globalcontext.CartItem.cartItem)
    ) {
      // cartItemList.forEach((item, index) => {
      //   setTimeout(() => {
      //     setCartItem((prevCards) =>
      //       prevCards ? [...prevCards, item] : [item]
      //     );
      //   }, index * 100);
      // });
      setCartItem(globalcontext.CartItem.cartItem);
    }
  }, [globalcontext.CartItem]);

  return (
    <Fragment>
      {isValidArray(cartItem) && (
        <div className="row">
          <div className="col-lg-12">
            <TransitionGroup>
              {cartItem.map((item, index) => (
                <CSSTransition
                  key={item.prod_id}
                  timeout={300}
                  classNames="card"
                >
                  <CartBodyItem item={item} index={index} />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const CartBodyItem = ({ item, index }) => {
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const globalcontext = useContext(GlobalContext);
  const removeCard = (item) => {
    setIsDeleteClicked(true);
    var data = {
      productId: String(item.prod_id),
      userId: String(globalcontext.UserId),
      orderItemId: item.order_item_id,
      orderId: String(globalcontext.OrderId),
    };
    axiosInstance
      .post("MyCart/RemoveItem", data)
      .then((response) => {
        console.log(response);
        if (response.data) {
          var res = response.data;
          if (res.statusCode == 0 && res.data) {
            globalcontext.setCartItem(res.data);
          } else {
          }
        }
      })
      .catch((error) => console.log(error.message));
  };

  return (
    <div
      className="cart-added-products"
      key={index}
      style={{
        opacity: isDeleteClicked ? 0.5 : 1,
      }}
    >
      <div className="added-cartPro-img">
        <ImageWithDefault src={item.image_th} alt="Product-image" />
      </div>
      <div className="added-cartPro-content">
        <div className="added-cartPro-info">
          <div className="added-cartPro-item">
            <h6>
              <Link to={item.product_url}>{item.description}</Link>
            </h6>
            <p>
              <span>Code: {item.prod_code}</span>${item.price_ext_applied} each
            </p>
          </div>
          <div className="cart-remove-wishlist">
            <button
              type="submit"
              className="cc-cart-delete"
              onClick={() => {
                removeCard(item);
              }}
            >
              <i className="fa-regular fa-trash-can" />
            </button>
            <WishlistItem productId={item.prod_id} />
          </div>
        </div>
        <div className="added-cartPro-price">
          <p>${item.total_ext}</p>
          {item.isErrorItem ? (
            <div className="qty-container orderErrorItem">
              {item.error_message}
            </div>
          ) : (
            <QuantityStripper prd={item} />
          )}
        </div>
      </div>
    </div>
  );
};
