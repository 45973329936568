import { Fragment } from "react";
import { Link } from "react-router-dom";

export const BreadCrumb = ({ data }) => {
  return (
    <Fragment>
      {data && data.length > 0 && (
        <section className="breadcrumb-section">
          <div className="container-fluid container-lg">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={"/"}>Home</Link>
                    </li>
                    {data.map((pill, index) => (
                      <li
                        className={`breadcrumb-item ${
                          index === data.length - 1 ? "active" : ""
                        }`}
                      >
                        <Link to={pill.url}>{pill.name}</Link>
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};
