export const ModelFilterMobile = () => {
  return (
    <div
      className="offcanvas offcanvas-end sorting-offcanvas"
      tabIndex={-1}
      id="mobFitmodal"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header filters-header border-bottom">
        <h5 id="offcanvasRightLabel">Brands</h5>
        <div className="clear-filter">
          <a className="cc-clear">Clear Filter</a>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
      </div>
      <div className="offcanvas-body moboffcanvas-body">
        <div className="categ-form-check mob-filters">
          <div className="filter-body">
            <div className="categ-form-check mob-filters">
              <div className="categ-list">
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="alcatel"
                    name="alcatel"
                  />
                  <label className="form-check-label" htmlFor="alcatel">
                    Alcatel
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="apple"
                    name="apple"
                  />
                  <label className="form-check-label" htmlFor="apple">
                    Apple
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="asus"
                    name="asus"
                  />
                  <label className="form-check-label" htmlFor="asus">
                    Asus
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="blackBerry"
                    name="blackBerry"
                  />
                  <label className="form-check-label" htmlFor="blackBerry">
                    BlackBerry
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="blink"
                    name="blink"
                  />
                  <label className="form-check-label" htmlFor="blink">
                    BLINK
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="cel"
                    name="cel"
                  />
                  <label className="form-check-label" htmlFor="cel">
                    Cel Fi
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="dodo"
                    name="dodo"
                  />
                  <label className="form-check-label" htmlFor="dodo">
                    Dodo
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ericsson"
                    name="ericsson"
                  />
                  <label className="form-check-label" htmlFor="ericsson">
                    ericsson
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="google"
                    name="google"
                  />
                  <label className="form-check-label" htmlFor="google">
                    Google
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="hp"
                    name="hp"
                  />
                  <label className="form-check-label" htmlFor="hp">
                    HP
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="htc"
                    name="htc"
                  />
                  <label className="form-check-label" htmlFor="htc">
                    HTC
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="huawei"
                    name="huawei"
                  />
                  <label className="form-check-label" htmlFor="huawei">
                    Huawei
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="hyundai"
                    name="hyundai"
                  />
                  <label className="form-check-label" htmlFor="hyundai">
                    Hyundai
                  </label>
                </div>
                <div className="categ-form-check form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="intercel"
                    name="intercel"
                  />
                  <label className="form-check-label" htmlFor="intercel">
                    Intercel
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer border-top">
        <button type="submit" className="btn btn-addcart">
          Apply
        </button>
      </div>
    </div>
  );
};
