import { Fragment, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { Toast } from "bootstrap";
import { ImageWithDefault } from "./imageWithDefault";

export const CartToastContainer = () => {
  const globalcontext = useContext(GlobalContext);
  const [toastData, setToastData] = useState(null);

  useEffect(() => {
    const { CardToast } = globalcontext;
    if (!CardToast || !CardToast.show) return;
    setToastData(CardToast);
    var toastE1 = document.getElementById("carttoastadded");
    var toastE2 = document.getElementById("carttoastremoved");
    var toastInc = new Toast(toastE1);
    var toastDec = new Toast(toastE2);
    if (CardToast && CardToast.show) {
      if (CardToast.type === "INC") toastInc.show();
      if (CardToast.type === "DEC") toastDec.show();
    }
  }, [globalcontext.CardToast]);

  return (
    <Fragment>
      <div className="cartadded-toast">
        <div
          id="carttoastadded"
          className="toast-cart toast hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-bs-delay={5000}
        >
          {toastData && (
            <div className="toast-body cart-toast">
              <div className="cart-item-update">
                <h6 className="text-uppercase fw-semibold">
                  <i className="fa-solid fa-check" /> Item Added to your cart
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                />
              </div>
              <div className="cart-toast-details mt-3">
                <div className="pro-toast-details">
                  <ImageWithDefault src={toastData.prodImage} alt="product" />
                  <div className="pro-toast-qty">
                    <span>{toastData.description}</span>
                    <p>Code: {toastData.productCode}</p>
                    <small>Qty:{toastData.qty}</small>
                  </div>
                </div>
                <p className="fw-bold">${toastData.price}</p>
              </div>
              <div className="cart-toast-btn mt-3">
                <a href="#." className="keepshopping">
                  Keep Shopping
                </a>
                <a href="#." className="checkoutcart-link">
                  Checkout
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="cartremoved-toast">
        <div
          id="carttoastremoved"
          className="toast-cart toast hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-bs-delay={1000}
        >
          {toastData && (
            <div className="toast-body cart-toast">
              <div className="cart-item-update">
                <h6 className="text-uppercase fw-semibold item-removed">
                  <i className="fa-solid fa-xmark" /> Item Removed From your
                  cart
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                />
              </div>
              <div className="cart-toast-details mt-3">
                <div className="pro-toast-details">
                  <img
                    src={require("../../assets/img/home-page/product-image/product1.png")}
                    alt="product"
                  />
                  <span>{toastData.description}</span>
                </div>
                <p className="fw-bold">${toastData.price}</p>
              </div>
              <div className="cart-toast-btn mt-3">
                <a href="#." className="viewcart-link continue-shop">
                  Continue Shopping
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
