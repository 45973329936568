import React from "react";
import $ from "jquery";
import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalContextProvider, { GlobalContext } from "./Context/GlobalContext";
import { BrowserRouter } from "react-router-dom";
import { WishlistProvider } from "./Context/WishlistContext";
import { OrderClarificationProvider } from "./Context/OrderClarificationPopUpContext";
import { AuthProvider } from "./Context/AuthContext";
// import './assets/js/jquery-3.7.0.min.js'
//import './assets/js/script.js'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <WishlistProvider>
        <OrderClarificationProvider>
          <GlobalContextProvider>
            <App />
          </GlobalContextProvider>
        </OrderClarificationProvider>
      </WishlistProvider>
    </AuthProvider>
  </BrowserRouter>
);
